import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToasterMessage } from "store/slices/modals_slice";

export default function SiteReportLoader(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size={80}
        style={{ color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD" }}
      />
      <Typography
        sx={{
          color: theme.palette.mode !== "dark" ? "black" : "white",
          fontSize: "24px",
          fontWeight: 600,
          marginTop: "16px",
        }}
      >
        Your report download is starting. Please enable pop-ups for this page.
        <br />
        The process may take a few minutes, so kindly avoid switching tabs.
      </Typography>
    </Box>
  );
}
