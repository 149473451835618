/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";

//Material
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
//Style
import GetStyles, {
  TableTextField,
  TableCellEditable,
  CustomSelect,
  CustomMenuItem,
} from "./style";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSolutionLccData } from "store/slices/lcc_slice";
import { deserializeCurrentSolutionLccData } from "utils/deserializers/lcc_data_deserializer";
import { StyledTableCell, StyledTableRow } from "components/OptimalTable/style";
import { Tooltip, useTheme } from "@mui/material";
import { setSelectedValue } from "store/slices/user_slice";

const ClientTable = ({
  headerValues,
  rowsValues,
  setHeaderValues,
  setRowsValues,
  selectedValue,
  rows,
  setRows,
  headers,
  setHeaders,
}) => {
  const dispatch = useDispatch();
  const styles = GetStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const lccData = useSelector((state) => state.lccData);
  const { currentSolution } = useSelector((state) => state.filters);

  const handleChange = (event) => {
    dispatch(setSelectedValue(event.target.value));
    setOpen(false);
  };

  useEffect(() => {
    const data = deserializeCurrentSolutionLccData(currentSolution.filter);
    dispatch(setCurrentSolutionLccData(data));
    // dispatch(setSelectedValue(data.numberOfStages));
  }, [currentSolution]);

  useEffect(() => {
    if (headers.length > 0) {
      setHeaders(headers.map((item) => item));
    } else if (selectedValue > 1) {
      setHeaders(
        headers.map((item, idx) => {
          if (idx + 1 <= selectedValue) {
            return item;
          }
        })
      );
    } else {
      setHeaders([""]);
    }
  }, [headers?.length, selectedValue]);

  useEffect(() => {
    if (rows[0]) {
      let newRows = [...rows];
      let newHeaders = [...headers];
      if (rows[0]?.length - 1 > selectedValue) {
        setRows(
          newRows.map((item) => {
            const arr = [...item];
            return arr.slice(0, selectedValue + 1);
          })
        );
      } else {
        for (let i = rows[0].length - 1; i < selectedValue; i++) {
          newRows = newRows.map((item) => [...item, ""]);
        }
        setRows(newRows);
      }
      if (selectedValue > newHeaders.length) {
        while (newHeaders.length < selectedValue) {
          newHeaders.push("");
        }
        setHeaders(newHeaders);
      } else {
        while (newHeaders.length > selectedValue) {
          newHeaders.pop();
        }
        setHeaders(newHeaders);
      }
    }
  }, [selectedValue]);
  console.log(headers, rows, "testhk2");
  useEffect(() => {
    if (rows?.length === 0) {
      const data = [
        ["Filter Type"],
        ["Filter Efficiency"],
        ["No Of Filters Per Stage"],
      ];
      // lccData.currentSolution?.data?.forEach((item, index) => {

      data[0].push("");
      data[1].push("");
      data[2].push("");
      //   return "";
      // });
      setRows(data);
    }
  }, [rows?.length]);

  const InitCustomIcon = useCallback(
    () => <CustomIcon handleOpen={setOpen} />,
    []
  );

  const handleHeadersChange = (value, index) => {
    setHeaderValues((prev) => {
      return { ...prev, [`stage${index + 1}`]: value };
    });
  };

  const handleRowsChange = (value, index, innerIndex) => {
    console.log(value, index, innerIndex, "debug");
    if (index === 0) {
      setRowsValues((prev) => {
        const obj = JSON.parse(JSON.stringify(prev));
        obj.filterType = {
          ...obj.filterType,
          [`stage${innerIndex}`]: value,
        };
        return obj;
      });
    } else if (index === 1) {
      setRowsValues((prev) => {
        const obj = JSON.parse(JSON.stringify(prev));
        obj.filterEff = {
          ...obj.filterEff,
          [`stage${innerIndex}`]: value,
        };
        return obj;
      });
    } else {
      setRowsValues((prev) => {
        const obj = JSON.parse(JSON.stringify(prev));
        obj.noOfFilters = {
          ...obj.noOfFilters,
          [`stage${innerIndex}`]: value,
        };
        return obj;
      });
    }
  };
  console.log(rowsValues, "rowsValues");
  return (
    <TableContainer component={Paper} sx={styles.tableBodyBackground}>
      <Table aria-label="customized table" sx={{ borderCollapse: "separate" }}>
        <TableHead>
          <TableRow sx={styles.tableHeader}>
            <TableCell colSpan={5} sx={styles.headerTitle}>
              INPUT YOUR CURRENT SOLUTION
              <Tooltip title="Tell us more about your current filtration solution, you may click on the “Number to stages” to specify stage numbers. Type in the box to modify filter name, type, efficiency and number of filters accordingly.">
                <InfoIcon
                  style={{ position: "relative", top: "6px", left: "8px" }}
                />
              </Tooltip>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styles.rowHeaders}>
              <CustomSelect
                open={open}
                value={selectedValue}
                onChange={handleChange}
                IconComponent={InitCustomIcon}
                sx={styles.rowHeaders}
              >
                <CustomMenuItem value={1} sx={styles.rowStyle}>
                  Number of stages: 1
                </CustomMenuItem>
                <CustomMenuItem value={2} sx={styles.rowStyle}>
                  Number of stages: 2
                </CustomMenuItem>
                <CustomMenuItem value={3} sx={styles.rowStyle}>
                  Number of stages: 3
                </CustomMenuItem>
              </CustomSelect>
            </TableCell>
            {headers &&
              headers.map((item, index) => {
                return (
                  <TableCellEditable
                    sx={{
                      color: "#0D0D0D",
                      width: "fit-content",
                      border: `1px solid ${
                        theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                      }`,
                      // : "1px solid #43404D",
                    }}
                    key={item}
                  >
                    <TableTextField
                      sx={{
                        color: "#0D0D0D",
                      }}
                      fullWidth
                      value={headerValues?.[`stage${index + 1}`]}
                      inputProps={{
                        style: {
                          width: "100%",
                        },
                      }}
                      onChange={(e) => {
                        handleHeadersChange(e.target.value, index);
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          color:
                            theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#31FFCD",
                          fontSize: "16px",
                          fontWeight: 700,
                          width: "100%",
                        },
                      }}
                    />
                  </TableCellEditable>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <StyledTableRow key={row?.[0]} isFirstRow={index === 0}>
              {row.map((item, innerIndex) => {
                return (
                  <>
                    {innerIndex === 0 ? (
                      <StyledTableCell component="th" scope="row" isFirstChild>
                        {item}
                      </StyledTableCell>
                    ) : (
                      <TableCellEditable>
                        <TableTextField
                          InputProps={{ disableUnderline: true }}
                          value={
                            index == 0
                              ? rowsValues?.filterType?.[`stage${innerIndex}`]
                              : index == 1
                              ? rowsValues?.filterEff?.[`stage${innerIndex}`]
                              : rowsValues?.noOfFilters?.[`stage${innerIndex}`]
                          }
                          onChange={(event) =>
                            handleRowsChange(
                              event.target.value,
                              index,
                              innerIndex
                            )
                          }
                        />
                      </TableCellEditable>
                    )}
                  </>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CustomIcon = (props) => {
  const { handleOpen } = props;
  const styles = GetStyles();
  return (
    <ExpandMoreIcon style={styles.iconColor} onClick={() => handleOpen(true)} />
  );
};

export default ClientTable;
