import React from "react";
import PropTypes from "prop-types";

//Material
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

//Style
import { BootstrapButton } from "./style";
import theme from "utils/theme";
import { useMediaQuery } from "@mui/material";

function RoundedButton({
  variant,
  color,
  label,
  onClick,
  disabled,
  size,
  showArrowIcon,
  activeLabel,
  isSimpleCycle,
  textColor,
  isConditionMet,
  ...props
}) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const customBackgroundColor =
    activeLabel && isSimpleCycle === label
      ? "#A4CDBD"
      : isSimpleCycle === label
      ? "#000000"
      : undefined;

  const customBorderColor =
    activeLabel && isSimpleCycle === label
      ? "#A4CDBD"
      : isSimpleCycle === label
      ? "#FFFFFF"
      : undefined;
  const customFontSize =
    isSimpleCycle && (isMobile || activeLabel) ? "13px" : undefined;
  return (
    <BootstrapButton
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={disabled}
      disableRipple
      customBackgroundColor={customBackgroundColor}
      customBorderColor={customBorderColor}
      customFontSize={customFontSize}
      size={size}
      style={{
        color: textColor,
        boxShadow: activeLabel
          ? "0px 20px 52px 0px rgba(209, 204, 128, 0.25)"
          : "none",
      }}
      {...props}
    >
      {label}
      {showArrowIcon && <CheckCircleIcon />}
    </BootstrapButton>
  );
}

export default RoundedButton;

RoundedButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  customBackgroundColor: PropTypes.string,
  customBorderColor: PropTypes.string,
  size: PropTypes.string,
  showArrowIcon: PropTypes.bool,
  textColor: PropTypes.string,
  isSimpleCycle: PropTypes.string,
};
