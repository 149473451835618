import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import newMarker from "../../assets/new-marker.svg";
import lightMarker from "../../assets/light-marker.svg";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import PM25Graph from "components/PM25Graph";
import { Box, Typography, useTheme } from "@mui/material";

import styles from "./style";
const GoogleMapComponent = ({ placeDetails, clickedPosition }) => {
  const [zoom, setZoom] = useState(10);
  const theme = useTheme();
  const mapOptions = {
    maxZoom: 15,
    minZoom: 1,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    keyboardShortcuts: false,
    zoomControlOptions: { position: 9 },
    restriction: {
      // this is for defines a boundary that restricts the area of the map accessible to users
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    },
    styles: [
      {
        elementType: "geometry",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#2b3539" : "#f7f0e7" },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#242f3e" : "transparent" },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#dbe7eb" : "#272927" },
        ],
      },
      { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#3b474b" : "#ffffff" },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#3b474b" : "#ffffff" },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#3b474b" : "#ffffff" },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#3b474b" : "#fda767" },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#3b474b" : "#fda767" },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          { color: theme.palette.mode !== "dark" ? "#3b474b" : "#212021" },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          { color: theme.palette.mode === "dark" ? "#111d21" : "#8adcfa" },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          { color: theme.palette.mode !== "dark" ? "#2f48a2" : "#111d21" },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [
          { color: theme.palette.mode !== "dark" ? "#2f48a2" : "#111d21" },
        ],
      },
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.park",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  const handleMapClick = async (e) => {
    try {
      console.log("success");
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const zoomIn = () => {
    if (zoom < 15) setZoom(zoom + 1);
  };

  const zoomOut = () => {
    if (zoom > 1) setZoom(zoom - 1);
  };

  const mapContainerStyle = {
    width: "100%",
    height: "55vh",
  };

  const defaultCenter = {
    lat: 37.7749,
    lng: -122.4194,
  };

  const cardStyle = {
    position: "absolute",
    bottom: "1px",
    left: "10px",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    width: "390px",
    padding: "8px",
    borderRadius: "12px",
    backgroundColor: theme.palette.mode === "dark" ? "#364246" : "#fff",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
    zIndex: 1,
  };

  const countryStyle = {
    fontSize: "14px",
    color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
    marginLeft: "15px",
    fontWeight: 600,
  };

  const textContainerStyle = {
    flex: 1,
  };

  const ContainerStyle = {
    position: "relative",
    width: "100%",
    height: "55vh",
  };

  const ZoomControlStyle = {
    position: "absolute",
    bottom: "25px",
    right: "10px",
    display: "flex",
    alignItems: "center",
    zIndex: 9999,
  };

  const zoomButtons = {
    padding: "0 6px",
    color: theme.palette.mode === "dark" ? "#31FFCD" : "#008c64",
    fontSize: "32px",
    fontWeight: "600",
    outline: "none",
    border: "1px solid rgba(232, 232, 232, 0.20)",
    cursor: "pointer",
    height: "42px",
    width: "42px",
    zIndex: 9999,
    backdropFilter: "blur(10px)",
    background:
      "var(--01, linear-gradient(134deg, rgba(219, 245, 253, 0.18) 0%, rgba(219, 245, 253, 0.16) 22.92%, rgba(219, 245, 253, 0.10) 47.92%, rgba(219, 245, 253, 0.16) 76.56%, rgba(219, 245, 253, 0.18) 100%))",
  };

  const updatedMarker = theme.palette.mode !== "dark" ? lightMarker : newMarker;

  return (
    <div style={ContainerStyle}>
      {/* {window && window.google !== undefined ? (
				<>
					<GoogleMap
						mapContainerStyle={{
							...mapContainerStyle,
							background: "#2b3639",
						}}
						center={clickedPosition || defaultCenter}
						zoom={zoom}
						onClick={handleMapClick}
						options={mapOptions}
					>
						{clickedPosition && (
							<Marker
								position={clickedPosition}
								draggable={false}
								icon={{
									url: newMarker,
									anchor: {
										x: 132,
										y: 132,
									},
								}}
							/>
						)}
						<div style={ZoomControlStyle}>
							<button style={{ ...zoomButtons, borderRadius: "6px 0 0 6px" }} onClick={zoomIn}>
								<AddRoundedIcon
									sx={{ width: "26px", height: "26px", alignItems: "center", marginTop: "6px" }}
								/>
							</button>
							<button style={{ ...zoomButtons, borderRadius: "0 6px 6px 0" }} onClick={zoomOut}>
								<RemoveRoundedIcon
									sx={{ width: "26px", height: "26px", alignItems: "center", marginTop: "6px" }}
								/>
							</button>
						</div>
					</GoogleMap>
					{clickedPosition && (
						<Box sx={cardStyle}>
							<Box sx={styles.placeDetailContainer}>
								<PM25Graph plots={placeDetails?.plots} />
							</Box>
							{placeDetails?.label && (
								<Box style={textContainerStyle}>
									<Typography sx={styles.placeDetailLabel}>{placeDetails?.label}</Typography>
									<Typography sx={countryStyle}>{placeDetails?.country}</Typography>
								</Box>
							)}
						</Box>
					)}
				</>
			) : ( */}
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={{
            ...mapContainerStyle,
            background: "#2b3639",
          }}
          center={clickedPosition || defaultCenter}
          zoom={zoom}
          onClick={handleMapClick}
          options={mapOptions}
        >
          {clickedPosition && (
            <Marker
              position={clickedPosition}
              draggable={false}
              icon={{
                url: updatedMarker,
                anchor: {
                  x: 132,
                  y: 132,
                },
              }}
            />
          )}
          <div style={ZoomControlStyle}>
            <button
              style={{ ...zoomButtons, borderRadius: "6px 0 0 6px" }}
              onClick={zoomIn}
            >
              <AddRoundedIcon
                sx={{
                  width: "26px",
                  height: "26px",
                  alignItems: "center",
                  marginTop: "6px",
                }}
              />
            </button>
            <button
              style={{ ...zoomButtons, borderRadius: "0 6px 6px 0" }}
              onClick={zoomOut}
            >
              <RemoveRoundedIcon
                sx={{
                  width: "26px",
                  height: "26px",
                  alignItems: "center",
                  marginTop: "6px",
                }}
              />
            </button>
          </div>
        </GoogleMap>
        {clickedPosition && (
          <Box sx={cardStyle}>
            <Box sx={styles.placeDetailContainer}>
              <PM25Graph plots={placeDetails?.plots} />
            </Box>
            {placeDetails?.label && (
              <Box style={textContainerStyle}>
                <Typography
                  sx={{
                    ...styles.placeDetailLabel,
                    color: theme.palette.mode !== "dark" ? "black" : "white",
                  }}
                >
                  {placeDetails?.label}
                </Typography>
                <Typography sx={countryStyle}>
                  {placeDetails?.country}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </LoadScript>
      {/* )} */}
    </div>
  );
};

export default GoogleMapComponent;
