import React from "react";
import PropTypes from "prop-types";

import { Snackbar, Alert as MuiAlert } from "@mui/material";

export const Alert = ({
  severity,
  autoHideDuration,
  title,
  onCloseCallback,
  anchorOrigin,
  ...props
}) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
      onCloseCallback && onCloseCallback();
    }
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
    >
      <MuiAlert
        severity={severity}
        onClose={handleClose}
        variant="filled"
        sx={{
          borderRadius: "10px",
        }}
        {...props}
      >
        {title}
      </MuiAlert>
    </Snackbar>
  );
};

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
  severity: PropTypes.oneOf(["success", "error", "warning", "info"]),
  onCloseCallback: PropTypes.func,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "top", "center"]),
    horizontal: PropTypes.oneOf(["right", "right", "center"]),
  }),
};
Alert.defaultProps = {
  autoHideDuration: 5000,
  onCloseCallback: null,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};
