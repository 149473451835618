import Dashboard from "../modules/Dashboard";
import NotFoundPage from "../modules/NotFoundPage";
import routePaths from "./routePaths";
import ContactPage from "../modules/ContactPage";
import RequestLLCPage from "../modules/RequestLCCPage";
import Home from "../modules/Home";
import Redirect from "modules/Redirect";
import SiteReportLoader from "modules/SiteReportLoader";

export const APP_SETTINGS = [
  {
    path: routePaths.DASHBOARD,
    component: Dashboard,
  },
  {
    path: routePaths.CONTACT_SALES,
    component: ContactPage,
  },
  {
    path: routePaths.REQUEST_LLC,
    component: RequestLLCPage,
  },
  {
    path: routePaths.NOT_FOUND_PAGE,
    component: NotFoundPage,
  },
  {
    path: routePaths.HOME,
    component: Home,
  },
  {
    path: routePaths.SITE_REPORT,
    component: SiteReportLoader,
  },
  { path: routePaths.REDIRECT, component: Redirect },
];
