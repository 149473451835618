import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  stage1: {
    filterType: "None",
  },
  stage2: {
    filterType: "None",
  },
  stage3: {
    filterType: "None",
  },
  isValueRatingLoading: true,
  valueRating: {},
  globalOptimalSolution: {},
  optimalSizeSolution: {},
  optimalTypeSolution: {},
  currentSolution: {},
  optimalSolutionWithSizeLimitation: {},
  currLoading: false,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setOverallFilters(state, action) {
      console.log(action.payload, "overalldsfs");
      state.isLoading = false;
      state.stage1 = action.payload.stage1;
      state.stage2 = action.payload.stage2;
      state.stage3 = action.payload.stage3;
    },
    setStage1Filter(state, action) {
      state.stage1 = action.payload.data;
      if (action.payload.callback)
        action.payload.callback(
          "stage1",
          action.payload.data,
          action.payload.dispatch
        );
    },
    setStage2Filter(state, action) {
      console.log(action.payload, "fdsfsdfstage2");
      state.stage2 = action.payload.data;
      if (action.payload.callback)
        action.payload.callback(
          "stage2",
          action.payload.data,
          action.payload.dispatch
        );
    },
    setStage3Filter(state, action) {
      state.stage3 = action.payload.data;
      if (action.payload.callback)
        action.payload.callback(
          "stage3",
          action.payload.data,
          action.payload.dispatch
        );
    },
    storeValueRating(state, action) {
      state.valueRating = action.payload;
      state.isValueRatingLoading = false;
    },
    setGlobalOptimalSolution(state, action) {
      state.globalOptimalSolution = action.payload;
      console.log(
        action.payload,
        "action payload within setGlobalOptimalSolution"
      );
    },
    setOptimalSizeSolution(state, action) {
      state.optimalSizeSolution = action.payload;
      console.log(
        state.optimalSizeSolution,
        "action payload within setoptimalSizeSolution"
      );
    },
    setOptimalTypeSolution(state, action) {
      state.optimalTypeSolution = action.payload;
    },
    setOptimalSolutionWithSizeLimitation(state, action) {
      if (
        (action.payload.filter[0] &&
          action.payload.filter[0].Stage ===
            "No filters match all the constraints") ||
        typeof action.payload.filter_stage_number === "undefined"
      ) {
        console.log("reserve state");
      } else {
        state.currentSolution = action.payload;
      }
      state.optimalSolutionWithSizeLimitation = action.payload;
      state.currLoading = false;
      console.log(
        state.optimalSolutionWithSizeLimitation,
        "action payload within setOptimalSolutionWithSizeLimitation"
      );
    },
    setCurrentSolution(state, action) {
      if (
        action.payload.filter[0] &&
        action.payload.filter[0].Stage ===
          "No filters match all the constraints"
      ) {
        console.log("reserve state");
      } else {
        state.currentSolution = action.payload;
      }
      state.currLoading = false;
    },
    setCurrLoading(state, action) {
      state.currLoading = action.payload;
    },
  },
});

export const {
  setOverallFilters,
  setStage1Filter,
  setStage2Filter,
  setStage3Filter,
  storeValueRating,
  setGlobalOptimalSolution,
  setOptimalSizeSolution,
  setOptimalTypeSolution,
  setOptimalSolutionWithSizeLimitation,
  setCurrentSolution,
  setCurrLoading,
} = filtersSlice.actions;
export default filtersSlice.reducer;
