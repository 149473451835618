import * as React from "react";
import PropTypes from "prop-types";

//Material
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography, useTheme } from "@mui/material";

//Style
import GetStyles, { ColorlibConnector, ColorlibStepIconRoot } from "./style";
function ColorlibStepIcon(props) {
  const { active, completed, icon, className } = props;

  const icons = {
    1: "01",
    2: "02",
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ["Summary", "Questionnaire"];

function Steppers({ activeStep }) {
  const styles = GetStyles();
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector activeStep={activeStep} />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Typography style={styles.stepperLabel}>{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default Steppers;
