/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Route, Switch } from "react-router";

// Settings
import { APP_SETTINGS } from "routes/router";
import routePaths from "routes/routePaths";

// Modules
import NotFoundPage from "modules/NotFoundPage";

// Tracking
import saveUserEvent from "monitor-user-data"

// Comps
import MenuBar from "components/MenuBar";
import InviteModal from "components/InviteModal";
import { Alert } from "components/Alert/Alert";

// Utils
import {
  convertUnixTimestampToUTC,
  getAuthTokenValue,
  removeAuthToken,
  setAuthToken,
} from "utils/auth-token-utils";
import { loginRequest } from "utils/auth-token-utils/auth-config";
import { useDispatch, useSelector } from "react-redux";
import { showToasterMessage } from "store/slices/modals_slice";
import EmailSentModal from "components/EmailSentModal";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Typography,
  createTheme,
  useTheme,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { employeeLoginByToken, guestLoginByToken } from "api";
import envConfig from "constants";
import useLocalStorage from "utils";
import LinkExpired from "components/TimeOutScreen";
import { goToRoute } from "store/actions/navigationActions";
import ProtectedRoute from "rbac/noAccessRoute";

// Notificaion
import Notification from "components/Notification";

function App() {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.router.location);
  const commonToast = useSelector((state) => state.modals);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const isLoginRoute = location.pathname === routePaths.LOGIN;
  const isRedirectRoute = location.pathname === routePaths.REDIRECT;
  const authDetails = useMsalAuthentication();
  const expirationTime = new Date(
    convertUnixTimestampToUTC(activeAccount?.idTokenClaims?.exp)
  );
  const expirationDate = activeAccount?.idTokenClaims?.expiration_date
    ? new Date(
        convertUnixTimestampToUTC(activeAccount?.idTokenClaims?.expiration_date)
      )
    : JSON.parse(localStorage.getItem("expirationDate"));
  console.log(expirationDate, "expirationDate");
  const [state] = useLocalStorage("isDarkMode", true);
  const [isApproved, setIsApproved] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const userLoggedIn = useRef(false);

  // Track user login
  useEffect(() => {
    if (activeAccount && !userLoggedIn.current) {
      const fullName = activeAccount?.idTokenClaims?.name;
      const email = activeAccount?.idTokenClaims?.email;
      const eventType = "Login"

      saveUserEvent(fullName, email, eventType); 
      userLoggedIn.current = true;
    }
  }, [activeAccount]); // Only run the effect if activeAccount changes

  console.log(activeAccount?.idTokenClaims?.Salesperson, "Salesperson");
  const isDarkMode = state;
  const currentTime = new Date();
  useEffect(() => {
    function preventBack(event) {
      this.history.go(1);
    }
    window.addEventListener("popstate", preventBack);
    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, []);
  const handleRedirect = (IS_MANUAL) => {
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0 || IS_MANUAL) {
      instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }
  };

  const getToken = async () => {
    const details = await authDetails.acquireToken(activeAccount);
    generateToken(details.idToken);
  };
  useEffect(() => {
    if (
      activeAccount?.idTokenClaims?.tid ===
      "ba9d205c-25f9-4b1f-9faa-519bd940802b"
    ) {
      localStorage.setItem(
        "salesPersonEmail",
        activeAccount?.idTokenClaims?.email //for employee, salesperson is themselves
      );
    } else if (
      typeof activeAccount?.idTokenClaims?.Salesperson !== "undefined" &&
      activeAccount?.idTokenClaims?.Salesperson !== null &&
      activeAccount?.idTokenClaims?.Salesperson.includes("@")
    ) {
      // for guest
      localStorage.setItem(
        "salesPersonEmail",
        activeAccount?.idTokenClaims?.Salesperson
      );
    } else {
      localStorage.setItem("salesPersonEmail", "myGTexpert@Camfil.com"); 
    }
    if (
      expirationDate &&
      activeAccount?.idTokenClaims?.tid !=
        "ba9d205c-25f9-4b1f-9faa-519bd940802b"
    ) {
      localStorage.setItem("expirationDate", JSON.stringify(expirationDate));
    }
    if (!activeAccount) {
      if (!getAuthTokenValue()) {
        handleRedirect();
      } else {
        removeAuthToken();
      }
    } else if (
      expirationDate &&
      expirationDate < currentTime &&
      activeAccount?.idTokenClaims?.tid !=
        "ba9d205c-25f9-4b1f-9faa-519bd940802b"
    ) {
      if (
        typeof activeAccount?.idTokenClaims?.Salesperson !== "undefined" &&
        activeAccount?.idTokenClaims?.Salesperson !== null &&
        activeAccount?.idTokenClaims?.Salesperson.includes("@")
      ) {
        localStorage.setItem(
          "salesPersonEmail",
          activeAccount?.idTokenClaims?.Salesperson
        );
      } else {
        localStorage.setItem("salesPersonEmail", "myGTexpert@Camfil.com");
      }
      localStorage.setItem("expirationDate", JSON.stringify(expirationDate));
      dispatch(goToRoute("/access-over"));
    } else if (
      activeAccount &&
      !activeAccount?.idToken &&
      !getAuthTokenValue()
    ) {
      getToken();
    } else if (activeAccount?.idToken && !getAuthTokenValue()) {
      generateToken();
    }
  }, [
    instance,
    activeAccount?.idToken,
    getAuthTokenValue(),
    activeAccount?.idTokenClaims?.tid,
  ]);
  console.log(activeAccount, "idTokenClaims");
  const generateToken = async (latestToken) => {
    try {
      if (activeAccount?.idTokenClaims?.email) {
        localStorage.setItem(
          "contactEmail",
          activeAccount?.idTokenClaims?.email
        );
      }
      const token = activeAccount?.idToken ?? latestToken;
      if (token) {
        let resp;
        if (
          activeAccount.idTokenClaims?.tid ==
          "ba9d205c-25f9-4b1f-9faa-519bd940802b"
        ) {
          resp = await employeeLoginByToken(token);
        } else if (
          activeAccount.idTokenClaims?.tid &&
          activeAccount.idTokenClaims?.tid !=
            "ba9d205c-25f9-4b1f-9faa-519bd940802b"
        ) {
          resp = await guestLoginByToken(token);
        }
        setAuthToken(resp.data);
        window.location.href = routePaths.DASHBOARD;
      }
    } catch {
      // localStorage.clear();
    }
  };
  console.log(envConfig, "fsdfsdfconfig");
  useEffect(() => {
    const handleEvent = (message) => {
      if (message.eventType === "authenticationFailed") {
        dispatch(
          showToasterMessage({
            message: message.error,
            toasterColor: "primary",
          })
        );
        handleRedirect();
        // Handle the authentication failure here
      }
      // Add other error handling logic as needed for different events
    };

    instance.addEventCallback(handleEvent);

    return () => {
      instance.removeEventCallback(handleEvent);
    };
  }, [instance]);

  let theme = createTheme({
    mode: "dark",
    palette: {
      labelPrimary: {
        main: "#000",
        dark: "#000",
        light: "#fff",
      },
      primary: {
        main: "#31FFCD",
      },
      secondary: {
        main: "#D1CC80",
      },
      info: {
        main: "#AD5BFF",
      },
      common: {
        main: "#44B0FF",
      },
      warning: {
        main: "#a09d9d",
      },
    },
    typography: {
      fontFamily: "Inter",
      // fontStyle: "normal",
      // fontSize: 18,
      // fontWeightLight: 300,
      // fontWeightRegular: 400,
      // fontWeightMedium: 500,
      // fontWeightBold: 600,
      // fontWeightHeavy: 900,
      h1: {
        fontWeight: 700,
        fontSize: "45px",
      },
      h2: {
        fontWeight: 700,
        fontSize: "24px",
      },
      h3: {
        fontWeight: 600,
        fontSize: "20px",
      },
      h4: {
        fontSize: "18px",
        fontWeight: 600,
      },
      h5: {
        fontSize: "16px",
        fontWeight: 500,
      },
      h6: {
        fontSize: "17px",
        fontWeight: 700,
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: "12px",
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: "10px",
      },
      subtitle3: {
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "auto",
      },
      button: {
        fontSize: "14px",
        fontWeight: 500,
      },
    },
  });

  const currentTheme = {
    ...theme,
    palette: {
      ...theme.palette,
      mode: isDarkMode ? "dark" : "light",
    },
  };
  document.getElementById("root").style.background = isDarkMode
    ? "#000"
    : "#F5F7FB";

  useEffect(() => {
    let flag = true;
    if (
      activeAccount?.idTokenClaims?.tid !=
      "ba9d205c-25f9-4b1f-9faa-519bd940802b"
    ) {
      flag = false;
      if (expirationDate) {
        if (typeof expirationDate == "string") {
          flag = new Date(expirationDate) > currentTime;
        } else {
          flag = expirationDate > currentTime;
        }
      }
    }
    setIsApproved(flag);
  }, [activeAccount?.idTokenClaims?.tid, expirationDate, currentTime]);

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <ThemeProvider theme={currentTheme}>
            <CssBaseline />
            {commonToast &&
              commonToast.toaster &&
              commonToast.toaster.message && (
                <Alert
                  autoHideDuration={7000}
                  onCloseCallback={() => {
                    dispatch(showToasterMessage(null));
                  }}
                  title={commonToast.toaster.message}
                  severity={commonToast.toaster.toasterColor}
                />
              )}
            <InviteModal />
            <EmailSentModal />
            <Notification />
            {!isLoginRoute && !isRedirectRoute && <MenuBar />}
            <Switch>
              {APP_SETTINGS.map((route, index) => {
                return (
                  <Route
                    key={route.path}
                    exact
                    path={route.path}
                    render={(path) => {
                      if (path.location.pathname === routePaths.NOT_FOUND) {
                        return <NotFoundPage />;
                      } else {
                        return (
                          <ProtectedRoute isApproved={isApproved}>
                            <route.component />
                          </ProtectedRoute>
                        );
                      }
                    }}
                  />
                );
              })}
              <Route
                key="2123"
                exact
                path={"/access-over"}
                render={() => <LinkExpired />}
              />
              <Route
                render={() => {
                  return (
                    <ProtectedRoute isApproved={isApproved}>
                      <NotFoundPage />
                    </ProtectedRoute>
                  );
                }}
              />
            </Switch>
          </ThemeProvider>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <RedirectScreen />
      </UnauthenticatedTemplate>
    </>
  );
}

const RedirectScreen = () => {
  const theme = useTheme();
  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size={80}
        style={{
          color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
        }}
      />
      <Typography
        sx={{
          color: "white",
          fontSize: "24px",
          fontWeight: 600,
          marginTop: "16px",
        }}
      >
        Redirecting...
      </Typography>
    </Box>
  );
};
App.propTypes = {};

export default App;
