// eslint-disable-next-line import/no-unresolved
import { configureStore } from "@reduxjs/toolkit";

// eslint-disable-next-line import/no-unresolved
import { createBrowserHistory } from "history";

// middlewares
import { routerMiddleware } from "connected-react-router";

// reducers
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: createRootReducer(history),

    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      routerMiddleware(history),
    ],
    preloadedState,
    devTools: process.env.NODE_ENV !== "production",
  });

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () =>
      store.replaceReducer(createRootReducer(history))
    );
  }

  return store;
}
