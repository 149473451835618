import authInstance from "./axios-instances/auth";

export function microsoftAuthenticate(redirect_uri) {
  const query = new URLSearchParams({ redirect_uri });
  return authInstance.get(`/auth/employee/login_url?${query.toString()}`);
}

export function generateTokenFromMicrosoftToken(payload) {
  return authInstance.post(`/auth/employee/login`, payload);
}

export function guestLogin(uuid) {
  return authInstance.post(`/auth/guest/login/${uuid}`);
}

export function guestLoginByToken(token) {
  return authInstance.post(`/auth/guest/login?id_token=${token}`);
}

export function employeeLoginByToken(token) {
  return authInstance.post(`/auth/employee/login?id_token=${token}`);
}

export function generateGuestToken(payload) {
  return authInstance.post(`/auth/guest/tokens`, payload);
}

export function getGuestTokenDetails(uuid) {
  return authInstance.get(`/auth/guest/tokens/${uuid}`);
}

export function sendEmailWithUUID(uuid) {
  return authInstance.get(`/auth/guest/tokens/${uuid}`);
}
