import React from "react";

// Mui
import { Box, Link } from "@mui/material";

// Assets
import valuerating from "../../src/assets/value_rating_img.svg";
import "./style.css";

function FuelRatingPage({ outputRating, grade }) {
  const getChangeDegree = (grade) => {
    switch (grade) {
      case "A++":
        return 99;
      case "A+":
        return 94;
      case "A":
        return 60;
      case "B":
        return 20;
      case "C":
        return -20;
      case "D":
        return -62;
      case "E":
        return -98;
      default:
        return -98;
    }
  };
  const getChangeLabelDegree = (grade) => {
    switch (grade) {
      case "A++":
        return -99;
      case "A+":
        return -94;
      case "A":
        return -60;
      case "B":
        return -20;
      case "C":
        return 20;
      case "D":
        return 62;
      case "E":
        return 98;
      default:
        return 98;
    }
  };
  return (
    <Box className="gauge-wrapper">
      <Link href="https://thevaluerating.com/" target="_blank">
        <img
          src={valuerating}
          alt="imgage_notfound"
          width="273px"
          height="240px"
        />
      </Link>
      <Box className="needle"></Box>
      <Box
        className="gauge-center"
        style={{ transform: `rotate(${getChangeDegree(grade)}deg)` }}
      >
        <Box
          className="content"
          style={{ transform: `rotate(${getChangeLabelDegree(grade)}deg)` }}
        >
          <Box className="label">OUTPUT RATING</Box>
          <Box className="number">{outputRating}%</Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FuelRatingPage;
