import React from "react";
import PropTypes from "prop-types";

//Material
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography, useTheme } from "@mui/material";

//Style
import { StyledSelect } from "./style";

function StandardDropdown({
  option,
  width,
  name,
  value,
  onChange,
  variant,
  ariaLabel,
  placeholder,
  defaultValue,
  id,
  select,
  size,
  ...props
}) {
  const theme = useTheme();
  return (
    <StyledSelect
      {...props}
      variant="standard"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      size={size}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: theme.palette.mode === "dark" ? "#000" : "#fff",
            color: theme.palette.mode === "dark" ? "#fff" : "#000",
            maxHeight: "200px",
            overflowY: "auto",
          },
        },
      }}
      inputProps={{
        style: {
          fontSize: "15px",
          fontWeight: 600,
          fontFamily: "Inter",
          lineHeight: "24px",
        },
        ariaLabel,
      }}
    >
      {option?.map((opt) => (
        <MenuItem key={opt.value} value={opt.value}>
          <Typography variant="h5">{opt.label}</Typography>
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

export default StandardDropdown;

StandardDropdown.propTypes = {
  option: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  width: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
