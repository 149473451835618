/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";

//Material
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

//Style
import GetStyles from "./style";
import { useSelector } from "react-redux";

const SmallTable = () => {
  const styles = GetStyles();
  const optimalData = useSelector((state) => state.globalData);
  const data = useMemo(() => {
    const data = [
      { id: 1, column1: "Site/Location:", column2: optimalData.site },
      { id: 2, column1: "Your Application:", column2: optimalData.application },
      {
        id: 3,
        column1: "Gas Turbine Capacity (MW)",
        column2: optimalData.output_input,
      },
    ];
    return data;
  }, [optimalData]);

  return (
    <Table sx={styles.tableContainer}>
      <TableBody>
        {data.map((row) => (
          <TableRow key={row.id}>
            <TableCell sx={styles.leftCell}>{row.column1}</TableCell>
            <TableCell sx={styles.rightCell}>{row.column2}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default SmallTable;
