import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inviteModal: false,
  toaster: null,
};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setInviteModal(state, action) {
      state.inviteModal = action.payload;
    },
    setEmailSentModal(state, action) {
      state.emailSentModal = action.payload;
    },
    showToasterMessage(state, action) {
      state.toaster = action.payload;
    },
    showNotification(state, action) {
      state.notification = action.payload; // Set the notification message and severity
    },
    hideNotification(state) {
      state.notification = null; // Hide the notification
    },
  },
});

export const {
  setInviteModal,
  setEmailSentModal,
  showToasterMessage,
  showNotification,
  hideNotification,
} = modalSlice.actions;
export default modalSlice.reducer;
