import axios from "axios";

// Utils
import getHeaders from "utils/get-headers";
import { handleCommonRequest, handleCommonResponse } from "./common-response";

let pdfInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: getHeaders(),
  responseType: "blob",
});
handleCommonRequest(pdfInstance, getHeaders());
handleCommonResponse(pdfInstance);

export default pdfInstance;
