import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataset: [],
};

const graphSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    setGraphData(state, action) {
      state.dataset = action.payload;
    },
  },
});

export const { setGraphData } = graphSlice.actions;
export default graphSlice.reducer;
