import { styled, useTheme } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color:
    theme?.palette?.mode !== "dark" ? theme?.palette?.grey[700] : "#31FFCD",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState &&
    ownerState?.active && {
      color: "#784af4",
    }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: "38px",
    height: "38px",
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

export const ColorlibConnector = styled(StepConnector)(
  ({ activeStep, theme }) => {
    return {
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 16,
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundColor:
            activeStep == 1 && theme.palette.mode === "dark"
              ? theme.palette.grey[700]
              : theme.palette.mode !== "dark" && activeStep != 2
              ? "#edf0f9"
              : theme.palette.mode !== "dark"
              ? "#008c64"
              : "#31FFCD",
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundColor:
            activeStep == 1 && theme.palette.mode === "dark"
              ? theme.palette.grey[700]
              : theme.palette.mode !== "dark" && activeStep != 2
              ? "#edf0f9"
              : theme.palette.mode !== "dark"
              ? "#008c64"
              : "#31FFCD",
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 7,
        border: 0,
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.grey[800]
            : theme.palette.mode !== "dark"
            ? "#008c64"
            : "#31FFCD",
        borderRadius: 1,
      },
    };
  }
);

export const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode !== "dark"
      ? "#edf0f9"
      : !ownerState?.completed
      ? theme.palette.grey[700]
      : theme.palette.mode !== "dark"
      ? "#008c64"
      : "#31FFCD",
  // backgroundColor: theme.palette.grey[700],
  zIndex: 1,
  color:
    theme.palette.mode !== "dark" && !ownerState?.completed
      ? "black"
      : ownerState.completed && theme.palette.mode == "dark"
      ? "black"
      : "white",
  width: "40px",
  fontSize: "14px",
  fontWeight: "600",
  height: "40px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState?.completed && {
    backgroundColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
  }),
}));

const GetStyles = () => {
  const theme = useTheme();
  return {
    stepperLabel: {
      color: theme.palette.mode !== "dark" ? "black" : "white",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "14px",
    },
  };
};
export default GetStyles;
