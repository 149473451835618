/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";

//Material
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";

//Style
import { StyledTableCell, StyledTableRow } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { setOptimalSolutionWithSizeLccData } from "store/slices/lcc_slice";
import { deserializeOptimalSolutionWithSizeLccData } from "utils/deserializers/lcc_data_deserializer";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Filter Type", "Static", "Pulse", "Static"),
  createData("Filter efficiency", "T5", "T8", "T8"),
  createData(
    "Size Constraints",
    '200mm[7.9"] to 350mm[13.8"]',
    '350mm[13.8"] to 600mm[23.6"]',
    '> 700mm[27.6"]'
  ),
];

function SolutionTable() {
  const dispatch = useDispatch();

  const { optimalSolutionWithSizeLimitation } = useSelector(
    (state) => state.filters
  );
  const lccData = useSelector((state) => state.lccData);

  useEffect(() => {
    dispatch(
      setOptimalSolutionWithSizeLccData(
        deserializeOptimalSolutionWithSizeLccData(
          optimalSolutionWithSizeLimitation.filter
        )
      )
    );
  }, [optimalSolutionWithSizeLimitation]);

  const rows = useMemo(() => {
    const data = [["Filter Type"], ["Filter Efficiency"], ["Size Constraints"]];
    lccData?.optimalSolutionWithSizeLimitation?.data?.forEach((item, index) => {
      data[0].push(item.filterType);
      data[1].push(item.filterEfficiency);
      data[2].push(item.sizeConstraints);
      return "";
    });
    return data;
  }, [lccData.optimalSolutionWithSizeLimitation]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell
              colSpan={5}
              align="center"
              isFirstRow
              sx={{ textTransform: "uppercase" }}
            >
              Optimal solution for existing configuration
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow className="second-table-head-row">
            <StyledTableCell>
              Number of Stages:{" "}
              {lccData?.optimalSolutionWithSizeLimitation.numberOfStages}
            </StyledTableCell>
            {lccData?.optimalSolutionWithSizeLimitation?.data?.map((item) => {
              return (
                <StyledTableCell key={item.name} align="center">
                  {item.name}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <StyledTableRow key={row?.[0]} isFirstRow={index === 0}>
              {row.map((item, innerIndex) => {
                return (
                  <StyledTableCell
                    key={item}
                    component="th"
                    scope="row"
                    isFirstChild={innerIndex === 0}
                  >
                    {item}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SolutionTable;
