import { connectRouter } from "connected-react-router";
import flagsReducer from "./slices/flags_slice";
import modalReducer from "./slices/modals_slice";
import globalReducer from "./slices/global_data_slice";
import filterReducer from "./slices/filters_slice";
import graphReducer from "./slices/graph_slice";
import invitedCustomerReducer from "./slices/invited_customer_slice";
import lccReducer from "./slices/lcc_slice";
import userSlice from "./slices/user_slice";
// configure root reducer
const reducers = (history) => ({
	router: connectRouter(history),
	flags: flagsReducer,
	modals: modalReducer,
	globalData: globalReducer,
	filters: filterReducer,
	graph: graphReducer,
	invitedCustomer: invitedCustomerReducer,
	lccData: lccReducer,
	users: userSlice,
});

export default reducers;
