import { styled } from "styled-components";
import { Modal } from "@mui/material";

export const StyledModal = styled(Modal)({
  "& .MuiBox-root": {
    border: "none",
    outline: "none",
    backgroundColor: "#282A30",
    color: "lightgrey",
    borderRadius: "10px",
    maxWidth: "600px",
    width: "500px",
  },
  "& .MuiTypography-root": {
    textAlign: "center",
    fontSize: "33px",

    color: "white",
  },
  "& .MuiTypography-body1": {
    fontSize: "18px",
  },
  "& .MuiButtonBase-root": {
    display: "flex",
    width: "300px",
    marginLeft: "70px",
    backgroundColor: "#31FFCD",
    color: "black",

    textTransform: "Capitalize",
    marginTop: "40px",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#31FFCD",
    },
  },
});

const styles = {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "5%",
  },
};

export default styles;
