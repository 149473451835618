/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Material
import Box from "@mui/material/Box";

//Style
import styles, {
  CustomTab,
  CustomTabs,
  CustomNoneTabs,
  CustomNoneTab,
} from "./style";

// utils
import { selectedTabDeserialize, serializeSelectedTab } from "./utils";
import { getOptimalTypeSolutionData, getPreFilterMinimumEfficiency } from "api";
import {
  setCurrLoading,
  setCurrentSolution,
  setOptimalSolutionWithSizeLimitation,
  setOverallFilters,
} from "store/slices/filters_slice";
import { convertRatingToValue } from "modules/Home/utils";
import { setGraphData } from "store/slices/graph_slice";
import {
  deserializeGraph,
  manipulateCurrentSolution,
  manipulateSizeSolution,
} from "utils/deserializers/graph_deserializer";
import { deserializeFilter } from "utils/deserializers/filters_deserializer";
import { showToasterMessage } from "store/slices/modals_slice";
import { showNotification } from "store/slices/modals_slice";

function Radios({ selection, setSelection, section }) {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(0);
  const { site, application, output_input } = useSelector(
    (state) => state.globalData
  );
  const { stage1, stage2, stage3, globalOptimalSolution, ...filterData } =
    useSelector((state) => state.filters);
  const graphData = useSelector((state) => state.graph.dataset);
  const handleTabChange = (event, newValue) => {
    dispatch(setCurrLoading(true));
    const payload = {
      site,
      application,
      output_input,
      final_filter: stage3.name,
      final_life: stage3.life,
      stage1_type:
        section === "stage1"
          ? selectedTabDeserialize(newValue)
          : stage1.filterType,
      stage2_type:
        section === "stage2"
          ? selectedTabDeserialize(newValue)
          : stage2.filterType,
      stage1_size: "None",
      stage2_size: "None",
    };

    getPreFilterMinimumEfficiency(site, application, payload)
      .then((res) => {
        const data = res.data;
        let minEfficiency, rating;
        if (data["Stage1 minial Efficiency"][0] === "TInf") {
          minEfficiency = "T2";
        } else {
          minEfficiency = data["Stage1 minial Efficiency"][0];
        }
        if (stage1.rating <= convertRatingToValue(minEfficiency)) {
          rating = minEfficiency;
        } else {
          rating = stage1.rating;
        }
        const data1 = {
          ...stage1,
          minEfficiency,
          rating,
        };
        if (section === "stage1") {
          data1.filterType = selectedTabDeserialize(newValue);
        }
        let minEfficiency2, rating2;
        if (data["Stage2 minial Efficiency"][0] === "TInf") {
          minEfficiency2 = "T2";
        } else {
          minEfficiency2 = data["Stage2 minial Efficiency"][0];
        }
        if (stage1.rating <= convertRatingToValue(minEfficiency2)) {
          rating2 = minEfficiency2;
        } else {
          rating2 = stage2.rating;
        }
        const data2 = {
          ...stage2,
          minEfficiency: minEfficiency2,
          rating: rating2,
        };
        if (section === "stage2") {
          data2.filterType = selectedTabDeserialize(newValue);
        }
        const currPayload = getFilterTypePayloadFromNewVal(section, newValue);
        console.log(currPayload, "radios currPayload");
        console.log(payload, "radios payload");

        const currentData = JSON.parse(
          JSON.stringify({
            stage1: { ...stage1, ...data1 },
            stage2: { ...stage2, ...data2 },
            stage3,
          })
        );

        if (
          (currPayload.stage2_type === "Pulse" && section === "stage1") ||
          (currPayload.stage1_type !== "None" &&
            section == "stage2" &&
            currPayload.stage2_type === "Pulse")
        ) {
          setSelectedTab(2);
          currentData[section] = {
            ...currentData[section],
            filterType: selectedTabDeserialize(2),
          };
          currPayload.stage1_type = "None";
          currPayload.stage1_size = "None";
          dispatch(
            showNotification({
              message:
                "Filters in front of the pulse filter are not recommended and have been removed for optimal performance.",
              severity: "error",
            })
          );
        } else if (
          currPayload.stage3_type === "Pulse" &&
          (currPayload.stage1_type !== "None" ||
            currPayload.stage2_type !== "None")
        ) {
          setSelectedTab(2);
          currentData[section] = {
            ...currentData[section],
            filterType: selectedTabDeserialize(2),
          };
          currPayload.stage1_type = "None";
          currPayload.stage1_size = "None";
          currPayload.stage2_type = "None";
          currPayload.stage2_size = "None";
          dispatch(
            showNotification({
              message:
                "Filters in front of the pulse filter are not recommended and have been removed for optimal performance.",
              severity: "error",
            })
          );
        }

        getCustomOptimalAndOptimalWithType(
          section,
          currPayload,
          newValue,
          data1,
          data2
        );
      })
      .catch((err) => {
        dispatch(
          showNotification({
            message:
              "The FAST simulator doesn’t support the requested configuration. Please request a FULL LCC if this is your current site configuration.",
            severity: "primary",
          })
        );
        dispatch(setCurrLoading(false));
        console.log(err);
      });
  };
  const getFilterTypePayloadFromNewVal = (at, value) => {
    const payload = {
      output_input,
      stage1_size: stage1.filterDepth || "None",
      // stage1_eff: convertValueToRating(stage1.rating),
      stage1_type: stage1.filterType,
      stage2_size: stage2.filterDepth || "None",
      // stage2_eff: convertValueToRating(stage2.rating),
      stage2_type: stage2.filterType,
      stage3_size: stage3.filterDepth || "None",
      // stage3_eff: convertValueToRating(stage3.rating),
      stage3_type: stage3.filterType,
      final_life: stage3.life,
    };
    if (at === "stage1") {
      payload.stage1_type = selectedTabDeserialize(value);
      payload.stage1_size = "None";
    }
    if (at === "stage2") {
      payload.stage2_type = selectedTabDeserialize(value);
      payload.stage2_size = "None";
    }
    if (at === "stage3") {
      payload.stage3_type = selectedTabDeserialize(value);
      payload.stage3_size = "None";
    }
    return payload;
  };
  useEffect(() => {
    const newSelection = {
      ...selection,
      filterType: selectedTabDeserialize(selectedTab),
    };
    setSelection({ data: newSelection });
  }, [selectedTab]);

  useEffect(() => {
    if (selection?.filterType) {
      setSelectedTab(serializeSelectedTab(selection?.filterType));
    }
  }, [selection?.filterType]);

  const getCustomOptimalAndOptimalWithType = (
    at,
    payload,
    value,
    data1,
    data2
  ) => {
    getOptimalTypeSolutionData(site, application, payload)
      .then((res) => {
        dispatch(setCurrentSolution(res.data));
        dispatch(
          setGraphData(manipulateCurrentSolution(graphData, res.data?.detail))
        );

        if (
          res.data &&
          res.data.filter &&
          res.data.filter[0] &&
          res.data.filter[0].Stage === "No filters match all the constraints"
        ) {
          dispatch(
            showNotification({
              message:
                "The FAST simulator doesn’t support the requested configuration. Please request a FULL LCC if this is your current site configuration.",
              severity: "primary",
            })
          );
        } else {
          const currentData = JSON.parse(
            JSON.stringify({
              stage1: { ...stage1, ...data1 },
              stage2: { ...stage2, ...data2 },
              stage3,
            })
          );

          currentData[at] = {
            ...currentData[at],
            filterType: selectedTabDeserialize(value),
          };
          dispatch(
            setOverallFilters(
              deserializeFilter(res.data.filter, currentData, dispatch)
            )
          );

          if (
            payload.stage1_type == "Pulse" ||
            payload.stage2_type == "Pulse" ||
            payload.stage3_type == "Pulse" ||
            payload.stage1_type !==
              globalOptimalSolution.filter[0]?.["Filter Type"] ||
            payload.stage2_type !==
              globalOptimalSolution.filter[1]?.["Filter Type"] ||
            payload.stage3_type !==
              globalOptimalSolution.filter[2]?.["Filter Type"]
          ) {
            const sizeSolution = manipulateSizeSolution(
              graphData,
              res.data?.detail
            );
            dispatch(setGraphData(sizeSolution));
            dispatch(setOptimalSolutionWithSizeLimitation(res.data));
            dispatch(setCurrentSolution(res.data));
            console.log(sizeSolution, "radio sizeSolution");
            dispatch(
              setGraphData(
                manipulateCurrentSolution(sizeSolution, res.data?.detail)
              )
            );
          } else {
            const initGraph = deserializeGraph(globalOptimalSolution.detail);
            dispatch(setCurrentSolution(res.data));
            dispatch(
              setGraphData(
                manipulateCurrentSolution(initGraph, res.data?.detail)
              )
            );
          }
        }
        dispatch(setCurrLoading(false));
      })
      .catch((err) => {
        console.log(err, "error");
        dispatch(
          showNotification({
            message:
              "The FAST simulator doesn’t support the requested configuration. Please request a FULL LCC if this is your current site configuration.",
            severity: "primary",
          })
        );
        dispatch(setCurrLoading(false));
        console.log(err);
      });
  };

  return (
    <Box style={styles.prefilterContainer}>
      <Box>
        {section === "stage3" ? (
          <CustomNoneTabs
            key={selection?.filterType}
            value={selectedTab}
            indicatorColor="transparent"
            onChange={handleTabChange}
            centered
          >
            <CustomNoneTab disabled={filterData.currLoading} label="Static" />
            <CustomNoneTab disabled={filterData.currLoading} label="Pulse" />
          </CustomNoneTabs>
        ) : (
          <CustomTabs
            key={selection?.filterType}
            value={selectedTab}
            indicatorColor="transparent"
            onChange={handleTabChange}
            centered
          >
            <CustomTab disabled={filterData.currLoading} label="Static" />
            <CustomTab disabled={filterData.currLoading} label="Pulse" />
            <CustomTab disabled={filterData.currLoading} label="None" />
          </CustomTabs>
        )}
      </Box>
    </Box>
  );
}

export default Radios;
