import React from 'react';
import PropTypes from 'prop-types';

//Style
import { StyledTextarea } from "./style"

function CustomTextArea({
  name,
  variant,
  onChange,
  value,
  placeholder,
  disabled,
  minRows,
  width,
  ...props
}) {

  return (
      <StyledTextarea
        variant={variant}
        id={name}
        minRows={minRows}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        multiline
        width={width}
        {...props}
      />
  );
}

export default CustomTextArea;

CustomTextArea.propTypes = {
  variant: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.func,
  minRows: PropTypes.number,
};
