import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#272b2c" : "#f6fbfd",
  borderRadius: "6px",
  width: "100%",
  height: "35px",
  minHeight: "30px",
  "& .MuiTabs-fixed": {
    height: "35px",
    "& .MuiTab-root": {
      minHeight: "35px",
      fontSize: "12px",
    },
  },
}));
export const CustomNoneTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#272b2c" : "#f6fbfd",
  borderRadius: "6px",
  width: "100%",
  height: "35px",
  minHeight: "30px",
  "& .MuiTabs-fixed": {
    height: "35px",
    "& .MuiTab-root": {
      minHeight: "35px",
      fontSize: "12px",
    },
  },
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#D4D4D4" : "#373737",
  padding: "18x 16px",
  width: "33%",
  height: "35px",
  textTransform: "none",
  "&.Mui-selected": {
    fontWeight: "600",
    fontSize: "14px",
    backgroundColor: theme.palette.mode === "dark" ? "#223b37" : "#deeeec",
    color: theme.palette.mode !== "dark" ? "#008c64" : "#47FFC6",
  },
}));

export const CustomNoneTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#D4D4D4" : "#373737",
  padding: "18x 16px",
  width: "140px",
  height: "35px",
  textTransform: "none",
  "&.Mui-selected": {
    fontWeight: "600",
    fontSize: "14px",
    backgroundColor: theme.palette.mode === "dark" ? "#223b37" : "#deeeec",
    color: theme.palette.mode !== "dark" ? "#008c64" : "#47FFC6",
  },
}));

const styles = {
  prefilterContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
