import { Slider as MuiSlider } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

export const Slider = styled(MuiSlider)(({ noSlot, theme }) => ({
  color:
    theme.palette.mode !== "dark" ? "#008c64 !important" : "#31FFCD !important",
  height: 6,
  width: "97%",
  "&.Mui-disabled": {
    pointerEvents: "none",
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .css-1tpqn52-MuiTypography-root": {
    fontSize: "10px !important",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: noSlot
      ? "transparent !important"
      : theme.palette.mode !== "dark"
      ? "white !important"
      : "black !important",
    border: noSlot ? "none" : "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 4,
    minWidth: 35,
    maxWidth: 55,
    width: "auto",
    height: 29,
    backgroundColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
    borderRadius: "6px",
    color: theme.palette.mode !== "dark" ? "white" : "black",
  },
  "& .my-thumb": {
    backgroundColor:
      theme.palette.mode !== "dark" ? "black !important" : "white !important",
    border: "2px solid red",
  },
  "& .css-14pt78w-MuiSlider-rail": {
    background: "lightgray !important",
  },
  "& .css-1cq0smy-MuiGrid-root": {
    fontSize: "13px !important",
  },
  "& .MuiTypography-root MuiTypography-body1 css-1tpqn52-MuiTypography-root": {
    fontSize: "13px !important",
  },
}));

const GetStyles = () => {
  const theme = useTheme();
  return {
    sliderContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
    },
    container: {
      display: "flex",
      gap: "5px",
    },
    sliderLabel: {
      color: theme?.palette.mode == "dark" ? "white" : "black",
      fontSize: "13px",
      fontWeight: "600",
    },
  };
};

export default GetStyles;
