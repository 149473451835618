import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  engineManufacturerList: [],
  turbineTypeList: [],
};

const globalSlice = createSlice({
  name: "globalData",
  initialState,
  reducers: {
    setDataOnOptimizeClick(state, action) {
      const { site, application, output_input } = action.payload;
      state.site = site;
      state.application = application;
      state.output_input = output_input;
    },
    setGlobalOptimalGraphData(state, action) {
      const { detail } = action.payload;
      state.graphData = detail;
    },
    setSiteInfo(state, action) {
      state.siteInfo = action.payload;
    },
    setGraphUrl(state, action) {
      state.graphURL = action.payload;
    },
    setCoordinates(state, action) {
      state.cordinates = action.payload;
    },
    setEngineManufacturerList(state, action) {
      state.engineManufacturerList = action.payload;
    },
    setTurbineTypeList(state, action) {
      state.turbineTypeList = action.payload;
    },
  },
});

export const {
  setDataOnOptimizeClick,
  setGlobalOptimalGraphData,
  setSiteInfo,
  setGraphUrl,
  setCoordinates,
  setEngineManufacturerList,
  setTurbineTypeList,
} = globalSlice.actions;
export default globalSlice.reducer;
