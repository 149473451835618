import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: {},
};

const invitedCustomerSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    setInvitedCustomerDetails(state, action) {
      state.details = action.payload;
    },
  },
});

export const { setInvitedCustomerDetails } = invitedCustomerSlice.actions;
export default invitedCustomerSlice.reducer;
