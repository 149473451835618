import React from "react";

// Mui
import { Box } from "@mui/material";

function NotFoundPage(props) {
	return <Box>NotFound</Box>;
}

NotFoundPage.propTypes = {};
export default NotFoundPage;
