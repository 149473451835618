import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const StyledBox = styled(Box)({
  width: "100%",
  margin: "0 auto",
});

export const MyStyledTypography = styled(Typography)(({ customColor }) => ({
  color: customColor || "#FFFFFF",
  textAlign: "center",
  fontFamily: "Inter",
  lineHeight: "57.6px",
  marginTop: "2rem",
  "&.MuiTypography-h1": {
    fontSize: "3vw",
    fontWeight: "700",
  },
}));

const GetStyles = () => {
  const theme = useTheme();
  return {
    labelStyles: {
      color: theme.palette.mode == "dark" ? "#FFFFFF" : "#000",
      width: "100%",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "21.78px",
      fontFamily: ["Inter"].join(","),
    },
    CheckboxStyle: {
      color: theme.palette.mode !== "dark" ? "#008c64 !important" : "#31FFCD",
      borderRadius: "4px",
      height: "24px",
      width: "24px",
    },
    Checkboxlabel: {
      color: theme.palette.mode == "dark" ? "#FFFFFF" : "#000",
      fontSize: "20px",
      fontWeight: "600",
      lineHeight: "24px",
      marginLeft: "12px",
      fontFamily: "Inter",
      marginRight: "13px",
    },
    TypographyStyle: {
      color: theme.palette.mode == "dark" ? "#FFFFFF" : "#000",
      marginRight: "8px",
      fontSize: "18px",
      fontWeight: "600px",
      fontFamily: "Inter",
      lineHeight: "21.6px",
    },
    TypographyContent: {
      color: theme.palette.mode == "dark" ? "#FFFFFF" : "#000",
      marginRight: "8px",
      marginLeft: "13px",
      fontSize: "18px",
      fontFamily: "Inter",
      fontWeight: "600px",
      lineHeight: "21.6px",
    },
    ContainerStyle: {
      display: "flex",
      justifyContent: "center",
      gap: "30px",
      padding: "30px",
      flexDirection: "column",
      width: "100%",
      margin: "auto",
    },
    StepperStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
    },
    NextButtonStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "40px",
    },
    BoxContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      margin: "auto",
    },
    EngineContainerStyle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: "34px",
      marginTop: "60px",
      alignItems: "center",
    },
    TurbineStyle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: "34px",
      alignItems: "center",
    },
    CurrencyStyle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: "34px",
      alignItems: "center",
    },
    UnitStyle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: "34px",
      alignItems: "center",
    },
    CheckboxContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "10px",
      marginBottom: "43px",
    },
    DowntimeStyle: {
      backgroundColor: "#A4CDBD",
      borderRadius: "6px",
      borderColor: "1px solid #A4CDBD",
      padding: "2px",
      paddingLeft: "20px",
    },
    DowntimeCompressor: {
      backgroundColor: "#A4CDBD",
      borderRadius: "6px",
      borderColor: "1px solid #A4CDBD",
      padding: "2px",
      paddingLeft: "20px",
    },
    CompressorStyle: {
      backgroundColor: "#A4CDBD",
      borderRadius: "6px",
      borderColor: "1px solid #A4CDBD",
      padding: "2px",
      paddingLeft: "15px",
    },
    SystemStyle: {
      backgroundColor: "#A4CDBD",
      borderRadius: "6px",
      borderColor: "1px solid #A4CDBD",
      padding: "2px",
      paddingLeft: "23px",
    },
    ButtonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "48px",
      marginBottom: "48px",
    },
    EngineDropdown: {
      backgroundColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
      borderColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
      opacity: theme.palette.mode !== "dark" ? "0.5" : "1",
    },
    turbineTextStyle: {
      fontSize: "15px",
      fontWeight: 600,
      fontFamily: "Inter",
      lineHeight: "24px",
      zIndex: "1",
    },
    compressorStyle: {
      fontSize: "18px",
      fontWeight: "600px",
      fontFamily: "Inter",
      lineHeight: "24px",
      marginTop: "15px",
      marginRight: "8px",
    },
    TurbineDropdown: {
      backgroundColor: "#A4CDBD",
      borderColor: "#A4CDBD",
    },
    DropdownStyle: {
      marginTop: "16px",
      marginBottom: "37px",
      zIndex: "22",
    },
    bg: {
      width: "451px",
      height: "371px",
      position: "absolute",
      bottom: "206px",
      right: "-261px",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
    fullLoad: {
      width: "451px",
      height: "371px",
      position: "absolute",
      bottom: "361px",
      right: "-247px",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
    costoffuelbg: {
      width: "451px",
      height: "371px",
      position: "absolute",
      top: "48%",
      right: "-224px",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
    containerbg: {
      width: "811px",
      height: "514px",
      position: "absolute",
      top: "62px",
      left: "-294px",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
  };
};

export default GetStyles;
