import React, { useState, useEffect } from "react";

//Material
import {
  Grid,
  Box,
  Typography,
  FormControl,
  Link,
  useTheme,
} from "@mui/material";

//Comps
import CustomTextArea from "../../components/Textarea";
import OutlinedButton from "../../components/Button";
import StandardDropdown from "../../components/StandardDropdown";
import EfficiencyPopup from "../../components/EfficiencyPopup";

//Assets
import HorizantalLine from "../../assets/HorizantalLine.svg";
import logobg from "../../assets/logobg.svg";

//Style
import GetStyles, { MyStyledTypography, StyledBox } from "./style";

import { useDispatch, useSelector } from "react-redux";

import { options } from "./utils";

import { setQuery } from "../../store/actions/navigationActions";
import { setIsSiteSelected } from "../../store/slices/flags_slice";
import { sendContactEmail, sendRepresentativeEmail } from "api";
import {
  showNotification,
  showToasterMessage,
} from "store/slices/modals_slice";
import { useMsal } from "@azure/msal-react";

const ContactPage = () => {
  const dispatch = useDispatch();
  const [contactOption, setContactOption] = useState(
    "Select the subject of your request"
  );
  const styles = GetStyles();
  const search = useSelector((state) => state.router.location.search);
  const [textareaValue, setTextareaValue] = useState("");
  const searchParams = new URLSearchParams(search);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { userDetails } = useSelector((state) => state.users);
  let salesPersonEmail = localStorage.getItem("salesPersonEmail");
  const theme = useTheme();
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const { site, application, output_input } = useSelector(
    (state) => state.globalData
  );
  // console.log(
  //   localStorage.getItem("contactEmail"),
  //   "localStorage contactEmail"
  // );
  console.log(activeAccount, "activeAccount");
  console.log(localStorage, "localStorage");

  useEffect(() => {
    // Check if all required fields are filled
    const isValid =
      contactOption !== "Select the subject of your request" &&
      textareaValue.trim() !== "";
    setIsFormValid(isValid);
  }, [contactOption, textareaValue]);

  const handleDropdownChange = (e) => {
    setContactOption(e.target.value);
  };

  const handleSubmit = () => {
    // const payload = {
    //   subject: contactOption,
    //   description: textareaValue,
    //   to: "chang.hong.camfil@gmail.com" || userDetails?.SalesEmail,
    //   contactEmail: activeAccount?.idTokenClaims?.emails || userDetails?.Email,
    //   contactName: activeAccount?.idTokenClaims?.name || userDetails?.Name,
    // };
    const salesPersonEmail = localStorage.getItem("salesPersonEmail");
    const payload = {
      customer_name: activeAccount.idTokenClaims.name,
      customer_email:
        localStorage?.getItem("contactEmail") ||
        activeAccount?.idTokenClaims?.email,
      query_subject: contactOption,
      query_topic: contactOption,
      query_details: textareaValue,
      site: site,
      application: application,
      output: output_input + "MW",
      torecip:
        activeAccount?.idTokenClaims?.tid ==
        "ba9d205c-25f9-4b1f-9faa-519bd940802b"
          ? localStorage?.getItem("contactEmail") ||
            activeAccount?.idTokenClaims?.email
          : salesPersonEmail && salesPersonEmail != "Not set"
          ? salesPersonEmail
          : "myGTexpert@Camfil.com",
      ccrecip: "chang.hong@camfil.com",
    };

    sendContactEmail(payload)
      .then((res) => {
        // dispatch(
        //   showNotification({
        //     message:
        //       "Your questionnaire has been successfully submitted to your sales representative, and you will be contacted shortly.",
        //     severity: "success",
        //   })
        // );
        dispatch(setQuery("submited", true));
        dispatch(setIsSiteSelected(true));
        setPopupOpen(true);
      })
      .catch((err) => {
        dispatch(
          showNotification({
            message:
              "Oops! Something went wrong, and the email could not be sent. Please contact myGTexpert@Camfil.com for assistance.",
            severity: "error",
          })
        );
      });
  };

  const handleCloseT1Modal = () => {
    setPopupOpen(false);
  };

  const modalData = [
    {
      id: "t1",
      title: "Your query has been submitted successfully.",
      description:
        "Your Camfil Sales Representative Will Be Getting In Touch With You Shortly.",
    },
  ];

  salesPersonEmail =
    activeAccount?.idTokenClaims?.tid == "ba9d205c-25f9-4b1f-9faa-519bd940802b"
      ? localStorage?.getItem("contactEmail") ||
        activeAccount?.idTokenClaims?.email
      : salesPersonEmail && salesPersonEmail != "Not set"
      ? salesPersonEmail
      : "myGTexpert@Camfil.com";
  return (
    <Box style={styles.pageContainer}>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <MyStyledTypography variant="h1">
            Contact Your Camfil Representative
          </MyStyledTypography>

          <Box sx={styles.containerStyle}>
            <StyledBox maxWidth="620px">
              <FormControl sx={{ width: "100%" }}>
                <Typography sx={styles.labelStyles}>Subject:</Typography>
                <StandardDropdown
                  fullWidth
                  marginTop="20px"
                  customBackgroundColor="#a4cdbd"
                  option={options}
                  value={contactOption}
                  name="contactOption"
                  style={styles.customDropdown}
                  onChange={handleDropdownChange}
                  inputProps={{
                    style: {
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                      lineHeight: "24px",
                    },
                  }}
                  sx={{ fontSize: "17px", fontWeight: 500 }}
                />

                <Typography sx={styles.labelStyles}>Description:</Typography>
                <CustomTextArea
                  width="100%"
                  variant="standard"
                  minRows="5"
                  value={textareaValue}
                  onChange={handleTextareaChange}
                  placeholder="Tell us how we can help you"
                />

                <OutlinedButton
                  variant="outlined"
                  customcolor={
                    theme.palette.mode !== "dark" ? "white" : "#000000"
                  }
                  label="Submit"
                  onClick={handleSubmit}
                  customBackgroundColor={
                    theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                  }
                  customBorderColor={
                    theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                  }
                  disabled={!isFormValid}
                  width="100%"
                  sx={{
                    marginTop: "27px",
                    "&.Mui-disabled": {
                      background: "#1C5E4E",
                      color: "#EDF5F2",
                    },
                  }}
                />

                <EfficiencyPopup
                  isOpen={popupOpen}
                  open={popupOpen}
                  handleClosePopup={handleCloseT1Modal}
                  title={modalData.find((item) => item.id === "t1").title}
                  description={
                    modalData.find((item) => item.id === "t1").description
                  }
                  buttonLabel="Okay"
                />

                <Box width="100%">
                  <img
                    src={HorizantalLine}
                    alt="img-notfound"
                    width="100%"
                    height="32px"
                    style={{ marginTop: "28px" }}
                  />
                </Box>
                <img src={logobg} alt="camfil" style={styles.bg} />
              </FormControl>
            </StyledBox>

            <Box maxWidth="680px" margin="0.5rem auto 2rem">
              <Typography style={styles.contactLabel}>
                Contact Your Respective Sales Person At:
              </Typography>
              <Link
                href={`mailto:${salesPersonEmail}`}
                style={{
                  color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
                  textDecorationColor:
                    theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
                }}
              >
                <Typography align="center" sx={styles.emailLabel}>
                  {salesPersonEmail == "undefined"
                    ? "myGTexpert@Camfil.com"
                    : salesPersonEmail}
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Box>
  );
};

ContactPage.propTypes = {};

export default ContactPage;
