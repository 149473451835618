import React from "react";
import PropTypes from "prop-types";

//Material
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

//Style
import { BootstrapInput } from "./style.js";
function CustomTextField({
	name,
	variant,
	type,
	onChange,
	value,
	onSearch,
	label,
	placeholder,
	defaultValue,
	errorMessage,
	ariaLabel,
	showSearchIcon = true,
	width,	
	...props
}) {
	return (
		<BootstrapInput
			variant={variant}
			id={name}
			type={type}
			label={label}
			onChange={onChange}
		  value={value} 
			width={width}
			placeholder={placeholder}
			defaultValue={defaultValue}
			inputProps={{
				style: {
					fontSize: "15px",
					fontWeight: 600,
					fontFamily:"Inter",
					lineHeight:'24px',
				},
				ariaLabel
			}}
			{...props}
			endAdornment={
				showSearchIcon ? (
					<InputAdornment onClick={onSearch}>
						<SearchIcon />
					</InputAdornment>
				) : null
			}
		/>
	);
}
export default CustomTextField;
CustomTextField.propTypes = {
	variant: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onSearch: PropTypes.func,
	showSearchIcon: PropTypes.bool,
	width: PropTypes.string,
	//   disabled: PropTypes.boolean,
};
