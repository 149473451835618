import { removeTokenAndGotoLogin } from "utils/auth-token-utils";

export const handleCommonResponse = (instance) => {
  return instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error) {
        const { status } = error?.response;
        if (status === 403) {
          removeTokenAndGotoLogin();
        } else if (status === 401) {
          removeTokenAndGotoLogin();
        } else return Promise.reject(error);
      }
    }
  );
};

export const handleCommonRequest = (instance, headers) => {
  return instance.interceptors.request.use(
    (config) => {
      config.headers = headers;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
