import { styled } from "@mui/material/styles";
import Input from "@mui/material/Input";

export const StyledInput = styled(Input)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000",
  borderBottom: "1px solid #2c2d2d",
  fontSize: "12px",
  lineHeight: "24px",
  fontWeight: "500px",
  fontFamily: "Inter",
  "& .MuiSvgIcon-root": {
    color: "#31FFCD",
    position: "absolute",
    right: 0,
    border: "2px",
    paddingRight: "5px",
    cursor: "pointer",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
  },
  "& input": { padding: "0.75rem !important" },
}));
