/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import get from "lodash/get";

// Comps
import Radio from "../../components/Radios";

// Utils
import { useDispatch, useSelector } from "react-redux";

//Material
import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Styles
import GetStyles from "./style";

// utils
import {
  convertRatingToValue,
  convertValueToRating,
  getFilterImage,
  getNumberOfClouds,
} from "./utils";
import {
  setCurrLoading,
  setCurrentSolution,
  setOptimalSizeSolution,
  setOptimalSolutionWithSizeLimitation,
  setOverallFilters,
  setStage1Filter,
  setStage2Filter,
  setStage3Filter,
  storeValueRating,
} from "store/slices/filters_slice";
import {
  customOptimalFunc,
  fetchFilterDepths,
  getOptimalSizeSolutionData,
  getPreFilterMinimumEfficiency,
  getValueRating,
  optimalFinalEffSolution,
  optimalFinalLifeSolution,
} from "api";
import {
  deserializeGraph,
  manipulateCurrentSolution,
  manipulateSizeSolution,
} from "utils/deserializers/graph_deserializer";
import { setGraphData } from "store/slices/graph_slice";
import { deserializeFilter } from "utils/deserializers/filters_deserializer";
import html2canvas from "html2canvas";

// Comps
import LccGraph from "../../components/LccGraph";
import Info from "../../components/Info";
import ValueRatingPopup from "../../components/ValueRatingPopup";
import FuelRatingPage from "FuelRatingPage";
import StandardDropdown from "../../components/StandardDropdown";
import Slider from "../../components/Slider";
import EfficiencyModal from "../../components/Modal/efficiencyModal";
import { showToasterMessage } from "store/slices/modals_slice";
import routePaths from "routes/routePaths";
import { goToRoute } from "store/actions/navigationActions";
import { setGraphUrl } from "store/slices/global_data_slice";

// notification
import { showNotification } from "store/slices/modals_slice";

// Global vars
let filterRatingTimeout = null;
let filterLifeTimeout = null;
let filterRatingChangeTimeout = null;
let filterDepthChangeTimeout = null;
function Home(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const styles = GetStyles();
  const chartRef = useRef();
  const { site, application, output_input } = useSelector(
    (state) => state.globalData
  );
  const isONG = application?.toLowerCase().includes("o&g");
  const { isSiteSelected } = useSelector((state) => state.flags);
  const {
    stage1,
    stage2,
    stage3,
    valueRating,
    globalOptimalSolution,
    optimalSolutionWithSizeLimitation,
    ...filterData
  } = useSelector((state) => state.filters);

  const graphData = useSelector((state) => state.graph.dataset);
  const [valueRatingOpen, setValueRatingOpen] = useState(false);
  const [commonModalOpen, setCommonModalOpen] = useState("");
  const [filterDepthOptions1, setFilterDepthOptions1] = useState([
    { value: "None", label: "Select Filter Depth" },
  ]);
  const [filterDepthOptions2, setFilterDepthOptions2] = useState([
    { value: "None", label: "Select Filter Depth" },
  ]);
  const [filterDepthOptions3, setFilterDepthOptions3] = useState([
    { value: "None", label: "Select Filter Depth" },
  ]);

  const previousFilterType1 = useRef(stage1?.filterType);
  const previousFilterType2 = useRef(stage2?.filterType);
  const previousFilterType3 = useRef(stage3?.filterType);

  // Function to set filter depth and then call API
  const setFilterDepthAndFetchOptions = (
    stage,
    setFilterDepthOptions,
    newFilterDepth,
    setter
  ) => {
    // First, set the filter depth to "None"
    setFilterDepth(stage, setter, newFilterDepth);

    // Trigger API call after setting the filter depth
    fetchAndSetFilterDepthOptions(stage.filterType, setFilterDepthOptions);
  };

  useEffect(() => {
    if (chartRef.current) {
      captureGraphAndGeneratePDF(chartRef.current);
    }
  }, [stage1, stage2, stage3]);

  const captureGraphAndGeneratePDF = async (graphElement) => {
    const canvas = await html2canvas(graphElement);
    const imageDataUrl = canvas.toDataURL("image/png");
    dispatch(setGraphUrl(imageDataUrl));
  };

  useEffect(() => {
    if (
      stage1 &&
      stage2 &&
      stage3 &&
      !stage1.minEfficiency &&
      stage1.filterType &&
      stage2.filterType &&
      stage3.name &&
      stage3.life
    ) {
      getPrefilterEff();
    }
  }, [stage1, stage2, stage3]);

  useEffect(() => {
    if (stage3?.name) {
      getPrefilterEff();
    }
  }, [stage3?.name]);

  const getPrefilterEff = () => {
    const payload = {
      site,
      application,
      output_input,
      final_filter: stage3.name,
      final_life: stage3.life,
      stage1_type: stage1.filterType,
      stage2_type: stage2.filterType,
      stage1_size: "None",
      stage2_size: "None",
    };
    getPreFilterMinimumEfficiency(site, application, payload)
      .then((res) => {
        const data = res.data;
        console.log(data, "restfsdfs");
        let minEfficiency, maxEfficiency, rating;
        if (data["Stage1 minial Efficiency"][0] === "TInf") {
          minEfficiency = "T2";
        } else {
          minEfficiency = data["Stage1 minial Efficiency"][0];
        }
        if (data["Stage1 maximal Efficiency"][0] === "TInf") {
          maxEfficiency = "T12";
        } else {
          maxEfficiency = data["Stage1 maximal Efficiency"][0];
        }
        if (stage1.rating <= convertRatingToValue(minEfficiency)) {
          rating = minEfficiency;
        } else {
          rating = stage1.rating;
        }
        const data1 = {
          ...stage1,
          minEfficiency,
          maxEfficiency,
          rating,
        };
        let minEfficiency2, maxEfficiency2, rating2;
        if (data["Stage2 minial Efficiency"][0] === "TInf") {
          minEfficiency2 = "T2";
        } else {
          minEfficiency2 = data["Stage2 minial Efficiency"][0];
        }
        if (data["Stage2 maximal Efficiency"][0] === "TInf") {
          maxEfficiency2 = "T12";
        } else {
          maxEfficiency2 = data["Stage2 maximal Efficiency"][0];
        }
        if (stage1.rating <= convertRatingToValue(minEfficiency2)) {
          rating2 = minEfficiency2;
        } else {
          rating2 = stage2.rating;
        }
        const data2 = {
          ...stage2,
          minEfficiency: minEfficiency2,
          maxEfficiency: maxEfficiency2,
          rating: rating2,
        };
        dispatch(setStage1Filter({ data: data1 }));
        dispatch(setStage2Filter({ data: data2 }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!isSiteSelected) {
      dispatch(goToRoute(routePaths.DASHBOARD));
    }
  }, [isSiteSelected]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  // Function to get filter depth options based on filterType
  const getFilterDepthOptions = (filterType) => {
    if (filterType === "Pulse") {
      return [
        { value: "None", label: "Select Filter Depth" },
        { value: "<=685 mm/27 Inches", label: "<=685 mm/27 Inches" },
        { value: "<=865 mm/34 Inches", label: "<=865 mm/34 Inches" },
        { value: ">865 mm/34 Inches", label: ">865 mm/34 Inches" },
      ];
    } else {
      return [
        { value: "None", label: "Select Filter Depth" },
        { value: "<=100 mm/4 Inches", label: "<=100 mm/4 Inches" },
        { value: "<=300 mm/12 Inches", label: "<=300 mm/12 Inches" },
        { value: "<=440 mm/17 Inches", label: "<=440 mm/17 Inches" },
        { value: "<=600 mm/24 Inches", label: "<=600 mm/24 Inches" },
        { value: ">600 mm/24 Inches", label: ">600 mm/24 Inches" },
      ];
    }
  };

  // useEffect for stage 1
  const fetchAndSetFilterDepthOptions = async (filtertype, setOptions) => {
    try {
      let options;
      // Conditional logic based on passed filtertype
      if (filtertype === "Static" || filtertype === "None") {
        options = getFilterDepthOptions(filtertype);
      } else if (filtertype === "Pulse") {
        options = getFilterDepthOptions(filtertype);
      } else {
        options = []; // Or some default/fallback options
      }

      setOptions(options);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // Call the function with the current filtertype
    if (stage1?.filterType !== previousFilterType1.current) {
      setFilterDepthAndFetchOptions(
        stage1,
        setFilterDepthOptions1,
        "None",
        setStage1Filter
      );
      previousFilterType1.current = stage1.filterType;
    } else {
      const fullOptions1 = getFilterDepthOptions(stage1?.filterType);
      // Ensure the previous selection is added if it's not already in the options
      const selectedOption = stage1?.filterDepth
        ? { value: stage1.filterDepth, label: stage1.filterDepth }
        : { value: "None", label: "Select Filter Depth" };
      // Update options, ensuring the previous selection is in the list
      setFilterDepthOptions1((prevOptions) => {
        const updatedOptions = fullOptions1.some(
          (opt) => opt.value === selectedOption.value
        )
          ? fullOptions1
          : [...fullOptions1, selectedOption];

        return updatedOptions;
      });
    }
  }, [stage1?.filterType]); // dependent on filter type

  // useEffect for stage2
  useEffect(() => {
    // Call the function with the current filtertype
    if (stage2?.filterType !== previousFilterType2.current) {
      setFilterDepthAndFetchOptions(
        stage2,
        setFilterDepthOptions2,
        "None",
        setStage2Filter
      );
      previousFilterType2.current = stage2.filterType;
    } else {
      const fullOptions2 = getFilterDepthOptions(stage2?.filterType);
      // Ensure the previous selection is added if it's not already in the options
      const selectedOption = stage2?.filterDepth
        ? { value: stage2.filterDepth, label: stage2.filterDepth }
        : { value: "None", label: "Select Filter Depth" };
      // Update options, ensuring the previous selection is in the list
      setFilterDepthOptions2((prevOptions) => {
        const updatedOptions = fullOptions2.some(
          (opt) => opt.value === selectedOption.value
        )
          ? fullOptions2
          : [...fullOptions2, selectedOption];

        return updatedOptions;
      });
    }
  }, [stage2?.filterType]); // dependent on filter type

  // useEffect for stage3
  useEffect(() => {
    // Call the function with the current filtertype
    if (stage3?.filterType !== previousFilterType3.current) {
      setFilterDepthAndFetchOptions(
        stage3,
        setFilterDepthOptions3,
        "None",
        setStage3Filter
      );
      previousFilterType3.current = stage3.filterType;
    } else {
      const fullOptions3 = getFilterDepthOptions(stage3?.filterType);
      // Ensure the previous selection is added if it's not already in the options
      const selectedOption = stage3?.filterDepth
        ? { value: stage3.filterDepth, label: stage3.filterDepth }
        : { value: "None", label: "Select Filter Depth" };
      // Update options, ensuring the previous selection is in the list
      setFilterDepthOptions3((prevOptions) => {
        const updatedOptions = fullOptions3.some(
          (opt) => opt.value === selectedOption.value
        )
          ? fullOptions3
          : [...fullOptions3, selectedOption];

        return updatedOptions;
      });
    }
  }, [stage3?.filterType]); // dependent on filter type

  useEffect(() => {
    if (application && output_input && stage3 && stage3.name) {
      getValueRating(application, stage3.name, { output: output_input })
        .then((res) => {
          if (res.data && res.data.length) {
            dispatch(storeValueRating(res.data[0]));
          }
        })
        .catch((err) => {
          dispatch(
            showToasterMessage({
              message: "failed to fetch value rating",
              toasterColor: "primary",
            })
          );
          console.log(err);
        });
    }
  }, [stage3]);
  console.log(theme.breakpoints);
  const mdScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const setFilterDepth = (currentValue, setter, value) => {
    dispatch(setCurrLoading(true));
    const newValue = { ...currentValue };
    console.log(currentValue, "home currentValue");
    newValue.filterDepth = value;
    dispatch(
      setter({
        data: currentValue,
        // callback: (at, value) => {
        //   const payload = getFilterDepthPayloadFromNewVal(at, newValue);
        //   getCustomOptimalAndOptimalWithSize(at, payload, value, newValue);
        // },
        callback: async (at, value) => {
          try {
            const payload = getFilterDepthPayloadFromNewVal(at, newValue);
            await getCustomOptimalAndOptimalWithSize(
              at,
              payload,
              value,
              newValue
            );
          } catch (error) {
            console.error("Error calculating optimal values:", error);
          } finally {
            dispatch(setCurrLoading(false));
          }
        },
      })
    );
  };

  const getCustomOptimalAndOptimalWithSize = (at, payload, value, newValue) => {
    const updatedPayload = {
      ...payload,
      site,
      application,
    };
    getOptimalSizeSolutionData(updatedPayload)
      .then((res) => {
        dispatch(setOptimalSizeSolution(res.data));
        if (
          get(res, "data.filter.0.Stage", "") ===
          "No filters match all the constraints"
        ) {
          dispatch(
            showNotification({
              message:
                "The FAST simulator doesn’t support the requested configuration. Please request a FULL LCC if this is your current site configuration.",
              severity: "primary",
            })
          );
          dispatch(setCurrLoading(false));
          return;
        }
        dispatch(setCurrentSolution(res.data));
        console.log(res.data, "home res.data");
        console.log(updatedPayload, "home updatedPayload");
        console.log(globalOptimalSolution, "home globalOptimalSolution");
        if (
          updatedPayload.stage1_size == "None" &&
          updatedPayload.stage2_size == "None" &&
          updatedPayload.stage3_size == "None" &&
          updatedPayload.stage1_type ===
            globalOptimalSolution.filter[0]?.["Filter Type"] &&
          updatedPayload.stage2_type ===
            globalOptimalSolution.filter[1]?.["Filter Type"] &&
          updatedPayload.stage3_type ===
            globalOptimalSolution.filter[2]?.["Filter Type"]
        ) {
          const initGraph = deserializeGraph(globalOptimalSolution.detail);
          console.log(initGraph, "initGraph data");
          console.log(res.data, "initGraph res.data");
          dispatch(
            setGraphData(manipulateCurrentSolution(initGraph, res.data?.detail))
          );
          dispatch(
            setOptimalSolutionWithSizeLimitation({
              filter: [
                { Stage: 1, "Filter Type": "None" },
                { Stage: 2, "Filter Type": "None" },
                { Stage: 3, "Filter Type": "None" },
              ],
            })
          );
        } else if (
          updatedPayload.stage1_size !== "None" ||
          updatedPayload.stage2_size !== "None" ||
          updatedPayload.stage3_size !== "None" ||
          updatedPayload.stage1_type !==
            globalOptimalSolution.filter[0]?.["Filter Type"] ||
          updatedPayload.stage2_type !==
            globalOptimalSolution.filter[1]?.["Filter Type"] ||
          updatedPayload.stage3_type !==
            globalOptimalSolution.filter[2]?.["Filter Type"]
        ) {
          console.log(graphData, "home graphData");
          dispatch(
            setGraphData(manipulateSizeSolution(graphData, res.data?.detail))
          );
          dispatch(setOptimalSolutionWithSizeLimitation(res.data));
        } else {
          dispatch(
            setGraphData(manipulateCurrentSolution(graphData, res.data?.detail))
          );
          dispatch(setCurrentSolution(res.data));
        }
        const currentData = JSON.parse(
          JSON.stringify({ stage1, stage2, stage3 })
        );
        currentData[at].filterDepth = newValue.filterDepth;
        dispatch(
          setOverallFilters(
            deserializeFilter(res.data.filter, currentData, dispatch)
          )
        );
      })
      .catch((err) => {
        dispatch(
          showToasterMessage({
            message: "failed to fetch optimal function data",
            toasterColor: "primary",
          })
        );
        dispatch(setOverallFilters({ stage1, stage2, stage3 }));
        console.log(err);
        dispatch(setCurrLoading(false));
      });
  };

  const setFilterRating = (currentValue, setter, value, IS_FINAL) => {
    if (filterRatingChangeTimeout) {
      clearTimeout(filterRatingChangeTimeout);
    }
    filterRatingChangeTimeout = setTimeout(() => {
      dispatch(setCurrLoading(true));
      const newValue = { ...currentValue };
      newValue.rating = value;
      dispatch(
        setter({
          data: newValue,
          callback: (at, value) => {
            if (filterRatingTimeout) {
              clearTimeout(filterRatingTimeout);
            }

            filterRatingTimeout = setTimeout(() => {
              const payload = getPayloadFromNewVal(at, value);
              const payload1 = getOptimalTypeSolutionPayload(at, value, true);
              if (!IS_FINAL) {
                // if (true) {
                customOptimalFunc(site, application, payload)
                  .then((res) => {
                    dispatch(setCurrentSolution(res.data));
                    dispatch(
                      setGraphData(
                        manipulateCurrentSolution(graphData, res.data?.detail)
                      )
                    );
                    dispatch(
                      setOverallFilters(
                        deserializeFilter(
                          res.data.filter,
                          { stage1, stage2, stage3 },
                          dispatch
                        )
                      )
                    );
                    dispatch(setCurrLoading(false));
                  })
                  .catch((err) => {
                    dispatch(
                      showToasterMessage({
                        message: "failed to fetch optimal function data",
                        toasterColor: "primary",
                      })
                    );
                    dispatch(setOverallFilters({ stage1, stage2, stage3 }));
                    dispatch(setCurrLoading(false));
                    console.log(err);
                  });
              } else {
                customOptimalFunc(site, application, payload)
                  .then((res) => {
                    dispatch(setCurrentSolution(res.data));
                    dispatch(
                      setGraphData(
                        manipulateCurrentSolution(graphData, res.data?.detail)
                      )
                    );
                    dispatch(
                      setOverallFilters(
                        deserializeFilter(
                          res.data.filter,
                          { stage1, stage2, stage3 },
                          dispatch
                        )
                      )
                    );
                    dispatch(setCurrLoading(false));
                  })
                  .catch((err) => {
                    dispatch(
                      showToasterMessage({
                        message: "failed to fetch optimal function data",
                        toasterColor: "primary",
                      })
                    );
                    dispatch(setOverallFilters({ stage1, stage2, stage3 }));
                    console.log(err);
                    dispatch(setCurrLoading(false));
                  });
              }
            }, 500);
          },
        })
      );
    }, 500);
  };
  const getOptimalTypeSolutionPayload = (at, value, IS_RATING) => {
    const optimalTypeSolutionPayload = {
      output_input,
      site,
      application,
      stage1_size: stage1?.filterDepth || "None",
      stage1_type: stage1?.filterType,
      stage2_size: stage2?.filterDepth || "None",
      stage2_type: stage2?.filterType,
      stage3_size: stage3?.filterDepth || "None",
      stage3_type: stage3?.filterType,
      final_life: value?.life,
    };
    if (at === "stage1") {
      optimalTypeSolutionPayload.stage1_size = value.filterDepth;
    }
    if (at === "stage2") {
      optimalTypeSolutionPayload.stage2_size = value.filterDepth;
    }
    if (at === "stage3") {
      optimalTypeSolutionPayload.stage3_size = value.filterDepth;
    }
    if (IS_RATING) {
      optimalTypeSolutionPayload["stage3_eff"] = `T${value?.rating}`;
    }
    return optimalTypeSolutionPayload;
  };

  const getFilterDepthPayloadFromNewVal = (at, value) => {
    const payload = {
      output_input,
      stage1_size: stage1.filterDepth || "None",
      // stage1_eff: convertValueToRating(stage1.rating),
      stage1_type: stage1.filterType,
      stage2_size: stage2.filterDepth || "None",
      // stage2_eff: convertValueToRating(stage2.rating),
      stage2_type: stage2.filterType,
      stage3_size: stage3.filterDepth || "None",
      // stage3_eff: convertValueToRating(stage3.rating),
      stage3_type: stage3.filterType,
      // final_life: stage3.life,
    };
    if (at === "stage1") {
      payload.stage1_size = value.filterDepth;
    }
    if (at === "stage2") {
      payload.stage2_size = value.filterDepth;
    }
    if (at === "stage3") {
      payload.stage3_size = value.filterDepth;
    }
    return payload;
  };
  console.log(stage1, "fsfsf");
  const getPayloadFromNewVal = (at, value) => {
    const payload = {
      output_input,
      stage1_size: stage1.filterDepth || "None",
      stage1_eff: String(stage1.rating)?.includes("T")
        ? stage1.rating
        : convertValueToRating(stage1.rating),
      stage1_type: stage1.filterType,
      stage2_size: stage2.filterDepth || "None",
      stage2_eff: String(stage2.rating)?.includes("T")
        ? stage2.rating
        : convertValueToRating(stage2.rating),
      stage2_type: stage2.filterType,
      stage3_size: stage3.filterDepth || "None",
      stage3_eff: String(stage3.rating)?.includes("T")
        ? stage3.rating
        : convertValueToRating(stage3.rating),
      stage3_type: stage3.filterType,
      final_life: stage3.life,
    };
    if (at === "stage1") {
      payload.stage1_eff = convertValueToRating(value.rating);
      payload.stage1_type = value.filterType;
    }
    if (at === "stage2") {
      payload.stage2_eff = convertValueToRating(value.rating);
      payload.stage2_type = value.filterType;
    }
    if (at === "stage3") {
      payload.stage3_eff = convertValueToRating(value.rating);
      payload.stage3_type = value.filterType;
      payload.final_life = value.life;
    }
    return payload;
  };

  const setFilterLife = (currentValue, setter, value, IS_FINAL) => {
    const newValue = { ...currentValue };
    newValue.life = value;
    dispatch(setCurrLoading(true));
    dispatch(
      setter({
        data: newValue,
        callback: (at, value) => {
          if (filterLifeTimeout) {
            clearTimeout(filterLifeTimeout);
          }

          filterLifeTimeout = setTimeout(() => {
            const payload = getPayloadFromNewVal(at, value);
            const payload1 = getOptimalTypeSolutionPayload(at, value);
            if (!IS_FINAL) {
              customOptimalFunc(site, application, payload)
                .then((res) => {
                  dispatch(setCurrentSolution(res.data));
                  dispatch(
                    setGraphData(
                      manipulateCurrentSolution(graphData, res.data?.detail)
                    )
                  );
                  const FilterSizeDataNonFinalFilter = {
                    stage1: { ...stage1, filterDepth: payload.stage1_size },
                    stage2: { ...stage2, filterDepth: payload.stage2_size },
                    stage3: { ...stage3, filterDepth: payload.stage3_size },
                  };
                  dispatch(
                    setOverallFilters(
                      deserializeFilter(
                        res.data.filter,
                        FilterSizeDataNonFinalFilter,
                        dispatch
                      )
                    )
                  );
                  dispatch(setCurrLoading(false));
                })
                .catch((err) => {
                  dispatch(
                    showToasterMessage({
                      message: "failed to fetch optimal function data",
                      toasterColor: "primary",
                    })
                  );
                  dispatch(setOverallFilters({ stage1, stage2, stage3 }));
                  console.log(err);
                  dispatch(setCurrLoading(false));
                });
            } else {
              optimalFinalLifeSolution(site, application, payload1)
                .then((res) => {
                  dispatch(setCurrentSolution(res.data));
                  dispatch(
                    setGraphData(
                      manipulateCurrentSolution(graphData, res.data?.detail)
                    )
                  );
                  const FilterSizeDataFinalFilter = {
                    stage1: { ...stage1, filterDepth: payload.stage1_size },
                    stage2: { ...stage2, filterDepth: payload.stage2_size },
                    stage3: { ...stage3, filterDepth: payload.stage3_size },
                  };
                  dispatch(
                    setOverallFilters(
                      deserializeFilter(
                        res.data.filter,
                        FilterSizeDataFinalFilter,
                        dispatch
                      )
                    )
                  );
                  dispatch(setCurrLoading(false));
                })
                .catch((err) => {
                  dispatch(
                    showToasterMessage({
                      message: "failed to fetch optimal function data",
                      toasterColor: "primary",
                    })
                  );
                  dispatch(setOverallFilters({ stage1, stage2, stage3 }));
                  console.log(err);
                  dispatch(setCurrLoading(false));
                });
            }
          }, 500);
        },
      })
    );
  };

  const isNoneSelected = (filterData) => {
    return filterData?.filterType === "None";
  };

  const handleValueRatingModal = () => {
    setValueRatingOpen(true);
  };

  const handelCloseValueRating = () => {
    setValueRatingOpen(false);
  };
  const modalData = [
    {
      id: "t1",
      title: "Efficiency",
      description: "Selected efficiency of pre-filter, per ISO 29461-1:2021",
    },
    {
      id: "finalt1",
      title: "Efficiency",
      description:
        "Select desired efficiency of final filter, per ISO 29461-1:2021",
    },
    {
      id: "years01",
      title: "Lifetime Duration",
      description:
        "Calculated based on ambient conditions and any pre-filtration applied",
    },
    {
      id: "final01",
      title: "Lifetime Duration",
      description: "Select desired life of final filter",
    },
    {
      id: "LCC Comparison",
      title: "LCC Comparison",
      description:
        "A 5 years comparison of Life Cycle Cost (LCC) is shown here for up to three solutions: the overall optimal filters at this location / environment, the optimal filters taking into account any size constraints of what filters can be installed, and the currently selected filter combination.",
    },
    {
      id: "CO2 Savings",
      title: "CO2 Savings",
      description:
        "When you use better filters, you can minimize performance degradation and reduce emissions. The CO2 SAVINGS indice compares each filter to a T5 / ISO ePM10 60% filter – a basic, industry-standard entry-level final filter. Based on the OUTPUT RATING and FUEL USAGE for the filter, the CO2 SAVINGS value estimates how many tonnes of CO2 you could save per year for a 125MW engine.",
    },
    {
      id: "Filter Selection",
      title: "Filter Selection",
      description:
        "Based on the selected site environmental conditions and application choice, FAST proposes the filtration solution with the lowest life cycle cost (LCC) initially.  It is possible to add a size limitation or type of filter to represent an existing filter house configuration. If a size limitation or a filter type is selected by the user, it will be shown as the Optimal solution with size limitation. For any further modification of the filtration efficiency or life expectation, a new life cycle cost estimation will be shown under the Current solution. The tool might also re-adjust the pre-filter selection to meet required final filter efficiency and/or life time.",
    },
    {
      id: "Fuel Penalty",
      title: "Fuel Penalty",
      description:
        "The Fuel Penalty indicates how much more fuel you must use to compensate for degradation due to fouling and pressure drop. A perfect filter with 0 Pa pressure drop and 100% efficiency would get a +0% rating. A final filter with a +3% rating indicates the turbine will use 3% more fuel than if it was perfectly clean. If you’re running at part load, you won’t see a direct impact of degradation on engine output, but you will see an impact on your fuel budget.",
    },
  ];
  console.log(stage2, "home stage2");
  const numberFormatter = (num) => {
    if (num) {
      let formattedNumberWithCommas = num?.toLocaleString();
      return formattedNumberWithCommas.replace(/,/g, " ");
    }
  };

  console.log(stage3, "home stage3");

  // adjust slider animation
  // let slider movement be seperated from API call
  const [sliderValue1, setSliderValue1] = useState(undefined);
  const [sliderValue2, setSliderValue2] = useState(undefined);
  const [sliderValue3, setSliderValue3] = useState(undefined);
  const [sliderValueLife, setSliderValueLife] = useState(undefined);

  // Update sliderValue when stage3.rating becomes available or changes
  useEffect(() => {
    if (stage1?.rating !== undefined) {
      setSliderValue1(stage1?.rating); // Set sliderValue when stage1.rating is available
    }
  }, [stage1?.rating]); // This effect runs whenever stage1?.rating changes

  // Update sliderValue when stage1.rating becomes available or changes
  useEffect(() => {
    if (stage2?.rating !== undefined) {
      setSliderValue2(stage2?.rating); // Set sliderValue when stage2.rating is available
    }
  }, [stage2?.rating]); // This effect runs whenever stage2?.rating changes

  // Update sliderValue when stage3.rating becomes available or changes
  useEffect(() => {
    if (stage3?.rating !== undefined) {
      setSliderValue3(stage3?.rating); // Set sliderValue when stage3.rating is available
    }
  }, [stage3?.rating]); // This effect runs whenever stage3?.rating changes

  useEffect(() => {
    if (stage3?.life !== undefined) {
      setSliderValueLife(stage3?.life); // Set sliderValue when stage3.rating is available
    }
  }, [stage3?.life]);


  if (filterData.isLoading) {
    return (
      <Box sx={styles.loaderContainer}>
        <CircularProgress
          size={80}
          style={{
            color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
          }}
        />

        <Typography
          sx={{
            color: "white",
            fontSize: "24px",
            fontWeight: 600,
            marginTop: "16px",
          }}
        >
          Calculating the optimal solution based on your inputs ...
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Box sx={styles.mainContainer}>
        {/* <img src={lccgraphbg} alt="Lccgraphbg" style={styles.bg} /> */}
        <Grid container columnGap={"0"}>
          <Grid item lg={7} md={12} xs={12} sx={styles.cardsContainer}>
            <Grid
              container
              sx={{
                ...styles.mainGrid,
                ...(isNoneSelected(stage1) && { position: "relative" }),
              }}
              // className={isNoneSelected(stage1) ? "none-select" : ""}
            >
              <Grid item md={12} lg={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    bottom: "32px",
                    opacity: 1,
                    zIndex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      ...styles.prefilterText,
                      margin: "0 5px 0 10px",
                      color:
                        theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
                      fontSize: "20px",
                    }}
                  >
                    Filter Selection
                  </Typography>
                  <Info
                    onClick={() => setCommonModalOpen("Filter Selection")}
                  />
                  <EfficiencyModal
                    isOpen={commonModalOpen}
                    handleClosePopup={() => setCommonModalOpen("")}
                    title={
                      modalData.find((item) => item.id === commonModalOpen)
                        ?.title
                    }
                    description={
                      modalData.find((item) => item.id === commonModalOpen)
                        ?.description
                    }
                    buttonLabel="Okay"
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={3.5}
                lg={4}
                sm={3}
                xs={12}
                sx={{
                  ...styles.imageCards1,
                  ...(isNoneSelected(stage1) && { opacity: "0.7" }),
                }}
              >
                {!isNoneSelected(stage1) && (
                  <>
                    <img
                      width="100%"
                      height="100%"
                      src={getFilterImage(stage1?.filterId)}
                      alt="disc"
                      style={{ objectFit: "contain" }}
                    />
                    <Typography sx={styles.filterName}>
                      {stage1?.name}
                    </Typography>
                  </>
                )}
              </Grid>

              <Grid
                item
                md={8.5}
                lg={8}
                sm={9}
                xs={12}
                sx={styles.imageCards}
                style={
                  isNoneSelected(stage1)
                    ? { opacity: 0.7, zIndex: 1 }
                    : { opacity: 1, zIndex: 1 }
                }
              >
                <Grid>
                  <Box sx={styles.prefiltertextContainer}>
                    <Typography sx={styles.prefilterText}>
                      Pre-Filter
                    </Typography>
                    <Radio
                      setSelection={setStage1Filter}
                      selection={stage1}
                      section="stage1"
                    />
                  </Box>
                </Grid>
                {!filterData.currLoading ? (
                  <StandardDropdown
                    onChange={(e, value) =>
                      setFilterDepth(stage1, setStage1Filter, e.target.value)
                    }
                    value={stage1?.filterDepth || "None"}
                    option={filterDepthOptions1}
                    disabled={isNoneSelected(stage1)}
                    fullWidth
                    sx={{ my: "18px" }}
                  />
                ) : (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height="40px"
                    sx={{ color: "#fdfdfd", my: "18px" }}
                    animation="wave"
                  />
                )}

                {!filterData.currLoading ? (
                  sliderValue1 ? (
                    <Slider
                      label={"test slider"}
                      sliderStartname={stage1?.minEfficiency || "T2"}
                      sliderEndname={stage1?.maxEfficiency || "T12"}
                      min={convertRatingToValue(stage1?.minEfficiency || "T2")}
                      max={convertRatingToValue(stage1?.maxEfficiency || "T12")}
                      popupLabel={"T"}
                      isLabelBefore={true}
                      value={sliderValue1}
                      onChange={(_, value) => setSliderValue1(value)}
                      onChangeCommitted={(_, value) => setFilterRating(stage1, setStage1Filter, value, true)}
                      disabled={isNoneSelected(stage1)}
                      onInfoClick={() => setCommonModalOpen("t1")}
                      loading={filterData.currLoading}
                    />
                  ) : (
                    <p
                      style={{
                        ...styles.sliderLabel,
                        color: "white",
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      Toggle filter type to enable slider
                    </p>
                  )
                ) : (
                  <Box
                    sx={{
                      position: "fixed", // Ensure the spinner is fixed in the viewport
                      top: 0,
                      left: 0,
                      width: "100vw",
                      height: "100vh",
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                      justifyContent: "center", // Horizontally center the spinner
                      alignItems: "center", // Vertically center the spinner
                      backgroundColor: "rgba(0, 0, 0, 0.7)", // Optional: Add a translucent background
                      zIndex: 9999, // Ensure it's on top of other elements
                    }}
                  >
                    {/* <CircularProgress
                      size={80} // Large size for the spinner
                      style={{
                        color:
                          theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
                      }}
                    /> */}
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "24px",
                        fontWeight: 600,
                        marginTop: "16px",
                      }}
                    >
                      Recalculating your solution ...
                    </Typography>
                  </Box>
                )}

                <Slider
                  label={"test slider"}
                  sliderStartname={"1 Year"}
                  sliderEndname={"5 Years"}
                  min={1}
                  max={5}
                  popupLabel={"Years"}
                  value={stage1?.life}
                  onChange={(_e, value) =>
                    setFilterLife(stage1, setStage1Filter, value)
                  }
                  noSlot={true}
                  isLabelBefore={false}
                  disabled={true}
                  onInfoClick={() => setCommonModalOpen("years01")}
                  loading={filterData.currLoading}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                ...styles.subGrid,
                opacity: isNoneSelected(stage2) ? 0.7 : 1,
              }}
            >
              <Grid item md={3.5} lg={4} sm={3} xs={12} sx={styles.imageCards1}>
                {!isNoneSelected(stage2) && (
                  <>
                    <img
                      width="100%"
                      height="232px"
                      src={getFilterImage(stage2?.filterId)}
                      alt="disc"
                      style={{ objectFit: "contain" }}
                    />
                    <Typography sx={styles.filterName}>
                      {stage2?.name}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid
                item
                md={8.5}
                lg={8}
                sm={9}
                xs={12}
                sx={styles.imageCards}
                order={{ md: 2, lg: 2 }}
              >
                <Box sx={styles.prefiltertextContainer}>
                  <Typography sx={styles.prefilterText}>Pre-Filter</Typography>
                  <Radio
                    setSelection={setStage2Filter}
                    selection={stage2}
                    section={"stage2"}
                  />
                </Box>
                {!filterData.currLoading ? (
                  <StandardDropdown
                    fullWidth
                    sx={{ my: "18px" }}
                    onChange={(e, value) =>
                      setFilterDepth(stage2, setStage2Filter, e.target.value)
                    }
                    value={stage2?.filterDepth}
                    option={filterDepthOptions2}
                    disabled={isNoneSelected(stage2)}
                  />
                ) : (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height="40px"
                    sx={{ color: "#fdfdfd", my: "18px" }}
                    animation="wave"
                  />
                )}

                {sliderValue2 ? (
                  <Slider
                    label={"test slider"}
                    sliderStartname={stage2?.minEfficiency || "T2"}
                    sliderEndname={stage2?.maxEfficiency || "T12"}
                    min={convertRatingToValue(stage2?.minEfficiency || "T2")}
                    max={convertRatingToValue(stage2?.maxEfficiency || "T12")}
                    value={sliderValue2}
                    onChange={(_, value) => setSliderValue2(value)}
                    onChangeCommitted={(_, value) => setFilterRating(stage2, setStage2Filter, value, true)}
                    popupLabel={"T"}
                    isLabelBefore={true}
                    disabled={isNoneSelected(stage2)}
                    onInfoClick={() => setCommonModalOpen("t1")}
                    loading={filterData.currLoading}
                  />
                ) : (
                  <p
                    style={{
                      ...styles.sliderLabel,
                      color: "white",
                      fontSize: "13px",
                      fontWeight: "600",
                    }}
                  >
                    Toggle filter type to enable slider
                  </p>
                )}

                <Slider
                  label={"test slider"}
                  sliderStartname={"1 Year"}
                  sliderEndname={"5 Years"}
                  min={1}
                  max={5}
                  value={stage2?.life}
                  onChange={(_e, value) =>
                    setFilterLife(stage2, setStage2Filter, value)
                  }
                  popupLabel={"Years"}
                  isLabelBefore={false}
                  disabled={true}
                  onInfoClick={() => setCommonModalOpen("years01")}
                  focusVisible={false}
                  noSlot={true}
                  loading={filterData.currLoading}
                />
              </Grid>
            </Grid>
            {mdScreen && (
              <Grid
                container
                sx={{
                  ...styles.subGrid,
                  opacity: isNoneSelected(stage2) ? 0.7 : 1,
                }}
                style={{ marginTop: "20px" }}
              >
                <Grid container style={{ marginTop: "20px" }}>
                  <Grid
                    item
                    md={3.5}
                    lg={4}
                    sm={3}
                    xs={12}
                    sx={styles.imageCards1}
                  >
                    <img
                      width="100%"
                      height="75%"
                      src={getFilterImage(stage3?.filterId)}
                      alt="Rectangle"
                      style={{ objectFit: "contain" }}
                    />
                    <Typography sx={styles.filterName}>
                      {stage3?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={8.5}
                    lg={8}
                    sm={9}
                    xs={12}
                    sx={styles.imageCards}
                  >
                    <Box sx={styles.prefiltertextContainer}>
                      <Typography sx={styles.prefilterText}>
                        Final Filter
                      </Typography>
                      <Radio
                        setSelection={setStage3Filter}
                        selection={stage3}
                        section={"stage3"}
                      />
                    </Box>
                    {!filterData.currLoading ? (
                      <StandardDropdown
                        fullWidth
                        sx={{ my: "18px" }}
                        onChange={(e, value) =>
                          setFilterDepth(
                            stage3,
                            setStage3Filter,
                            e.target.value
                          )
                        }
                        value={stage3?.filterDepth}
                        option={filterDepthOptions3}
                        disabled={isNoneSelected(stage3)}
                      />
                    ) : (
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height="40px"
                        sx={{ color: "#fdfdfd", my: "18px" }}
                        animation="wave"
                      />
                    )}

                    {/* {sliderValue3 ? (
                      <Slider
                        sliderStartname={"T8"}
                        sliderEndname={"T12"}
                        min={8}
                        max={12}
                        value={sliderValue3}
                        onChange={handleSliderChange3} // Handle smooth real-time updates
                        popupLabel={"T"}
                        isLabelBefore={true}
                        onInfoClick={() => setCommonModalOpen("finalt1")}
                        loading={filterData.currLoading}
                      />
                    ) : (
                      <p>Loading</p>
                    )}

                    {sliderValueLife ? (
                      <Slider
                        sliderStartname={"1 Year"}
                        sliderEndname={"5 Years"}
                        min={1}
                        max={5}
                        value={sliderValueLife}
                        onChange={handleSliderChangeLife}
                        popupLabel={"Years"}
                        isLabelBefore={false}
                        onInfoClick={() => setCommonModalOpen("final01")}
                        loading={filterData.currLoading}
                      />
                    ) : (
                      <p>Loading</p>
                    )} */}

                    <Slider
                      sliderStartname={"T8"}
                      sliderEndname={"T12"}
                      min={8}
                      max={12}
                      value={stage3?.rating}
                      onChange={(_e, value) =>
                        setFilterRating(stage3, setStage3Filter, value, true)
                      }
                      popupLabel={"T"}
                      isLabelBefore={true}
                      onInfoClick={() => setCommonModalOpen("finalt1")}
                      loading={filterData.currLoading}
                    />

                    <Slider
                      sliderStartname={"1 Year"}
                      sliderEndname={"5 Years"}
                      min={1}
                      max={5}
                      value={stage3?.life}
                      onChange={(_e, value) =>
                        setFilterLife(stage3, setStage3Filter, value, true)
                      }
                      popupLabel={"Years"}
                      isLabelBefore={false}
                      onInfoClick={() => setCommonModalOpen("final01")}
                      loading={filterData.currLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            ref={chartRef}
            item
            md={12}
            lg={5}
            sm={12}
            xs={12}
            sx={styles.chartGridTwo}
          >
            <Box sx={styles.chartTitlePosition}>
              <Box sx={styles.chartTitleContainer}>
                <Typography sx={styles.chartTitle}>LCC Comparison </Typography>{" "}
                <Info onClick={() => setCommonModalOpen("LCC Comparison")} />
              </Box>
            </Box>
            <LccGraph order={{ md: 3, lg: 3 }} />
            <Box sx={styles.optimalSolution}>
              <Box display={"flex"} my={1}>
                <Typography
                  sx={{ ...styles.optimalSolutionTag, width: "200px" }}
                >
                  Optimal solution:
                </Typography>
                <Typography sx={styles.optimalSolutionTag}>
                  {globalOptimalSolution?.filter?.map((item, index) => {
                    if (item.Filter) {
                      return (
                        <Typography
                          sx={{
                            ...styles.optimalSolutionTag,
                            marginRight: "5px",
                          }}
                          key={item.Filter}
                        >
                          {`${item.Filter} ${
                            index + 1 != globalOptimalSolution?.filter?.length
                              ? "& "
                              : ""
                          }`}
                        </Typography>
                      );
                    }
                  })}
                </Typography>
              </Box>
              <Box display={"flex"} my={1}>
                <Typography
                  sx={{ ...styles.optimalSolutionTag, width: "200px" }}
                >
                  Optimal solution for <br />
                  existing configuration:
                </Typography>
                <Typography sx={styles.optimalSolutionTag}>
                  {optimalSolutionWithSizeLimitation?.filter?.map(
                    (item, index) => {
                      if (item.Filter) {
                        return (
                          <Typography
                            sx={{
                              ...styles.optimalSolutionTag,
                              marginRight: "5px",
                            }}
                            key={item.Filter}
                          >
                            {`${item.Filter} ${
                              index + 1 !=
                              optimalSolutionWithSizeLimitation?.filter?.length
                                ? "& "
                                : ""
                            }`}
                          </Typography>
                        );
                      }
                    }
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={styles.subGridGraps}>
          <Grid item md={12} lg={6.95} sm={12} xs={12}>
            {!mdScreen && (
              <Grid
                container
                style={{ marginTop: "20px", paddingRight: "22px" }}
              >
                <Grid
                  item
                  md={3.5}
                  lg={4}
                  sm={3}
                  xs={12}
                  sx={styles.imageCards2}
                >
                  <img
                    width="100%"
                    height="232px"
                    src={getFilterImage(stage3?.filterId)}
                    alt="Rectangle"
                    style={{ objectFit: "contain" }}
                  />
                  <Typography sx={styles.filterName}>{stage3?.name}</Typography>
                </Grid>
                <Grid
                  item
                  md={8.5}
                  lg={8}
                  sm={9}
                  xs={12}
                  sx={styles.imageCards}
                >
                  <Box sx={styles.prefiltertextContainer}>
                    <Typography sx={styles.prefilterText}>
                      Final Filter
                    </Typography>
                    <Radio
                      setSelection={setStage3Filter}
                      selection={stage3}
                      section={"stage3"}
                    />
                  </Box>
                  {!filterData.currLoading ? (
                    <StandardDropdown
                      fullWidth
                      sx={{ my: "18px" }}
                      onChange={(e) =>
                        setFilterDepth(stage3, setStage3Filter, e.target.value)
                      }
                      value={stage3?.filterDepth}
                      option={filterDepthOptions3}
                      disabled={isNoneSelected(stage3)}
                    />
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="40px"
                      sx={{ color: "#fdfdfd", my: "18px" }}
                      animation="wave"
                    />
                  )}
                  <Box mt={2.5}>
                    {sliderValue3 ? (
                      <Slider
                        sliderStartname={"T8"}
                        sliderEndname={"T12"}
                        min={8}
                        max={12}
                        value={sliderValue3}
                        onChange={(_, value) => setSliderValue3(value)}
                        onChangeCommitted={(_, value) => setFilterRating(stage3, setStage3Filter, value, true)}
                        popupLabel={"T"}
                        isLabelBefore={true}
                        onInfoClick={() => setCommonModalOpen("finalt1")}
                        loading={filterData.currLoading}
                      />
                    ) : (
                      <p>Loading</p>
                    )}
                  </Box>
                  <Box mt={2.5}>
                    {sliderValueLife ? (
                      <Slider
                        sliderStartname={"1 Year"}
                        sliderEndname={"5 Years"}
                        min={1}
                        max={5}
                        value={sliderValueLife}
                        onChange={(_, value) => setSliderValueLife(value)}
                        onChangeCommitted={(_, value) => setFilterLife(stage3, setStage3Filter, value, true)}
                        popupLabel={"Years"}
                        isLabelBefore={false}
                        onInfoClick={() => setCommonModalOpen("final01")}
                        loading={filterData.currLoading}
                      />
                    ) : (
                      <p>Loading</p>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            md={12}
            lg={5.05}
            sm={12}
            xs={12}
            sx={styles.valueRatingGraphs}
          >
            <Typography sx={styles.ratingLabel}>
              Value Rating
              <span
                style={{
                  fontSize: "20px",
                  paddingLeft: "12px",
                  color: theme.palette.mode == "dark" ? "white" : "black",
                }}
              >
                {stage3?.name}
              </span>
            </Typography>
            <Grid container>
              <Grid position={"relative"} pt={2} item xs={6}>
                <Typography sx={styles.TypographyContainer}>
                  Output & Fuel Rating
                </Typography>

                <FuelRatingPage
                  outputRating={valueRating["Output Rating"]}
                  grade={valueRating["Grade"]}
                />
                <Box sx={styles.InfoContainer}>
                  <Typography
                    sx={{
                      ...styles.TonnesStyle,
                      color:
                        theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    {isONG ? (
                      <span>{valueRating["Fuel Penalty"]}% MJ/MWh</span>
                    ) : (
                      <span>{valueRating["Output"]} MW</span>
                    )}
                  </Typography>
                  <Typography sx={styles.PowerOutputStyle}>
                    {isONG ? "Fuel Penalty" : "Power Output"}
                    <span
                      style={{
                        position: "relative",
                        left: "5px",
                        top: "2.5px",
                      }}
                    >
                      <Info
                        onClick={() => {
                          if (isONG) {
                            setCommonModalOpen("Fuel Penalty");
                          } else {
                            handleValueRatingModal();
                          }
                        }}
                      />
                    </span>
                    <ValueRatingPopup
                      isOpen={valueRatingOpen}
                      handleClosePopup={handelCloseValueRating}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                pt={2}
                item
                xs={1}
              >
                <Box
                  sx={{
                    height: "100%",
                    borderRight: "1px solid rgb(92 87 87 / 50%)",
                  }}
                ></Box>
              </Grid>

              <Grid position={"relative"} pt={3} item xs={5}>
                <Typography sx={styles.CO2Container}>
                  CO<sub>2</sub> Savings
                </Typography>
                <Box sx={styles.savingsImageContainer}>
                  <img
                    src={getNumberOfClouds(valueRating["CO2 Saving"])}
                    alt="co2 savings"
                  />
                </Box>
                <Box sx={styles.TonnesContainer}>
                  <Typography sx={styles.InfoTextStyle}>
                    {numberFormatter(valueRating["CO2 Saving"])}{" "}
                  </Typography>
                  <Typography sx={styles.TonnesStyle}>
                    Tonnes/TWh
                    <span
                      style={{
                        position: "relative",
                        left: "5px",
                        top: "2.5px",
                      }}
                    >
                      <Info onClick={() => setCommonModalOpen("CO2 Savings")} />
                    </span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

Home.propTypes = {};

export default Home;
