import React from "react";

//Material
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Slide } from "@mui/material";

//Style
import styles, { StyledModal } from "./style";

function ValueRatingPopup({ isOpen, handleClosePopup, ...modalProps }) {

  return (
    <Box>
      <StyledModal
        TransitionComponent={Slide}
        open={isOpen}
        onClose={handleClosePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...modalProps}
      >
        <Box sx={styles.modalContainer}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Value Rating
          </Typography>
          <Typography id="modal-modal-description" sx={styles.textContainer}>
           <span style={styles.EpowerStyle}> 90.00% </span>  =  E Power Output  <br/>
           <span style={styles.DpowerStyle}> 92.00% </span> =  D Power Output  <br/>
           <span style={styles.CpowerStyle}> 94.00% </span>=  C Power Output  <br/>
           <span style={styles.BpowerStyle}> 96.50%  </span> =  B Power Output  <br/>
           <span style={styles.ApowerStyle}> 98.50% </span>  =  A Power Output  <br/>
           <span style={styles.APowerStyle}> 99.00% </span>  =  A++ Power Output <br/>
          </Typography>
        </Box>
      </StyledModal>
    </Box>

  );
}

export default ValueRatingPopup;
