import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const BootstrapButton = styled(Button)(
	({ customBackgroundColor, customBorderColor, customcolor, width }) => ({
		color: customcolor || "#FFFFFF",
		fontSize: "20px",
		borderRadius: "12px",
		padding: "8px 16px",
		border: "1px solid",
		lineHeight: "24px",
		fontWeight: 600,
		height:"57px",
		textTransform: "none",
		backgroundColor: customBackgroundColor || "#31FFCD",
		width: width || "639px",
		borderColor: customBorderColor || "#31FFCD",
    fontFamily:"Inter",
		"&:hover": {
			backgroundColor: customBackgroundColor || "#31FFCD",
			borderColor: customBorderColor || "#31FFCD",
			boxShadow: "none",
		},
		"&.Mui-disabled": {
			background: "#1C5E4E",
			color: "#0D0D0D",
		},
	})
);
