export const options = [
  {
    value: "Select the subject of your request",
    label: "Select the subject of your request",
  },
  { value: "Technical Issue", label: "Technical Issue" },
  {
    value: "Product Information Request",
    label: "Product Information Request",
  },
  { value: "Life Cycle Cost", label: "Life Cycle Cost" },
  {
    value: "Site Environmental Report",
    label: "Site Environmental Report",
  },
  { value: "General Contact", label: "General Contact" },
  { value: "Other", label: "Other" },
];
