import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

export const StyledSelect = styled(Select)(({ width, theme }) => ({
  width,
  color: theme.palette.mode !== "dark" ? "#FFFFFF" : "#000000",
  borderRadius: "8px",
  height: "50px",
  fontSize: "15px",
  fontFamily: "Inter",
  lineHeight: "24px",
  justifyContent: "space-between",
  fontWeight: 600,
  marginTop: "5px",
  "& .MuiSelect-icon": {
    right: 12,
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "50%",
    transform: "translateY(-50%)",
    color: theme.palette.mode !== "dark" ? "#FFFFFF" : "#000000",
  },
  "& fieldset": {
    border: "none",
  },
  "& div": {
    minHeight: "fit-content !important",
    fontSize: "15px !important",
  },
  "&.MuiSelect-root": {
    fontSize: "15px !important",
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ customColor }) => ({
  color: customColor || "#FFFFFF",
  width: "173px",
  height: "23px",

  fontSize: "19px",
  lineHeight: "22.8px",
  fontWeight: "500px",
  left: "758px",
  marginRight: "30px",
}));
