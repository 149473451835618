import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const BootstrapButton = styled(Button)(
  ({ customBackgroundColor, customBorderColor, customFontSize, width }) => ({
    color: "#0D0D0D",
    fontSize: customFontSize || "17px",
    borderRadius: "47px",
    padding: "8px 14px",
    border: "1px solid",
    lineHeight: "24px",
    fontWeight: 600,
    textTransform: "none",
    backgroundColor: customBackgroundColor || "#31FFCD",
    width: width || "315",
    height: "56px",
    gap: "12px",
    borderColor: customBorderColor || "#31FFCD",
    fontFamily: "Inter",
    "&:hover": {
      backgroundColor: customBackgroundColor || "#31FFCD",
      borderColor: customBorderColor || "#31FFCD",
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      background: "#1C5E4E",
      color: "#080809",
    },
  })
);
