import React from "react";

//Material
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Slide, useTheme } from "@mui/material";

//Style
import styles, { StyledModal } from "./style";

const EfficiencyModal = ({
  isOpen,
  handleClosePopup,
  title,
  description,
  buttonLabel,
  ...modalProps
}) => {
  const theme = useTheme();
  return (
    <Box>
      <StyledModal
        open={isOpen}
        onClose={handleClosePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        TransitionComponent={Slide}
        {...modalProps}
      >
        <Box sx={styles.modalContainer}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              color: theme.palette.mode !== "dark" ? "#333f48" : "white",
            }}
          >
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 3 }}>
            {description}
          </Typography>
          <Button
            onClick={handleClosePopup}
            sx={{
              ...styles.buttonStyle,
              color:
                theme.palette.mode !== "dark" ? "white !important" : "black",
            }}
          >
            {buttonLabel}
          </Button>
        </Box>
      </StyledModal>
    </Box>
  );
};

export default EfficiencyModal;
