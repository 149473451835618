import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  optimalSolution: {},
  currentSolution: {},
  optimalSolutionWithSizeLimitation: {},
  lccManufacturerOptions: [],
  turbineOptions: [],
};

const lccSlice = createSlice({
  name: "lccData",
  initialState,
  reducers: {
    setLccData(state, action) {
      state.optimalSolution = action.payload.optimalSolution;
      state.currentSolution = action.payload.currentSolution;
      state.optimalSolutionWithSizeLimitation =
        action.payload.optimalSolutionWithSizeLimitation;
    },
    setOptimalSolutionLccData(state, action) {
      state.optimalSolution = action.payload;
    },
    setCurrentSolutionLccData(state, action) {
      state.currentSolution = action.payload;
    },
    setOptimalSolutionWithSizeLccData(state, action) {
      state.optimalSolutionWithSizeLimitation = action.payload;
    },
    setLccManufacturerOptions(state, action) {
      state.lccManufacturerOptions = action.payload;
    },
    setLccTurbineOptions(state, action) {
      state.turbineOptions = action.payload;
    },
  },
});

export const {
  setLccData,
  setOptimalSolutionLccData,
  setCurrentSolutionLccData,
  setOptimalSolutionWithSizeLccData,
  setLccManufacturerOptions,
  setLccTurbineOptions,
} = lccSlice.actions;
export default lccSlice.reducer;
