export const getStoredXml = (payload) => {
  const {
    unitID,
    currency,
    currencyID,
    reportDate,
    contactEmail,
    salesPersonEmail,
    site,
    location,
    manufacturer,
    manufacturerID,
    turbineTypeID,
    turbineType,
    isoBaseRating,
    heatRate,
    heatRateUnit,
    massFlow,
    operationMode,
    downtimeReplacement,
    gasTurbineOutput,
    fuelCost,
    fuelCostUnit,
    compressorWashingDowntime,
    compressorWashCost,
    totalAirflow,
    lccPeriod,
    co2TaxRate,
    filterStageNumber1,
    filterStageNumber2,
    filterStageNumber3,
    offlineWaterWashInterval,
    mWhSoldPrice,
    turbineOperating,
    loadType,
    maintenanceIntervalMonths,
    timeAtFullLoad,
    powerOutput,
    filterID11,
    noOfFilter11,
    filterID12,
    noOfFilter12,
    filterID13,
    noOfFilter13,
    filterID21,
    noOfFilter21,
    filterID22,
    noOfFilter22,
    filterID23,
    noOfFilter23,
    filterID31,
    noOfFilter31,
    filterID32,
    noOfFilter32,
    filterID33,
    noOfFilter33,
    siteInfo,
    isCoordinatesCheck,
    isCogeneration,
    isStage5Check,
    isStage4Check,
    isStage6Check,
  } = payload;

  const xml = `<?xml version="1.0" encoding="utf-8"?>
      <NewDataSet>
        <xs:schema id="NewDataSet"
          xmlns=""
          xmlns:xs="http://www.w3.org/2001/XMLSchema"
          xmlns:msdata="urn:schemas-microsoft-com:xml-msdata">
          <xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:MainDataTable="Data" msdata:UseCurrentLocale="true">
            <xs:complexType>
              <xs:choice minOccurs="0" maxOccurs="unbounded">
                <xs:element name="Data">
                  <xs:complexType>
                    <xs:sequence>
                      <xs:element name="ValidFile-LCCGTRecord" type="xs:string" minOccurs="0" />
                      <xs:element name="RegionID" type="xs:double" minOccurs="0" />
                      <xs:element name="VERSION" type="xs:double" minOccurs="0" />
                      <xs:element name="Unit" type="xs:string" minOccurs="0" />
                      <xs:element name="UnitID" type="xs:long" minOccurs="0" />
                      <xs:element name="_x005B_Currency_x005D_" type="xs:string" minOccurs="0" />
                      <xs:element name="CurrencyID" type="xs:long" minOccurs="0" />
                      <xs:element name="PROJECTNAME" type="xs:string" minOccurs="0" />
                      <xs:element name="REPORTDATE" type="xs:string" minOccurs="0" />
                      <xs:element name="CONSULTINGENGINEER" type="xs:string" minOccurs="0" />
                      <xs:element name="ADDRESS" type="xs:string" minOccurs="0" />
                      <xs:element name="CITY" type="xs:string" minOccurs="0" />
                      <xs:element name="STATE" type="xs:string" minOccurs="0" />
                      <xs:element name="ZIPCODE" type="xs:string" minOccurs="0" />
                      <xs:element name="COUNTRY" type="xs:string" minOccurs="0" />
                      <xs:element name="ACCOUNTNAME" type="xs:string" minOccurs="0" />
                      <xs:element name="DESIGNATION" type="xs:string" minOccurs="0" />
                      <xs:element name="TELNO" type="xs:string" minOccurs="0" />
                      <xs:element name="MOBILENO" type="xs:string" minOccurs="0" />
                      <xs:element name="FAXNO" type="xs:string" minOccurs="0" />
                      <xs:element name="EMAIL" type="xs:string" minOccurs="0" />
                      <xs:element name="CUSTCOMPANY" type="xs:string" minOccurs="0" />
                      <xs:element name="CUSTSTREET" type="xs:string" minOccurs="0" />
                      <xs:element name="CUSTCITY" type="xs:string" minOccurs="0" />
                      <xs:element name="CUSTSTATE" type="xs:string" minOccurs="0" />
                      <xs:element name="CUSTZIP" type="xs:string" minOccurs="0" />
                      <xs:element name="CUSTCOUNTRY" type="xs:string" minOccurs="0" />
                      <xs:element name="CONTACTPERSON1" type="xs:string" minOccurs="0" />
                      <xs:element name="CONTACTNO1" type="xs:string" minOccurs="0" />
                      <xs:element name="CONTACTEMAIL1" type="xs:string" minOccurs="0" />
                      <xs:element name="CONTACTTITLE1" type="xs:string" minOccurs="0" />
                      <xs:element name="REPINFOCHANGED" type="xs:long" minOccurs="0" />
                      <xs:element name="SITE" type="xs:string" minOccurs="0" />
                      <xs:element name="BUILDING" type="xs:string" minOccurs="0" />
                      <xs:element name="LOCATION" type="xs:string" minOccurs="0" />
                      <xs:element name="AHU" type="xs:string" minOccurs="0" />
                      <xs:element name="DESCRIPTION" type="xs:string" minOccurs="0" />
                      <xs:element name="MANUFACTURER" type="xs:string" minOccurs="0" />
                      <xs:element name="MANUFACTURERID" type="xs:double" minOccurs="0" />
                      <xs:element name="GASTURBINEMODEL" type="xs:string" minOccurs="0" />
                      <xs:element name="GASTURBINEMODELID" type="xs:double" minOccurs="0" />
                      <xs:element name="TurbineModel" type="xs:string" minOccurs="0" />
                      <xs:element name="Years" type="xs:string" minOccurs="0" />
                      <xs:element name="ISOBASERATING" type="xs:double" minOccurs="0" />
                      <xs:element name="HEATRATE" type="xs:double" minOccurs="0" />
                      <xs:element name="PRESSRATIO" type="xs:double" minOccurs="0" />
                      <xs:element name="MASSFLOW" type="xs:double" minOccurs="0" />
                      <xs:element name="TURBINESPEED" type="xs:string" minOccurs="0" />
                      <xs:element name="TEMPINLET" type="xs:string" minOccurs="0" />
                      <xs:element name="TEMPEXHAUST" type="xs:string" minOccurs="0" />
                      <xs:element name="WEIGHT" type="xs:string" minOccurs="0" />
                      <xs:element name="APPROXIMATE" type="xs:string" minOccurs="0" />
                      <xs:element name="AIRDENSITY" type="xs:double" minOccurs="0" />
                      <xs:element name="COMMENTS" type="xs:string" minOccurs="0" />
                      <xs:element name="OPERATIONMODE" type="xs:string" minOccurs="0" />
                      <xs:element name="DOWNTIMEREPLACEMENT" type="xs:double" minOccurs="0" />
                      <xs:element name="SOLDPRICE" type="xs:double" minOccurs="0" />
                      <xs:element name="OUTPUT" type="xs:double" minOccurs="0" />
                      <xs:element name="OUTPUTREDUCTION" type="xs:double" minOccurs="0" />
                      <xs:element name="HEATRATEINCREASE" type="xs:double" minOccurs="0" />
                      <xs:element name="ENGINEHEATRATE" type="xs:double" minOccurs="0" />
                      <xs:element name="HeatRateOfEngineUnit" type="xs:string" minOccurs="0" />
                      <xs:element name="FUELCOST" type="xs:double" minOccurs="0" />
                      <xs:element name="FuelCostUnit" type="xs:string" minOccurs="0" />
                      <xs:element name="TimeOffFouledCompressorWashing" type="xs:double" minOccurs="0" />
                      <xs:element name="WaterWashCost" type="xs:double" minOccurs="0" />
                      <xs:element name="Airflow" type="xs:double" minOccurs="0" />
                      <xs:element name="CompressorDowntimeCost" type="xs:double" minOccurs="0" />
                      <xs:element name="CompressorPowerConsumptionCost" type="xs:double" minOccurs="0" />
                      <xs:element name="CompressorPressureRatio" type="xs:double" minOccurs="0" />
                      <xs:element name="RUNTIMEOFSYSTEM" type="xs:double" minOccurs="0" />
                      <xs:element name="TurbineOperatingTimeMode" type="xs:string" minOccurs="0" />
                      <xs:element name="BaseLoad" type="xs:double" minOccurs="0" />
                      <xs:element name="PartLoad" type="xs:double" minOccurs="0" />
                      <xs:element name="LCCPeriod" type="xs:double" minOccurs="0" />
                      <xs:element name="OUTDOORENVTYPE" type="xs:string" minOccurs="0" />
                      <xs:element name="OUTDOORENVTEXT" type="xs:string" minOccurs="0" />
                      <xs:element name="OUTDOORENVCONC" type="xs:double" minOccurs="0" />
                      <xs:element name="OUTDOORENVIRONMENTCATEOGORY" type="xs:string" minOccurs="0" />
                      <xs:element name="MajorMaintenanceInterval" type="xs:double" minOccurs="0" />
                      <xs:element name="CO2TaxRate" type="xs:double" minOccurs="0" />
                      <xs:element name="SteamCostPerTon" type="xs:double" minOccurs="0" />
                      <xs:element name="SteamPricePerTon" type="xs:double" minOccurs="0" />
                      <xs:element name="OUTDOORPM25LOCCRITERIA" type="xs:string" minOccurs="0" />
                      <xs:element name="PM25OutdoorEnvCategory" type="xs:string" minOccurs="0" />
                      <xs:element name="LATITUDE" type="xs:double" minOccurs="0" />
                      <xs:element name="LONGITUDE" type="xs:double" minOccurs="0" />
                      <xs:element name="GTPLANTID" type="xs:double" minOccurs="0" />
                      <xs:element name="PlantDesc" type="xs:string" minOccurs="0" />
                      <xs:element name="ApplyUserDataforPM25Concentration" type="xs:string" minOccurs="0" />
                      <xs:element name="PM25Jan" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Feb" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Mar" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Apr" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25May" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Jun" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Jul" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Aug" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Sep" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Oct" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Nov" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Dec" type="xs:double" minOccurs="0" />
                      <xs:element name="PM25Avg" type="xs:double" minOccurs="0" />
                      <xs:element name="PM10Avg" type="xs:double" minOccurs="0" />
                      <xs:element name="TotalSolution" type="xs:double" minOccurs="0" />
                      <xs:element name="Solution1" type="xs:double" minOccurs="0" />
                      <xs:element name="SolutionName1" type="xs:string" minOccurs="0" />
                      <xs:element name="TotalStage1" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterLifetimeCriteria1" type="xs:string" minOccurs="0" />
                      <xs:element name="OffLineWaterWashInterval1" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP11" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP21" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP31" type="xs:double" minOccurs="0" />
                      <xs:element name="INSTALLATIONMONTH1" type="xs:double" minOccurs="0" />
                      <xs:element name="DPSYSTEM1" type="xs:double" minOccurs="0" />
                      <xs:element name="ENGINEOVERHAULCOST1" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_11" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_11" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_11" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_11" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_11" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_11" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_11" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_12" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_12" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_12" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_12" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_12" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_12" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_12" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_13" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_13" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_13" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_13" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_13" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_13" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_13" type="xs:double" minOccurs="0" />
                      <xs:element name="Solution2" type="xs:double" minOccurs="0" />
                      <xs:element name="SolutionName2" type="xs:string" minOccurs="0" />
                      <xs:element name="TotalStage2" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterLifetimeCriteria2" type="xs:string" minOccurs="0" />
                      <xs:element name="OffLineWaterWashInterval2" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP12" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP22" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP32" type="xs:double" minOccurs="0" />
                      <xs:element name="INSTALLATIONMONTH2" type="xs:double" minOccurs="0" />
                      <xs:element name="DPSYSTEM2" type="xs:double" minOccurs="0" />
                      <xs:element name="ENGINEOVERHAULCOST2" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_21" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_21" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_21" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_21" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_21" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_21" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_21" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_22" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_22" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_22" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_22" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_22" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_22" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_22" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_23" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_23" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_23" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_23" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_23" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_23" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_23" type="xs:double" minOccurs="0" />
                      <xs:element name="Solution3" type="xs:double" minOccurs="0" />
                      <xs:element name="SolutionName3" type="xs:string" minOccurs="0" />
                      <xs:element name="TotalStage3" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterLifetimeCriteria3" type="xs:string" minOccurs="0" />
                      <xs:element name="OffLineWaterWashInterval3" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP13" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP23" type="xs:double" minOccurs="0" />
                      <xs:element name="SERVICESTOP33" type="xs:double" minOccurs="0" />
                      <xs:element name="INSTALLATIONMONTH3" type="xs:double" minOccurs="0" />
                      <xs:element name="DPSYSTEM3" type="xs:double" minOccurs="0" />
                      <xs:element name="ENGINEOVERHAULCOST3" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_31" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_31" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_31" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_31" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_31" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_31" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_31" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_32" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_32" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_32" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_32" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_32" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_32" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_32" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterID_33" type="xs:double" minOccurs="0" />
                      <xs:element name="ExchangeValue_33" type="xs:double" minOccurs="0" />
                      <xs:element name="NoOfFilter_33" type="xs:double" minOccurs="0" />
                      <xs:element name="FilterPrice_33" type="xs:double" minOccurs="0" />
                      <xs:element name="HOUSINGCOST_33" type="xs:double" minOccurs="0" />
                      <xs:element name="LABOURCOST_33" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLING_33" type="xs:double" minOccurs="0" />
                      <xs:element name="FILTERPRICEINCREASE" type="xs:double" minOccurs="0" />
                      <xs:element name="ABOURCOSTINCREASE" type="xs:double" minOccurs="0" />
                      <xs:element name="WASTEHANDLINGINCREASE" type="xs:double" minOccurs="0" />
                      <xs:element name="INTERESTRATE" type="xs:double" minOccurs="0" />
                      <xs:element name="PRICEINCREASE" type="xs:double" minOccurs="0" />
                      <xs:element name="FINANCERATE" type="xs:double" minOccurs="0" />
                      <xs:element name="REINVESTMENTRATE" type="xs:double" minOccurs="0" />
                      <xs:element name="EndOfXMLFile" type="xs:string" minOccurs="0" />
                    </xs:sequence>
                  </xs:complexType>
                </xs:element>
              </xs:choice>
            </xs:complexType>
          </xs:element>
        </xs:schema>
      <Data>
        <ValidFile-LCCGTRecord>True</ValidFile-LCCGTRecord>
    <RegionID>2</RegionID>
    <VERSION>1</VERSION>
    <Unit />
    <UnitID>${unitID === "Metric" ? "1" : "2"}</UnitID>
    <_x005B_Currency_x005D_>${currency}</_x005B_Currency_x005D_>
    <CurrencyID>${currencyID}</CurrencyID>
    <PROJECTNAME>${site}</PROJECTNAME>
    <REPORTDATE>${reportDate}</REPORTDATE>
    <CONSULTINGENGINEER />
    <ADDRESS />
    <CITY />
    <STATE />
    <ZIPCODE />
    <COUNTRY />
    <ACCOUNTNAME />
    <DESIGNATION />
    <TELNO />
    <MOBILENO />
    <FAXNO />
    <EMAIL>${salesPersonEmail || "chang.hong@camfil.com"}</EMAIL>
    <CUSTCOMPANY />
    <CUSTSTREET />
    <CUSTCITY />
    <CUSTSTATE />
    <CUSTZIP />
    <CUSTCOUNTRY />
    <CONTACTPERSON1 />
    <CONTACTNO1 />
    <CONTACTEMAIL1>${contactEmail}</CONTACTEMAIL1>
    <CONTACTTITLE1 />
    <REPINFOCHANGED>1</REPINFOCHANGED>
    <SITE>${site.slice(0, -8)}</SITE>
    <BUILDING />
    <LOCATION>${location}</LOCATION>
    <AHU />
    <DESCRIPTION>This XML file is submitted via FAST</DESCRIPTION>
    <MANUFACTURER>${manufacturer.replace(/&/g, "&amp;")}</MANUFACTURER>
    <MANUFACTURERID>${manufacturerID}</MANUFACTURERID>
    <GASTURBINEMODEL />
    <GASTURBINEMODELID>${turbineTypeID}</GASTURBINEMODELID>
    <TurbineModel>${turbineType}</TurbineModel>
    <Years />
    <ISOBASERATING>${isoBaseRating}</ISOBASERATING>
    <HEATRATE>${heatRate}</HEATRATE>
    <PRESSRATIO>0</PRESSRATIO>
    <MASSFLOW>${massFlow}</MASSFLOW>
    <TURBINESPEED>0</TURBINESPEED>
    <TEMPINLET />
    <TEMPEXHAUST />
    <WEIGHT />
    <APPROXIMATE />
    <AIRDENSITY>1.2</AIRDENSITY>
    <COMMENTS />
    <OPERATIONMODE>${operationMode}</OPERATIONMODE>
    <DOWNTIMEREPLACEMENT>${downtimeReplacement || "21"}</DOWNTIMEREPLACEMENT>
    <SOLDPRICE>${mWhSoldPrice}</SOLDPRICE>
    <OUTPUT>${gasTurbineOutput}</OUTPUT>
    <OUTPUTREDUCTION>1.4</OUTPUTREDUCTION>
    <HEATRATEINCREASE>0.4</HEATRATEINCREASE>
    <ENGINEHEATRATE>${heatRate}</ENGINEHEATRATE>
    <HeatRateOfEngineUnit>${heatRateUnit}</HeatRateOfEngineUnit>
    <FUELCOST>${fuelCost}</FUELCOST>
    <FuelCostUnit>${fuelCostUnit}</FuelCostUnit>
    <TimeOffFouledCompressorWashing>${compressorWashingDowntime}</TimeOffFouledCompressorWashing>
    <WaterWashCost>${compressorWashCost}</WaterWashCost>
    <Airflow>${totalAirflow}</Airflow>
    <CompressorDowntimeCost>0</CompressorDowntimeCost>
    <CompressorPowerConsumptionCost>0</CompressorPowerConsumptionCost>
    <CompressorPressureRatio>0</CompressorPressureRatio>
    <RUNTIMEOFSYSTEM>${turbineOperating}</RUNTIMEOFSYSTEM>
    <TurbineOperatingTimeMode>${
      loadType === "Full Load" ? "BASELOAD" : "PARTLOAD"
    }</TurbineOperatingTimeMode>
    <BaseLoad>${loadType === "Full Load" ? "100" : timeAtFullLoad}</BaseLoad>
    <PartLoad>${loadType === "Part Load" ? powerOutput : "0"}</PartLoad>
    <LCCPeriod>${lccPeriod}</LCCPeriod>
    <OUTDOORENVTYPE>PM25LOCATION</OUTDOORENVTYPE>
    <OUTDOORENVTEXT>25</OUTDOORENVTEXT>
    <OUTDOORENVCONC>0.025</OUTDOORENVCONC>
    <OUTDOORENVIRONMENTCATEOGORY>${
      siteInfo?.environment
    }</OUTDOORENVIRONMENTCATEOGORY>
    <MajorMaintenanceInterval>${maintenanceIntervalMonths}</MajorMaintenanceInterval>
    <CO2TaxRate>${co2TaxRate}</CO2TaxRate>
    <SteamCostPerTon>${isCogeneration ? "3" : "0"}</SteamCostPerTon>
    <SteamPricePerTon>${isCogeneration ? "2" : "0"}</SteamPricePerTon>
    <OUTDOORPM25LOCCRITERIA>${
      isCoordinatesCheck ? "PM25COORDINATE" : "PM25LOCATION"
    }</OUTDOORPM25LOCCRITERIA>
    <PM25OutdoorEnvCategory>${siteInfo.environment}</PM25OutdoorEnvCategory>
    <LATITUDE>${siteInfo.latitude}</LATITUDE>
    <LONGITUDE>${siteInfo.longitude}</LONGITUDE>
    <GTPLANTID>${siteInfo.plantid}</GTPLANTID>
    <PlantDesc>${
      isCoordinatesCheck
        ? ""
        : `${site.slice(0, -8)}[${siteInfo.latitude}, ${siteInfo.longitude}, ${
            siteInfo.environment
          }]`
    }</PlantDesc>
    <ApplyUserDataforPM25Concentration>False</ApplyUserDataforPM25Concentration>
    <PM25Jan>0</PM25Jan>
    <PM25Feb>0</PM25Feb>
    <PM25Mar>0</PM25Mar>
    <PM25Apr>0</PM25Apr>
    <PM25May>0</PM25May>
    <PM25Jun>0</PM25Jun>
    <PM25Jul>0</PM25Jul>
    <PM25Aug>0</PM25Aug>
    <PM25Sep>0</PM25Sep>
    <PM25Oct>0</PM25Oct>
    <PM25Nov>0</PM25Nov>
    <PM25Dec>0</PM25Dec>
    <PM25Avg>0</PM25Avg>
    <PM10Avg>0</PM10Avg>
    <TotalSolution>${
      isStage4Check && isStage6Check !== 0 ? "3" : "2"
    }</TotalSolution>
    <Solution1>1</Solution1>
    <SolutionName1>FAST Global Optimal Solution</SolutionName1>
    <TotalStage1>${filterStageNumber1}</TotalStage1>
    <FilterLifetimeCriteria1>TIMEBASE</FilterLifetimeCriteria1>
    <OffLineWaterWashInterval1>${offlineWaterWashInterval}</OffLineWaterWashInterval1>
    <SERVICESTOP11>0</SERVICESTOP11>
    <SERVICESTOP21>0</SERVICESTOP21>
    <SERVICESTOP31>0</SERVICESTOP31>
    <INSTALLATIONMONTH1>1</INSTALLATIONMONTH1>
    <DPSYSTEM1>0</DPSYSTEM1>
    <ENGINEOVERHAULCOST1>0</ENGINEOVERHAULCOST1>`;

  const chunk2 = `${
    isStage5Check === 1
      ? `<FilterID_11>${filterID13}</FilterID_11>
    <ExchangeValue_11>8760</ExchangeValue_11>
    <NoOfFilter_11>${noOfFilter13}</NoOfFilter_11>
    <FilterPrice_11>11</FilterPrice_11>
    <HOUSINGCOST_11>0</HOUSINGCOST_11>
    <LABOURCOST_11>0</LABOURCOST_11>
    <WASTEHANDLING_11>0</WASTEHANDLING_11>`
      : isStage5Check === 2 && filterID12 !== undefined
      ? `<FilterID_11>${filterID12}</FilterID_11>
    <ExchangeValue_11>8760</ExchangeValue_11>
    <NoOfFilter_11>${noOfFilter12}</NoOfFilter_11>
    <FilterPrice_11>11</FilterPrice_11>
    <HOUSINGCOST_11>0</HOUSINGCOST_11>
    <LABOURCOST_11>0</LABOURCOST_11>
    <WASTEHANDLING_11>0</WASTEHANDLING_11>
    <FilterID_12>${filterID13}</FilterID_12>
    <ExchangeValue_12>8760</ExchangeValue_12>
    <NoOfFilter_12>${noOfFilter13}</NoOfFilter_12>
    <FilterPrice_12>11</FilterPrice_12>
    <HOUSINGCOST_12>0</HOUSINGCOST_12>
    <LABOURCOST_12>0</LABOURCOST_12>
    <WASTEHANDLING_12>0</WASTEHANDLING_12>`
      : isStage5Check === 2 && filterID12 === undefined
      ? `<FilterID_11>${filterID11}</FilterID_11>
    <ExchangeValue_11>8760</ExchangeValue_11>
    <NoOfFilter_11>${noOfFilter11}</NoOfFilter_11>
    <FilterPrice_11>11</FilterPrice_11>
    <HOUSINGCOST_11>0</HOUSINGCOST_11>
    <LABOURCOST_11>0</LABOURCOST_11>
    <WASTEHANDLING_11>0</WASTEHANDLING_11>
    <FilterID_12>${filterID13}</FilterID_12>
    <ExchangeValue_12>8760</ExchangeValue_12>
    <NoOfFilter_12>${noOfFilter13}</NoOfFilter_12>
    <FilterPrice_12>11</FilterPrice_12>
    <HOUSINGCOST_12>0</HOUSINGCOST_12>
    <LABOURCOST_12>0</LABOURCOST_12>
    <WASTEHANDLING_12>0</WASTEHANDLING_12>`
      : `<FilterID_11>${filterID11}</FilterID_12>
    <ExchangeValue_11>8760</ExchangeValue_11>
    <NoOfFilter_11>${noOfFilter11}</NoOfFilter_11>
    <FilterPrice_11>11</FilterPrice_11>
    <HOUSINGCOST_11>0</HOUSINGCOST_11>
    <LABOURCOST_11>0</LABOURCOST_11>
    <WASTEHANDLING_11>0</WASTEHANDLING_11>
    <FilterID_12>${filterID12}</FilterID_12>
    <ExchangeValue_12>8760</ExchangeValue_12>
    <NoOfFilter_12>${noOfFilter12}</NoOfFilter_12>
    <FilterPrice_12>11</FilterPrice_12>
    <HOUSINGCOST_12>0</HOUSINGCOST_12>
    <LABOURCOST_12>0</LABOURCOST_12>
    <WASTEHANDLING_12>0</WASTEHANDLING_12>
    <FilterID_13>${filterID13}</FilterID_13>
    <ExchangeValue_13>8760</ExchangeValue_13>
    <NoOfFilter_13>${noOfFilter13}</NoOfFilter_13>
    <FilterPrice_13>11</FilterPrice_13>
    <HOUSINGCOST_13>0</HOUSINGCOST_13>
    <LABOURCOST_13>0</LABOURCOST_13>
    <WASTEHANDLING_13>0</WASTEHANDLING_13>`
  }`;

  const chunk3 = `${
    isStage6Check === 0
      ? ""
      : isStage6Check === 1
      ? `<Solution2>2</Solution2>
      <SolutionName2>Customer Solution</SolutionName2>
      <TotalStage2>${filterStageNumber2}</TotalStage2>
      <FilterLifetimeCriteria2>TIMEBASE</FilterLifetimeCriteria2>
      <OffLineWaterWashInterval2>${offlineWaterWashInterval}</OffLineWaterWashInterval2>
      <SERVICESTOP12>0</SERVICESTOP12>
      <SERVICESTOP22>0</SERVICESTOP22>
      <SERVICESTOP32>0</SERVICESTOP32>
      <INSTALLATIONMONTH2>1</INSTALLATIONMONTH2>
      <DPSYSTEM2>0</DPSYSTEM2>
      <ENGINEOVERHAULCOST2>0</ENGINEOVERHAULCOST2>
      <FilterID_21>${filterID23}</FilterID_21>
      <ExchangeValue_21>8760</ExchangeValue_21>
      <NoOfFilter_21>${noOfFilter23}</NoOfFilter_21>
      <FilterPrice_21>11</FilterPrice_21>
      <HOUSINGCOST_21>0</HOUSINGCOST_21>
      <LABOURCOST_21>0</LABOURCOST_21>
      <WASTEHANDLING_21>0</WASTEHANDLING_21>`
      : isStage6Check == 2 && filterID22 !== undefined
      ? `<Solution2>2</Solution2>
      <SolutionName2>Customer Solution</SolutionName2>
      <TotalStage2>${filterStageNumber2}</TotalStage2>
      <FilterLifetimeCriteria2>TIMEBASE</FilterLifetimeCriteria2>
      <OffLineWaterWashInterval2>${offlineWaterWashInterval}</OffLineWaterWashInterval2>
      <SERVICESTOP12>0</SERVICESTOP12>
      <SERVICESTOP22>0</SERVICESTOP22>
      <SERVICESTOP32>0</SERVICESTOP32>
      <INSTALLATIONMONTH2>1</INSTALLATIONMONTH2>
      <DPSYSTEM2>0</DPSYSTEM2>
      <ENGINEOVERHAULCOST2>0</ENGINEOVERHAULCOST2>
      <FilterID_21>${filterID22}</FilterID_21>
      <ExchangeValue_21>8760</ExchangeValue_21>
      <NoOfFilter_21>${noOfFilter22}</NoOfFilter_21>
      <FilterPrice_21>11</FilterPrice_21>
      <HOUSINGCOST_21>0</HOUSINGCOST_21>
      <LABOURCOST_21>0</LABOURCOST_21>
      <WASTEHANDLING_21>0</WASTEHANDLING_21>
      <FilterID_22>${filterID23}</FilterID_22>
      <ExchangeValue_22>8760</ExchangeValue_22>
      <NoOfFilter_22>${noOfFilter23}</NoOfFilter_22>
      <FilterPrice_22>11</FilterPrice_22>
      <HOUSINGCOST_22>0</HOUSINGCOST_22>
      <LABOURCOST_22>0</LABOURCOST_22>
      <WASTEHANDLING_22>0</WASTEHANDLING_22>`
      : isStage6Check === 2 && filterID22 === undefined
      ? `<Solution2>2</Solution2>
      <SolutionName2>Customer Solution</SolutionName2>
      <TotalStage2>${filterStageNumber2}</TotalStage2>
      <FilterLifetimeCriteria2>TIMEBASE</FilterLifetimeCriteria2>
      <OffLineWaterWashInterval2>${offlineWaterWashInterval}</OffLineWaterWashInterval2>
      <SERVICESTOP12>0</SERVICESTOP12>
      <SERVICESTOP22>0</SERVICESTOP22>
      <SERVICESTOP32>0</SERVICESTOP32>
      <INSTALLATIONMONTH2>1</INSTALLATIONMONTH2>
      <DPSYSTEM2>0</DPSYSTEM2>
      <ENGINEOVERHAULCOST2>0</ENGINEOVERHAULCOST2>
      <FilterID_21>${filterID21}</FilterID_21>
      <ExchangeValue_21>8760</ExchangeValue_21>
      <NoOfFilter_21>${noOfFilter21}</NoOfFilter_21>
      <FilterPrice_21>11</FilterPrice_21>
      <HOUSINGCOST_21>0</HOUSINGCOST_21>
      <LABOURCOST_21>0</LABOURCOST_21>
      <WASTEHANDLING_21>0</WASTEHANDLING_21>
      <FilterID_22>${filterID23}</FilterID_22>
      <ExchangeValue_22>8760</ExchangeValue_22>
      <NoOfFilter_22>${noOfFilter23}</NoOfFilter_22>
      <FilterPrice_22>11</FilterPrice_22>
      <HOUSINGCOST_22>0</HOUSINGCOST_22>
      <LABOURCOST_22>0</LABOURCOST_22>
      <WASTEHANDLING_22>0</WASTEHANDLING_22>`
      : `<Solution2>2</Solution2>
      <SolutionName2>Customer Solution</SolutionName2>
      <TotalStage2>${filterStageNumber2}</TotalStage2>
      <FilterLifetimeCriteria2>TIMEBASE</FilterLifetimeCriteria2>
      <OffLineWaterWashInterval2>${offlineWaterWashInterval}</OffLineWaterWashInterval2>
      <SERVICESTOP12>0</SERVICESTOP12>
      <SERVICESTOP22>0</SERVICESTOP22>
      <SERVICESTOP32>0</SERVICESTOP32>
      <INSTALLATIONMONTH2>1</INSTALLATIONMONTH2>
      <DPSYSTEM2>0</DPSYSTEM2>
      <ENGINEOVERHAULCOST2>0</ENGINEOVERHAULCOST2>
      <FilterID_21>${filterID21}</FilterID_21>
      <ExchangeValue_21>8760</ExchangeValue_21>
      <NoOfFilter_21>${noOfFilter21}</NoOfFilter_21>
      <FilterPrice_21>11</FilterPrice_21>
      <HOUSINGCOST_21>0</HOUSINGCOST_21>
      <LABOURCOST_21>0</LABOURCOST_21>
      <WASTEHANDLING_21>0</WASTEHANDLING_21>
      <FilterID_22>${filterID22}</FilterID_22>
      <ExchangeValue_22>8760</ExchangeValue_22>
      <NoOfFilter_22>${noOfFilter22}</NoOfFilter_22>
      <FilterPrice_22>11</FilterPrice_22>
      <HOUSINGCOST_22>0</HOUSINGCOST_22>
      <LABOURCOST_22>0</LABOURCOST_22>
      <WASTEHANDLING_22>0</WASTEHANDLING_22>
      <FilterID_23>${filterID23}</FilterID_23>
      <ExchangeValue_23>8760</ExchangeValue_23>
      <NoOfFilter_23>${noOfFilter23}</NoOfFilter_23>
      <FilterPrice_23>11</FilterPrice_23>
      <HOUSINGCOST_23>0</HOUSINGCOST_23>
      <LABOURCOST_23>0</LABOURCOST_23>
      <WASTEHANDLING_23>0</WASTEHANDLING_23>`
  }`;

  const chunk4 = `${
    !isStage4Check
      ? ""
      : isStage4Check === 1
      ? `<Solution3>3</Solution3>
      <SolutionName3>Optimal solution for existing configuration</SolutionName3>
      <TotalStage3>${filterStageNumber3}</TotalStage3>
      <FilterLifetimeCriteria3>TIMEBASE</FilterLifetimeCriteria3>
      <OffLineWaterWashInterval3>${offlineWaterWashInterval}</OffLineWaterWashInterval3>
      <SERVICESTOP13>0</SERVICESTOP13>
      <SERVICESTOP23>0</SERVICESTOP23>
      <SERVICESTOP33>0</SERVICESTOP33>
      <INSTALLATIONMONTH3>1</INSTALLATIONMONTH3>
      <DPSYSTEM3>0</DPSYSTEM3>
      <ENGINEOVERHAULCOST3>0</ENGINEOVERHAULCOST3>
      <FilterID_31>${filterID33}</FilterID_31>
      <ExchangeValue_31>8760</ExchangeValue_31>
      <NoOfFilter_31>${noOfFilter33}</NoOfFilter_31>
      <FilterPrice_31>11</FilterPrice_31>
      <HOUSINGCOST_31>0</HOUSINGCOST_31>
      <LABOURCOST_31>0</LABOURCOST_31>
      <WASTEHANDLING_31>0</WASTEHANDLING_31>`
      : isStage4Check === 2 && filterID32 !== undefined
      ? `<Solution3>3</Solution3>
      <SolutionName3>Optimal solution for existing configuration</SolutionName3>
      <TotalStage3>${filterStageNumber3}</TotalStage3>
      <FilterLifetimeCriteria3>TIMEBASE</FilterLifetimeCriteria3>
      <OffLineWaterWashInterval3>${offlineWaterWashInterval}</OffLineWaterWashInterval3>
      <SERVICESTOP13>0</SERVICESTOP13>
      <SERVICESTOP23>0</SERVICESTOP23>
      <SERVICESTOP33>0</SERVICESTOP33>
      <INSTALLATIONMONTH3>1</INSTALLATIONMONTH3>
      <DPSYSTEM3>0</DPSYSTEM3>
      <ENGINEOVERHAULCOST3>0</ENGINEOVERHAULCOST3>
      <FilterID_31>${filterID32}</FilterID_31>
      <ExchangeValue_31>8760</ExchangeValue_31>
      <NoOfFilter_31>${noOfFilter32}</NoOfFilter_31>
      <FilterPrice_31>11</FilterPrice_31>
      <HOUSINGCOST_31>0</HOUSINGCOST_31>
      <LABOURCOST_31>0</LABOURCOST_31>
      <WASTEHANDLING_31>0</WASTEHANDLING_31>
      <FilterID_32>${filterID33}</FilterID_32>
      <ExchangeValue_32>8760</ExchangeValue_32>
      <NoOfFilter_32>${noOfFilter33}</NoOfFilter_32>
      <FilterPrice_32>11</FilterPrice_32>
      <HOUSINGCOST_32>0</HOUSINGCOST_32>
      <LABOURCOST_32>0</LABOURCOST_32>
      <WASTEHANDLING_32>0</WASTEHANDLING_32>`
      : isStage4Check == 2 && filterID32 === undefined
      ? `<Solution3>3</Solution3>
      <SolutionName3>Optimal solution for existing configuration</SolutionName3>
      <TotalStage3>${filterStageNumber3}</TotalStage3>
      <FilterLifetimeCriteria3>TIMEBASE</FilterLifetimeCriteria3>
      <OffLineWaterWashInterval3>${offlineWaterWashInterval}</OffLineWaterWashInterval3>
      <SERVICESTOP13>0</SERVICESTOP13>
      <SERVICESTOP23>0</SERVICESTOP23>
      <SERVICESTOP33>0</SERVICESTOP33>
      <INSTALLATIONMONTH3>1</INSTALLATIONMONTH3>
      <DPSYSTEM3>0</DPSYSTEM3>
      <ENGINEOVERHAULCOST3>0</ENGINEOVERHAULCOST3>
      <FilterID_31>${filterID31}</FilterID_31>
      <ExchangeValue_31>8760</ExchangeValue_31>
      <NoOfFilter_31>${noOfFilter31}</NoOfFilter_31>
      <FilterPrice_31>11</FilterPrice_31>
      <HOUSINGCOST_31>0</HOUSINGCOST_31>
      <LABOURCOST_31>0</LABOURCOST_31>
      <WASTEHANDLING_31>0</WASTEHANDLING_31>
      <FilterID_32>${filterID33}</FilterID_32>
      <ExchangeValue_32>8760</ExchangeValue_32>
      <NoOfFilter_32>${noOfFilter33}</NoOfFilter_32>
      <FilterPrice_32>11</FilterPrice_32>
      <HOUSINGCOST_32>0</HOUSINGCOST_32>
      <LABOURCOST_32>0</LABOURCOST_32>
      <WASTEHANDLING_32>0</WASTEHANDLING_32>`
      : `<Solution3>3</Solution3>
      <SolutionName3>Optimal solution for existing configuration</SolutionName3>
      <TotalStage3>${filterStageNumber3}</TotalStage3>
      <FilterLifetimeCriteria3>TIMEBASE</FilterLifetimeCriteria3>
      <OffLineWaterWashInterval3>${offlineWaterWashInterval}</OffLineWaterWashInterval3>
      <SERVICESTOP13>0</SERVICESTOP13>
      <SERVICESTOP23>0</SERVICESTOP23>
      <SERVICESTOP33>0</SERVICESTOP33>
      <INSTALLATIONMONTH3>1</INSTALLATIONMONTH3>
      <DPSYSTEM3>0</DPSYSTEM3>
      <ENGINEOVERHAULCOST3>0</ENGINEOVERHAULCOST3>
      <FilterID_31>${filterID31}</FilterID_31>
      <ExchangeValue_31>8760</ExchangeValue_31>
      <NoOfFilter_31>${noOfFilter31}</NoOfFilter_31>
      <FilterPrice_31>11</FilterPrice_31>
      <HOUSINGCOST_31>0</HOUSINGCOST_31>
      <LABOURCOST_31>0</LABOURCOST_31>
      <WASTEHANDLING_31>0</WASTEHANDLING_31>
      <FilterID_32>${filterID32}</FilterID_32>
      <ExchangeValue_32>8760</ExchangeValue_32>
      <NoOfFilter_32>${noOfFilter32}</NoOfFilter_32>
      <FilterPrice_32>11</FilterPrice_32>
      <HOUSINGCOST_32>0</HOUSINGCOST_32>
      <LABOURCOST_32>0</LABOURCOST_32>
      <WASTEHANDLING_32>0</WASTEHANDLING_32>
      <FilterID_33>${filterID33}</FilterID_33>
      <ExchangeValue_33>8760</ExchangeValue_33>
      <NoOfFilter_33>${noOfFilter33}</NoOfFilter_33>
      <FilterPrice_33>11</FilterPrice_33>
      <HOUSINGCOST_33>0</HOUSINGCOST_33>
      <LABOURCOST_33>0</LABOURCOST_33>
      <WASTEHANDLING_33>0</WASTEHANDLING_33>`
  }`;

  const chunk5 = `<FILTERPRICEINCREASE>2</FILTERPRICEINCREASE>
    <ABOURCOSTINCREASE>2</ABOURCOSTINCREASE>
    <WASTEHANDLINGINCREASE>2</WASTEHANDLINGINCREASE>
    <INTERESTRATE>8</INTERESTRATE>
    <PRICEINCREASE>0</PRICEINCREASE>
    <FINANCERATE>8</FINANCERATE>
    <REINVESTMENTRATE>8</REINVESTMENTRATE>
    <EndOfXMLFile>END</EndOfXMLFile>
    </Data>
    </NewDataSet>`;

  let finalXML = "";
  if (xml) finalXML += String(xml);
  if (chunk2) finalXML += String(chunk2);
  if (chunk3) finalXML += String(chunk3);
  if (chunk4) finalXML += String(chunk4);
  if (chunk5) finalXML += String(chunk5);
  const storedXml = btoa(finalXML.trim());
  return storedXml;
};
