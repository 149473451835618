import { useTheme } from "@mui/material";

const GetStyles = () => {
  const theme = useTheme();
  return {
    rightCell: {
      color: theme.palette.mode === "dark" ? "#FFF" : "#000",
      fontSize: 15,
      border: `2px solid ${
        theme.palette.mode === "dark" ? "#211F2C" : "#c9c6d8"
      }`,
      padding: "10px",
      minWidth: "300px",
      minHeight: "10px",
    },
    leftCell: {
      color: theme.palette.mode === "dark" ? "#FFF" : "#000",
      fontSize: 15,
      border: `2px solid ${
        theme.palette.mode === "dark" ? "#211F2C" : "#c9c6d8"
      }`,
      padding: "10px",
      minWidth: "300px",
      backgroundColor: theme.palette.mode === "dark" ? "#43404D" : "#edf0f9",
      minHeight: "10px",
    },
    tableContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      gap: "10px",
    },
  };
};
export default GetStyles;
