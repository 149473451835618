import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const StyledBox = styled(Box)({
  width: "100%",
  margin: "0 auto",
});

export const MyStyledTypography = styled(Typography)(
  ({ customColor, theme }) => ({
    color: customColor || theme.palette.mode === "dark" ? "#FFFFFF" : "#000",
    textAlign: "center",
    fontFamily: "Inter",
    lineHeight: "57.6px",
    marginTop: "2rem",
    "&.MuiTypography-h1": {
      fontSize: "2.5vw",
      fontWeight: "700",
    },
  })
);

const GetStyles = () => {
  const theme = useTheme();
  return {
    pageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "calc(100vh - 70px)",
      backgroundColor: theme.palette.mode !== "dark" ? "#edf0f9" : "#121212",
    },
    labelStyles: {
      fontSize: "18px",
      fontWeight: "600",
      color: theme.palette.mode !== "dark" ? "#000" : "#FFFFFF",
      fontFamily: "Inter",
      lineHeight: "21.6px",
      marginBottom: "11px",
      marginTop: "22px",
    },
    contactLabel: {
      width: "100%",
      display: "flex",
      textAlign: "center",
      color: theme.palette.mode !== "dark" ? "#000" : "white",
      fontWeight: 600,
      fontFamily: "Inter",
      margin: "0 -0.5rem",
      fontSize: "1.9vw",
      whiteSpace: "nowrap",
      lineHeight: "40px",
    },
    emailLabel: {
      variant: "body1",
      color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
      fontWeight: 700,
      fontFamily: "Inter",
      fontSize: "26px",
      marginTop: "12px",
      lineHeight: "40px",
    },
    containerStyle: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      margin: "auto",
    },
    dropdownStyle: {
      fontSize: "17px",
      fontWeight: 500,
    },
    textfieldStyle: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Inter",
      lineHeight: "24px",
    },
    bg: {
      width: "359px",
      height: "435px",
      position: "absolute",
      bottom: "-162px",
      right: "-127px",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
  };
};

export default GetStyles;
