import authInstance from "./axios-instances/auth";
import pdfInstance from "./axios-instances/pdfblob";

export function fetchSites() {
  return authInstance.get(`/services/Sites`);
}

export function fetchSiteCordinates(site) {
  return authInstance.get(`/services/Sites/${site}/Coordinates`);
}

export function fetchSiteInfo(site) {
  return authInstance.get(`/services/Sites/${site}/Info`);
}

export function fetchSitePM25(site) {
  return authInstance.get(`/services/Sites/${site}/PM25`);
}

export function fetchApplications() {
  return authInstance.get(`/services/Applications`);
}

export function fetchFilterDepths() {
  return authInstance.get(`/services/FilterDepths`);
}

export function plotPM25(site) {
  return authInstance.get(`/services/Sites/${site}/PM25`);
}

export function getCurrencyUnit(site) {
  return authInstance.get(`/services/Sites/${site}/Currency`);
}

export function fetchEngineManufacturer() {
  return authInstance.get(`/services/LccManufacturers`);
}

export function getLCCTurbineFromManufacturers(LccManufacturer) {
  if (LccManufacturer.includes("SIEMENS")) {
    LccManufacturer = "SIEMENS";
  }
  return authInstance.get(
    `/services/LccManufactures/${LccManufacturer}/LccTurbines`
  );
}

export function getLCCTurbineAirflowAndHeatrate(payload) {
  const query = new URLSearchParams(payload);
  return authInstance.get(`/services/LccTurbines?${query.toString()}`);
}

export function getGlobalOptimalData(site, application, payload) {
  const query = new URLSearchParams(payload);
  return authInstance.post(
    `/services/Sites/${site}/Applications/${application}/GlobalOptimal?${query.toString()}`
  );
}

export function getOptimalSizeSolutionData(payload) {
  return authInstance.post(
    `/services/Sites/${payload.site}/Applications/${payload.application}/OptimalSizeSolution`,
    payload
  );
}
export function getPreFilterMinimumEfficiency(site, application, payload) {
  return authInstance.post(
    `/services/Sites/${site}/Applications/${application}/PreFilterEffMin`,
    payload
  );
}

export function getOptimalFunctionData(site, application, payload) {
  return authInstance.post(
    `/services/Sites/${site}/Applications/${application}/OptimalFunc`,
    payload
  );
}

export function getOptimalTypeSolutionData(site, application, payload) {
  return authInstance.post(
    `/services/Sites/${site}/Applications/${application}/OptimalTypeSolution`,
    payload
  );
}

export function customOptimalFunc(site, application, payload) {
  return authInstance.post(
    `/services/Sites/${site}/Applications/${application}/CustomOptimalFunc`,
    payload
  );
}

export function optimalFinalLifeSolution(site, application, payload) {
  return authInstance.post(
    `/services/Sites/${site}/Applications/${application}/OptimalFinalLifeSolution`,
    payload
  );
}

export function optimalFinalEffSolution(site, application, payload) {
  return authInstance.post(
    `/services/Sites/${site}/Applications/${application}/OptimalFinalEffSolution`,
    payload
  );
}

export function getValueRating(application, final_filter, payload) {
  return authInstance.post(
    `/services/Sites/Applications/${application}/Filter/${final_filter}/valuerating`,
    payload
  );
}

export function sendEmailAttachment(payload, formData) {
  return authInstance.post(
    `/services/send-email-attachment?customer_name=${payload.customer_name}&customer_email=${payload.customer_email}&site=${payload.site}&application_t=${payload.application_t}&output_input=${payload.output_input}&torecip=${payload.torecip}&ccrecip=${payload.ccrecip}&customer_stage=${payload.customer_stage}&customer_filter_name1=${payload.customer_filter_name1}&customer_filter_type1=${payload.customer_filter_type1}&customer_filter_eff1=${payload.customer_filter_eff1}&customer_filter_number1=${payload.customer_filter_number1}&customer_filter_name2=${payload.customer_filter_name2}&customer_filter_type2=${payload.customer_filter_type2}&customer_filter_eff2=${payload.customer_filter_eff2}&customer_filter_number2=${payload.customer_filter_number2}&customer_filter_name3=${payload.customer_filter_name3}&customer_filter_type3=${payload.customer_filter_type3}&customer_filter_eff3=${payload.customer_filter_eff3}&customer_filter_number3=${payload.customer_filter_number3}&stage1_size=${payload.stage1_size}&stage1_eff=${payload.stage1_eff}&stage1_type=${payload.stage1_type}&stage2_size=${payload.stage2_size}&stage2_eff=${payload.stage2_eff}&stage2_type=${payload.stage2_type}&stage3_size=${payload.stage3_size}&stage3_eff=${payload.stage3_eff}&stage3_type=${payload.stage3_type}&final_life=${payload.final_life}`,
    formData
  );
}

export function sendContactEmail(payload) {
  return authInstance.post(
    `/services/send-email-customer-form?customer_name=${payload.customer_name}&customer_email=${payload.customer_email}&query_subject=${payload.query_subject}&query_topic=${payload.query_topic}&query_details=${payload.query_details}&site=${payload.site}&application=${payload.application}&output=${payload.output}&torecip=${payload.torecip}&ccrecip=${payload.ccrecip}`
  );
}

export function generatePDFReport(site, client_name) {
  return pdfInstance.post(
    `/services/Sites/${site}/GenerateReport?client_name=${client_name}`
  );
}
