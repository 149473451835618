import { styled } from "styled-components";
import { Modal } from "@mui/material";

import Box from "@mui/material/Box";

export const StyledBox = styled(Box)({
	width: "100%",
	margin: "0 auto",
});

export const StyledModal = styled(Modal)({
	"& .MuiBox-root": {
		border: "none",
		outline: "none",
		backgroundColor: "#282A30",
		color: "lightgrey",
		borderRadius: "10px",
		width: "100%",
		padding: "0px",
	},
	"& .MuiTypography-root": {
		textAlign: "center",
		fontSize: "33px",
		fontFamily: "Inter",
		color: "white",
	},
	"& .MuiTypography-body1": {
		fontSize: "18px",
	},
	"& .MuiButtonBase-root": {
		display: "flex",
		color: "black",
		fontFamily: "Inter",
		textTransform: "Capitalize",
		justifyContent: "center",
		right: "20px",
	},
	maxWidth: "700px",
	width: "90vw",
	margin: "auto",
});

const styles = {
	modalContainer: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		width: "100%",
		borderRadius: "5%",
		overflowY: "auto",
		overflowX: "hidden",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItem: "center",
		paggingLeft: "63px",
	},
	containerStyles: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		width: "100%",
		margin: "auto",
	},
	labelStyles: {
		textAlign: "left",
	},
	invitationStyles: {
		fontFamily: "Inter",
		fontSize: "35px",
		lineHeight: 1.2,
		fontWeight: 700,
		color: "white",
		textAlign: "center",
	},
	textStyles: {
		fontSize: "16px",
		fontWeight: "500",
		flex: "0 0 360px",
		color: "#fff",
	},
	nameContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		marginBottom: "34px",
		marginTop: "10px",
		alignItems: "center",
		paddingRight: "16px",
	},
	emailContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		marginBottom: "34px",
		alignItems: "center",
		paddingRight: "16px",
	},
	companyContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		marginBottom: "34px",
		alignItems: "center",
		paddingRight: "16px",
	},
	dateContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		marginBottom: "34px",
		alignItems: "center",
		paddingRight: "16px",
	},
	accessCodeContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		marginBottom: "23px",
		alignItems: "center",
		paddingRight: "16px",
		marginTop: "20px",
	},
	buttonStyle: {
		fontSize: "18px",
		width: "423px",
		height: "55px",
		margin: "0 auto",
		alignItems: "center",
		marginBottom: "20px",
		borderRadius: "6px",
		"@media (max-width: 768px)": {
			width: "100%",
		},
	},
};

export default styles;
