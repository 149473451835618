import axios from "axios";

// Utils
import getHeaders from "utils/get-headers";
import { handleCommonRequest, handleCommonResponse } from "./common-response";

let instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: getHeaders(),
});
handleCommonRequest(instance, getHeaders());
handleCommonResponse(instance);

console.log(instance.baseURL);

export default instance;
