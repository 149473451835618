export const deserializeGraph = (values, initArray) => {
  let serialArray = [];
  if (initArray) {
    serialArray = [...initArray];
  } else {
    serialArray = [
      "Cost of fouling",
      "Cost of pressure drop",
      "Direct filter cost",
      "Cost of downtime",
    ].map((item) => {
      return {
        seriesname: item,
        data: [],
      };
    });
  }
  const colorMapping = {
    "Cost of fouling": "#34B4E4",
    "Cost of pressure drop": "#B7DD79",
    "Direct filter cost": "#00673E",
    "Cost of downtime": "#D1CCBD",
  };

  for (const value of values) {
    for (let i = 0; i < 4; i++) {
      if (serialArray[i].seriesname === value.name) {
        serialArray[i].data.push({
          value: value.value,
          color: colorMapping[value.name],
        });
        break;
      }
    }
  }
  return serialArray;
};
const colorMapping = {
  "Cost of fouling": "#34B4E4",
  "Cost of pressure drop": "#B7DD79",
  "Direct filter cost": "#00673E",
  "Cost of downtime": "#D1CCBD",
};

export const manipulateCurrentSolution = (currValue, newValue) => {
  const newCurr = JSON.parse(JSON.stringify(currValue));
  for (const value of newValue) {
    for (let i = 0; i < 4; i++) {
      if (newCurr[i].seriesname === value.name) {
        if (newCurr[i].data.length === 1) {
          newCurr[i].data.splice(2, 1, {
            value: value.value,
            color: colorMapping[value.name],
          });
        } else if (newCurr[i].data.length === 2) {
          newCurr[i].data.splice(1, 1, {
            value: value.value,
            color: colorMapping[value.name],
          });
        } else {
          newCurr[i].data.splice(2, 2, {
            value: value.value,
            color: colorMapping[value.name],
          });
        }

        break;
      }
    }
  }
  console.log(newCurr, "new data for plot after change");
  return newCurr;
};

export const manipulateSizeSolution = (currValue, newValue) => {
  const newCurr = JSON.parse(JSON.stringify(currValue));
  for (const value of newValue) {
    for (let i = 0; i < 4; i++) {
      if (newCurr[i].seriesname === value.name) {
        if (newCurr[i].data.length === 2) {
          newCurr[i].data.splice(1, 0, {
            // add size limitation bar in the middle
            value: value.value,
            color: colorMapping[value.name],
          });
          newCurr[i].data.splice(2, 2, {
            // update current solution bar
            value: value.value,
            color: colorMapping[value.name],
          });
        } else {
          // update size limitation bar
          newCurr[i].data.splice(1, 1, {
            value: value.value,
            color: colorMapping[value.name],
          });
          newCurr[i].data.splice(2, 2, {
            // update current solution bar
            value: value.value,
            color: colorMapping[value.name],
          });
        }
        break;
      }
    }
  }
  console.log(newCurr, "new data for plot after size change");
  return newCurr;
};
