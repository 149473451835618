import { useTheme } from "@mui/material";
import React, { useMemo } from "react";
import FusionCharts from "react-fusioncharts";

function PM25Graph({ plots }) {
  const theme = useTheme();
  const chartConfigs = useMemo(() => {
    if (plots) {
      const data = Object.keys(plots).map((key) => {
        return {
          label: key,
          value: plots[key],
          color: theme.palette.mode !== "dark" ? "#008c64" : "#3ab9c9",
          anchorBgColor: theme.palette.mode !== "dark" ? "#008c64" : "#3ab9c9",
          anchorBorderColor:
            theme.palette.mode !== "dark" ? "#008c64" : "#3ab9c9",
        };
      });
      return {
        type: "line",
        width: "230px",
        height: "140px",
        dataFormat: "json",

        dataSource: {
          plotColor: theme.palette.mode !== "dark" ? "#008c64" : "#3ab9c9",
          chart: {
            caption: "PM2.5 Conc.",
            captionColor: "#fff",
            showYAxisValues: "0",
            showXAxisValues: "1",
            bgAlpha: "100",
            bgColor: "#00000",
            canvasBgColor: "#000",
            canvasBorderThickness: "0",
            numDivLines: "0",
            labelDisplay: "auto",
            showValues: 0,
            canvasPadding: 10,
            captionFontColor: "#FFFF",
          },
          data,
          xAxis: {
            labelDisplay: "rotate", // Rotate X-axis labels
            slantLabels: "2", // Specify the rotation angle (1 = 45 degrees, 2 = 90 degrees, etc.)
          },
        },
      };
    }
  }, [plots]);

  return (
    <div
      style={{
        transform: "scale(0.95,1.26)",
        position: " absolute",
        top: " 30px",
        left: " -18px",
        height: " 345px",
        background: "#000",
      }}
    >
      <FusionCharts {...chartConfigs} />
    </div>
  );
}

export default PM25Graph;
