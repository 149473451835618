import * as React from "react";
import { useMsal } from "@azure/msal-react";

//Material
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";

//Assests
import clock from "../../assets/clock.svg";
import logobg from "../../assets/logobg.svg";

//Style
import GetStyles from "./style";

const LinkExpired = () => {
  const styles = GetStyles();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const salesPersonEmail = localStorage.getItem("salesPersonEmail");
  return (
    <Grid container style={styles.link_expired_container}>
      <img src={logobg} alt="camfil" style={styles.bg} />
      <Grid item md={12} lg={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          lg={12}
          sm={12}
          style={styles.clock_container}
        >
          <img src={clock} alt="clock_timer" />
        </Grid>
        <Grid item md={12} lg={12} xs={12} sm={12}>
          <Typography style={styles.expired_heading} className="allocated-text">
            Your Allocated Access Duration Is Over.
          </Typography>
        </Grid>
        <Box style={styles.expired_content}>
          Your temporary access link has expired, please contact <br /> your
          sales representative for an extension.
        </Box>
      </Grid>
      <Grid
        item
        md={12}
        lg={12}
        xs={12}
        style={styles.expired_contact_container}
      >
        <Box className="Contact-header" style={styles.contact}>
          Contact Your Respective Sales Person At:
        </Box>
        <Box style={styles.email}>
          {activeAccount?.idTokenClaims?.tid ==
          "ba9d205c-25f9-4b1f-9faa-519bd940802b"
            ? localStorage?.getItem("contactEmail") ||
              activeAccount?.idTokenClaims?.email
            : salesPersonEmail && salesPersonEmail != "Not set"
            ? salesPersonEmail
            : "myGTexpert@Camfil.com"}
        </Box>
      </Grid>
    </Grid>
  );
};
export default LinkExpired;
