export const chooseRequestLccoptions = [
  { value: "Select One", label: "Select One" },
  { value: "PG Baseload", label: "PG Baseload" },
  { value: "PG Peaker", label: "PG Peaker" },
  { value: "O&G Refinery", label: "O&G Refinery" },
  { value: "O&G Offshore", label: "O&G Offshore" },
  { value: "O&G Pipeline", label: "O&G Pipeline" },
];

export const Currencyoptions = [
  { value: "USD", label: "USD" },
  { value: "EURO", label: "EURO" },
];

export const OfflineCompressoroptions = [
  { value: "[USD/wash]", label: "[USD/wash]" },
  { value: "[EURO/wash]", label: "[EURO/wash]" },
];
export const TaxRateoptions = [
  { value: "[USD/Tonne]", label: "[USD/Tonne]" },
  { value: "[EURO/Tonne]", label: "[EURO/Tonne]" },
];

export const PriceSoldoptions = [
  { value: "[USD/MWh]", label: "[USD/MWh]" },
  { value: "[EURO/MWh]", label: "[EURO/MWh]" },
];

export const CostOfFueloptions = [
  { value: "USD/MJ", label: "USD/MJ" },
  { value: "USD/MMBtu", label: "USD/MMBtu" },
  { value: "EURO/MJ", label: "EURO/MJ" },
  { value: "EURO/MMBtu", label: "EURO/MMBtu" },
];

export const Unitoptions = [
  { value: "Metric", label: "Metric" },
  { value: "Imperial", label: "Imperial" },
];

export const TurbineHeatoptions = [
  { value: "kJ/kWh", label: "kJ/kWh" },
  { value: "Btu/kWh", label: "Btu/kWh" },
];

export const TotalSystemoptions = [
  {
    value: "m3/h",
    label: (
      <span>
        m<sup>3</sup>/h
      </span>
    ),
  },
  { value: "CFM", label: "CFM" },
];

export const LccPeriodoptions = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
];

export const Maintenanceoptions = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
];

export const WaterWashoptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
];

export const Timeloadoptions = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "40", label: "40" },
  { value: "50", label: "50" },
  { value: "60", label: "60" },
  { value: "70", label: "70" },
  { value: "80", label: "80" },
  { value: "90", label: "90" },
  { value: "100", label: "100" },
];

export const PartLoadoptions = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "40", label: "40" },
  { value: "50", label: "50" },
  { value: "60", label: "60" },
  { value: "70", label: "70" },
  { value: "80", label: "80" },
  { value: "90", label: "90" },
  { value: "100", label: "100" },
];
