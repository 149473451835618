export const deserializeOptimalSolutionLccData = (filters) => {
	const data = {
		numberOfStages: 1,
		data: [],
	};
	if (filters) {
		for (const item of filters) {
			if (item["Filter Type"] !== "None") {
				data.data.push({
					name: item.Filter,
					filterType: item["Filter Type"],
					filterEfficiency: item["Rating"],
					sizeConstraints: item["Size"],
				});
			}
		}
		data.numberOfStages = data.data.length;
	}
	return data;
};

export const deserializeCurrentSolutionLccData = (filters) => {
	const data = {
		numberOfStages: 1,
		data: [],
	};
	if (filters) {
		for (const item of filters) {
			if (item["Filter Type"] !== "None") {
				data.data.push({
					name: item.Filter,
					filterType: item["Filter Type"],
					filterEfficiency: item["Rating"],
					noOfFilters: 0,
				});
			}
		}
		data.numberOfStages = data.data.length;
	}
	return data;
};

export const deserializeOptimalSolutionWithSizeLccData = (filters) => {
	const data = {
		numberOfStages: 1,
		data: [],
	};
	if (filters) {
		for (const item of filters) {
			if (item["Filter Type"] !== "None") {
				data.data.push({
					name: item.Filter,
					filterType: item["Filter Type"],
					filterEfficiency: item["Rating"],
					sizeConstraints: item["Size"],
				});
			}
		}
		data.numberOfStages = data.data.length;
	}
	return data;
};

export const deserializeLccManufacturer = (data) => {
	let result = [];
	if (data) {
		result = data.map((item) => ({ label: item.Manufacturer, value: item.Manufacturer }));
	}
	result.unshift({ value: "Select One", label: "Select One" });
	return result;
};

export const deserializeLccTurbines = (data) => {
	let result = [];
	if (data) {
		result = data.map((item) => ({ label: item["Gas Turbine"], value: item["Gas Turbine"] }));
	}
	result.unshift({ value: "Select One", label: "Select One" });
	return result;
};
