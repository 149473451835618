const routePaths = {
  DASHBOARD: "/",
  HOME: "/home",
  NOT_FOUND_PAGE: "/404",
  REQUEST_LLC: "/request_full_llc",
  // LOGIN: "/login",
  SITE_REPORT: "/site_environment_report",
  CONTACT_SALES: "/contact_sales",
  REDIRECT: "/microsoft_redirect",
};

export default routePaths;
