import React from "react";
import PropTypes from "prop-types";

//Material
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

//Style
import { StyledInput } from "./style";

const StandardTextField = React.forwardRef(
	(
		{
			ariaLabel,
			type,
			value,
			variant,
			onSearch,
			onChange,
			showSearchIcon,
			placeholder,
			disabled,
			name,
			...props
		},
		ref
	) => {
		return (
			<StyledInput
				type={type}
				value={value}
				name={name}
				variant={variant}
				onChange={onChange}
				disabled={disabled}
				autoComplete="off"
				inputProps={{
					style: {
						fontSize: "15px",
						fontWeight: "600px",
						fontFamily: "Inter",
						lineHeight: "24px",
					},
					ariaLabel,
					...props.inputProps,
				}}
				inputRef={ref}
				placeholder={placeholder}
				endAdornment={
					showSearchIcon ? (
						<InputAdornment position="end" onClick={onSearch}>
							<SearchIcon />
						</InputAdornment>
					) : null
				}
				{...props}
			/>
		);
	}
);

export default StandardTextField;

StandardTextField.propTypes = {
	onSearch: PropTypes.func,
	showSearchIcon: PropTypes.bool,
	placeholder: PropTypes.string,
};

StandardTextField.defaultProps = {
	inputProps: {},
};
