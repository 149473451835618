export const selectedTabDeserialize = (tab) => {
	switch (tab) {
		case 0:
			return "Static";
		case 1:
			return "Pulse";
		case 2:
			return "None";
		default:
			return "Static";
	}
};

export const serializeSelectedTab = (type) => {
	switch (type) {
		case "Static":
			return 0;
		case "Pulse":
			return 1;
		case "None":
			return 2;
		default:
			return 0;
	}
};
