import { Button, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBackButton = styled(Button)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
  width: "311px",
  height: "57px",
  fontSize: "1.2rem",
  fontWeight: 700,
  color: theme.palette.mode !== "dark" ? "white" : "black",
  fontFamily: "Inter",
  textTransform: "Capitalize",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
    borderColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
    boxShadow: "none",
  },
}));

const GetStyles = () => {
  const theme = useTheme();
  return {
    pageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "calc(100vh - 70px)",
      backgroundColor: theme.palette.mode !== "dark" ? "#edf0f9" : "#121212",
      overflow: "hidden",
      position: "relative",
    },
    link_expired_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
    },
    clock_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    expired_heading: {
      fontWeight: 700,
      fontSize: "3vw",
      textAlign: "center",
      fontFamily: "Inter",
    },
    expired_content: {
      color: theme.palette.mode !== "dark" ? "#6B6B6B" : "#D4D4D4",
      fontSize: "1.23vw",
      fontWeight: 400,
      fontFamily: "Inter",
      textAlign: "center",
    },
    button_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "20px",
      textTransform: "lower-case",
    },
    buttonvar: {
      fontSize: "14px",
      textTransform: "none",
      fontFamily: "Inter",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
        borderColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
        boxShadow: "none",
      },
    },
    bg: {
      width: "550px",
      height: "480px",
      position: "absolute",
      top: "-155px",
      right: "-193px",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
  };
};

export default GetStyles;
