import React from "react";
import PropTypes from "prop-types";

//Material
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//Style
import { StyledSelect } from "./style";
import { useTheme } from "@mui/material";

function OutlineDropdown({
  option,
  width,
  name,
  value,
  id,
  onChange,
  ariaLabel,
  ...props
}) {
  const theme = useTheme();
  return (
    <StyledSelect
      value={value}
      onChange={onChange}
      IconComponent={KeyboardArrowDownIcon}
      width={width}
      id={id}
      name={name}
      {...props}
      inputProps={{
        style: {
          fontSize: "15px",
          fontWeight: 600,
          fontFamily: "Inter",
          lineHeight: "24px",
        },
        ariaLabel,
      }}
      MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: theme.palette.mode === "dark" ? "black" : "white",
            color: theme.palette.mode === "dark" ? "white" : "black",
          },
        },
      }}
    >
      {option.map((opt) => (
        <MenuItem key={opt.value} value={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

export default OutlineDropdown;

OutlineDropdown.propTypes = {
  customBackgroundColor: PropTypes.string,
  option: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  width: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
