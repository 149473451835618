import { styled } from "@mui/material/styles";

// Mui
import { Popper, TextField, autocompleteClasses } from "@mui/material";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  borderBottom: "1px solid #2c2d2d",
  fontSize: "12px",
  lineHeight: "24px",
  fontWeight: "500px",
  fontFamily: "Inter",
  color: theme.palette.mode == "dark" ? "white" : "black",
  input: {
    color: theme.palette.mode == "dark" ? "white" : "black",
  },
  "& .Input": { color: theme.palette.mode == "dark" ? "white" : "black" },
  "& input": { padding: "0.75rem !important" },
  "& .Mui-focused::after": {
    borderBottom: `2px solid ${
      theme.palette.mode !== "dark"
        ? "#008c64 !important"
        : "#31FFCD !important"
    }`,
  },
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    background: theme.palette.mode != "dark" ? "white" : "black",
    color: theme.palette.mode == "dark" ? "white" : "black",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
}));
const style = {
  adornment: {
    position: "absolute",
    right: "0px",
    border: "2px",
    cursor: "pointer",
  },
  propsContainer: { color: "white" },
};
export default style;
