import { useTheme } from "@mui/material/styles";

const GetStyles = () => {
  const theme = useTheme();
  return {
    mainContainer: {
      marginTop: "32px",
      mx: "64px",
      md: {
        mx: "12px",
      },
    },
    filterName: {
      fontSize: "18px",
      fontWeight: "700",
      textAlign: "center",
      color: theme.palette.mode !== "dark" ? "black" : "white",
    },
    loaderContainer: {
      width: "100%",
      height: "80vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    cardsContainer: {
      padding: "0px",
      marginRight: "0px",
      marginBottom: "10px",
    },
    mainGrid: {
      width: {
        lg: "98% !important",
      },
      background: theme.palette.mode !== "dark" ? "#FFFFFF" : "#191c1d",
      borderRadius: "3px",
      padding: "39px 14px",
    },
    imageCards: {
      color: "red",
      padding: "10px",
      height: "239px",
    },
    imageCards1: {
      color: "red",
      padding: "0 1.5rem",
      height: "238px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    imageCards2: {
      padding: "24px",
      height: "268px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    subGrid: {
      background: theme.palette.mode !== "dark" ? "#FFFFFF" : "#191c1d",
      borderRadius: "3px",
      padding: "39px 14px",
      width: {
        lg: "98% !important",
      },
      marginTop: "20px",
    },
    subGridGraps: {
      background: theme.palette.mode !== "dark" ? "#FFFFFF" : "#191c1d",
      borderRadius: "3px",
      padding: "28px 14px",
      marginTop: "10px",
    },
    chartGrid: {
      color: "red",
      background: theme.palette.mode !== "dark" ? "#FFFFFF" : "#191c1d",
      borderRadius: "3px",
    },
    chartGridTwo: {
      padding: "0.5rem 1rem",
      marginBottom: "10px",
      background: theme.palette.mode !== "dark" ? "#FFFFFF" : "#191c1d",
      borderRadius: "3px",
      opacity: "0.9",
      height: "683px",
    },
    valueRatingGraphs: {
      color: theme.palette.mode !== "dark" ? "black" : "white",
      borderLeft: "1px solid rgb(92 87 87 / 50%)",
    },
    prefilterText: {
      color: theme.palette.mode !== "dark" ? "black" : "white",
      fontSize: "18px !important",
      fontWeight: 600,
    },
    prefiltertextContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      pb: "16px",
    },
    chartTitleContainer: {
      display: "flex",
    },
    chartTitle: {
      fontSize: "20px !important",
      fontWeight: "600 !important",
      color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
      marginRight: "7px",
      marginLeft: "10px",
      position: "relative",
    },
    chartTitlePosition: {
      position: "absolute",
      zIndex: "12",
    },
    optimalSolution: {
      borderRadius: "9px",
      border: `2px solid ${
        theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
      }`,
      width: "80%",
      margin: "2.5rem auto 1.5rem auto",
      padding: "12px 20px",
    },
    optimalSolutionTag: {
      fontWeight: 600,
      fontSize: "13px !important",
      color: theme.palette.mode !== "dark" ? "#000" : "#fff",
    },
    ratingLabel: {
      fontSize: "20px",
      fontFamily: "Inter",
      fontWeight: 700,
      lineHeight: "28px",
      textAlign: "left",
      paddingLeft: "25px",
      color: theme.palette.mode !== "dark" ? "#008c64 !important" : "#31FFCD",
    },
    TypographyContainer: {
      marginBottom: "-13px",
      marginTop: "1px",
      marginLeft: "30px",
      textAlign: "center",
      fontWeight: 600,
      fontSize: "18px",
      color: theme.palette.mode !== "dark" ? "black" : "white",
    },
    InfoContainer: {
      bottom: "0px",
      left: "56%",
      zIndex: 10,
      mt: "10px",
      position: "absolute",
      width: "100%",
      transform: "translate(-50%,12%)",
    },
    CO2Container: {
      marginBottom: "-23px",
      textAlign: "center",
      fontWeight: 600,
      fontSize: "18px",
      color: theme.palette.mode !== "dark" ? "black" : "white",
    },

    TonnesContainer: {
      bottom: "6px",
      left: "49%",
      zIndex: 10,
      position: "absolute",
      width: "100%",
      transform: "translate(-50%,12%)",
    },
    PowerOutputStyle: {
      mb: 1,
      // marginTop: "-67px",
      textAlign: "center",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "Inter",
      color: "#bdbdbd",
    },
    InfoTextStyle: {
      mb: 1,
      mt: 1,
      textAlign: "center",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "18px",
      color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
    },
    TonnesStyle: {
      // mb: 2,
      textAlign: "center",
      fontWeight: 500,
      fontSize: "12px",
      color: "#D4D4D4",
      fontFamily: "Inter",
    },
    bg: {
      width: "381px",
      height: "314px",
      position: "absolute",
      top: "37px",
      right: "32%",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
    savingsImageContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "65px",
      transform: "scale(1.5)",
    },
    Co2InfoIcon: {
      margin: "5px 0 0 5px",
    },
  };
};
export default GetStyles;
