import axios from "axios";

export const getCountry = async (latLng) => {
  // console.log("Latitude : ", latLng.lat);
  // console.log("Longitude : ", latLng.lng);
  if (latLng.lat === "" || latLng.lng === "") {
    alert("Please provide both latitude and longitude.");
    return;
  }
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${process.env.REACT_APP_MAPS_API_KEY}`;

  let response = await fetch(apiUrl);
  const data = await response.json();

  if (data.status === "OK") {
    for (const result of data.results) {
      for (const component of result.address_components) {
        if (component.types.includes("country")) {
          return component.long_name;
        }
      }
    }
  }
};

export const fetchLatLngFromSite = async (searchQuery) => {
  try {
    const apiKey = process.env.REACT_APP_MAPS_API_KEY;
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        searchQuery
      )}&key=${apiKey}`
    );
    const location = response.data.results[0].geometry.location;
    const result = response.data.results[0];

    let city = '';
    let country = '';

    // Loop through address components to find city and country
    result.address_components.forEach(component => {
      if (component.types.includes('locality')) {
        city = component.long_name; // Get the city name
      }
      if (component.types.includes('country')) {
        country = component.long_name; // Get the country name
      }
    });

    return { 
      lat: location.lat, 
      lng: location.lng, 
      placeName: result.formatted_address, 
      city_long: city, 
      country_long: country 
    };
  } catch (error) {
    console.error("Error fetching location:", error);
  }
};

export const isLabel = (chooseApplication) => {
  return chooseApplication === "Select One";
};
