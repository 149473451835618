import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell)(
  ({ theme, isFirstRow, isFirstChild }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: isFirstRow
        ? theme.palette.mode !== "dark"
          ? "#008c64"
          : "#31FFCD"
        : theme.palette.mode !== "dark"
        ? "#e9fff8"
        : "#122f28",
      color: isFirstRow
        ? theme.palette.mode !== "dark"
          ? "#FFF"
          : "#0D0D0D"
        : theme.palette.mode !== "dark"
        ? "#008c64"
        : "#31FFCD",
      Width: "100%",
      border: `1px solid ${
        theme.palette.mode !== "dark" ? "#c9c6d8" : "#43404D"
      }`,
      fontSize: "17px",

      fontWeight: 700,
      lineHeight: "22.1px",
      borderBottom: `1px solid ${
        theme.palette.mode !== "dark" ? "#c9c6d8" : "#43404D"
      }`,
    },
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: isFirstChild
        ? theme.palette.mode !== "dark"
          ? "#edf0f9"
          : "#282a30"
        : theme.palette.mode !== "dark"
        ? "#fff"
        : "#18191a ",
      border: `1px solid ${
        theme.palette.mode !== "dark" ? "#c9c6d8" : "#43404D"
      }`,
      color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000",
      fontSize: "15px",
      lineHeight: "19.5px",
      fontWeight: "500px",
    },
  })
);

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&.second-table-head-row": {
    border: "1px solid #000",
  },
}));
