//Material
import { InputBase } from "@mui/material";

// Styles
import { styled } from "@mui/system";

export const StyledTextarea = styled(InputBase)(({ width, theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "6px",
    border: "1px solid",
    width: width || "360px",
    fontSize: "17px",
    fontWeight: "500px",
    color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000",
    height: "150px",
    backgroundColor: theme.palette.mode !== "dark" ? "#edf0f9" : "#212526",
    borderColor: theme.palette.mode !== "dark" ? "#2c2d2d" : "#EBEBEB",
    gap: "12px",
    lineHeight: "24px",
    padding: "1rem",
    boxSizing: "border-box",
  },
  width: width || "360px",
}));
