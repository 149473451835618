import { Modal } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledModal = styled(Modal)(({ theme }) => ({
  "& .MuiBox-root": {
    border: "none",
    outline: "none",
    backgroundColor: theme.palette.mode !== "dark" ? "#ffffff" : "#282A30",
    color: "lightgrey",
    borderRadius: "10px",
    maxWidth: "400px",
    width: "fit-content",
  },
  "& .MuiTypography-root": {
    fontSize: "33px",
    fontFamily: "Inter",
    fontWeight: 500,
    lineHeight: "28px",
    color: theme.palette.mode !== "dark" ? "#333f48" : "white",
  },
  "& .MuiTypography-body1": {
    fontSize: "18px",
  },
  "& .MuiButtonBase-root": {
    display: "flex",
    width: "300px",
    marginLeft: "70px",
    backgroundColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
    color: theme.palette.mode !== "dark" ? "white" : "#333f48",

    textTransform: "Capitalize",
    marginTop: "40px",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
    },
  },
}));

const styles = {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "5%",
  },
  textContainer: {
    mt: 2,
    fontSize: "24px",
  },
  EpowerStyle: {
    color: "#f34f4f",
  },
  DpowerStyle: {
    color: "#f3944f",
  },
  CpowerStyle: {
    color: "#f3ed4f",
  },
  BpowerStyle: {
    color: "#f34f4f",
  },
  ApowerStyle: {
    color: "#4ff37d",
  },
  APowerStyle: {
    color: "#43aa76",
  },
};

export default styles;
