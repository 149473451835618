/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useMemo, useEffect } from "react";
import get from "lodash/get";
import moment from "moment";

//Material
import { Box, Typography, FormControl } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

//Comps
import OutlinedButton from "../Button";
import StandardTextField from "../StandardTextField";
import { useDispatch, useSelector } from "react-redux";

//Style
import styles, { StyledModal, StyledBox } from "./style";
import {
  setEmailSentModal,
  setInviteModal,
  showNotification,
  showToasterMessage,
} from "store/slices/modals_slice";
import { generateGuestToken, sendUuidEmail } from "api";
import { setInvitedCustomerDetails } from "store/slices/invited_customer_slice";
import { goToRoute } from "store/actions/navigationActions";
import routePaths from "routes/routePaths";

function InviteModal({ isOpen, handleClosePopup, ...modalProps }) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modals.inviteModal);
  const calRef = useRef(null);
  const initialFormState = {
    name: "",
    email: "",
    company: "",
    expirationDate: moment().add(14, "day").format("DD/MM/YYYY"),
  };
  const [formData, setFormData] = useState(initialFormState);
  const [accessCode, setAccessCode] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    return () => {
      setFormData(initialFormState);
      setAccessCode("");
    };
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name Field is required";
    }

    if (!formData.email) {
      newErrors.email = "Email Field is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter correct Email value";
    }

    if (!formData.company) {
      newErrors.company = "Company Field is required";
    }

    const currentDate = new Date();
    const expirationDate = new Date(formData.expirationDate);
    if (!formData.expirationDate || expirationDate <= currentDate) {
      newErrors.expirationDate = "Date must be greater than todays date";
    }

    return newErrors;
  };

  useEffect(() => {
    setErrors(validateForm());
  }, [formData]);

  const onClickGenerateCode = () => {
    if (Object.entries(errors)?.length !== 0) {
      console.log(errors);
      dispatch(
        showToasterMessage({
          message: Object.entries(errors)[0],
          toasterColor: "primary",
        })
      );
      return;
    }
    const payload = {
      customerName: formData.name,
      customerEmail: formData.email,
      customerCompany: formData.company,
      tokenExpireDate: formData.expirationDate
        ? moment(formData.expirationDate).format("YYYY-MM-DD")
        : moment(formData.expirationDate).add("days", 14).format("YYYY-MM-DD"),
    };
    generateGuestToken(payload)
      .then((res) => {
        console.log(res.data);
        dispatch(setInvitedCustomerDetails(get(res, "data.0", {})));
        setAccessCode(get(res, "data.0.Uuid", ""));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendCustomerEmail = () => {
    sendUuidEmail({
      to: formData.email,
      uuid: accessCode,
      companyName: formData.company,
      name: formData.name,
    })
      .then((res) => {
        dispatch(setEmailSentModal(true));
        dispatch(setInviteModal(false));
        dispatch(goToRoute(routePaths.DASHBOARD));
      })
      .catch((err) => {
        dispatch(
          showNotification({
            message:
              "Oops! Something went wrong, and the email was not sent. Please contact your sales representative for assistance.",
            severity: "error",
          })
        );
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const nameRegex = /^[a-zA-Z ]+$/;
    const companyRegex = /^[a-zA-Z0-9]+$/;
    // if (name === "expirationDate") {
    // 	const newVal = moment(value, "dd/mm/yyyy").format("YYYY-MM-DD");
    // 	setFormData((prevData) => ({
    // 		...prevData,
    // 		[name]: value,
    // 	}));
    // } else
    if (name === "name") {
      if (nameRegex.test(value) || !value) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "company") {
      if (companyRegex.test(value) || !value) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCloseModal = () => {
    setFormData(initialFormState);
    setAccessCode("");
    dispatch(setInviteModal(false));
  };

  const canGenerateCode = useMemo(() => {
    const { name, email, company, expirationDate } = formData;
    return Boolean(name && email && company && expirationDate);
  }, [formData]);

  return (
    <StyledModal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...modalProps}
    >
      <Box sx={styles.modalContainer}>
        <Box style={{ padding: "2rem 3rem" }}>
          <Box
            display="flex"
            mx="auto"
            mb="1rem"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Typography style={styles.invitationStyles}>
              Generate invitation code for external users
            </Typography>
          </Box>

          <Box sx={styles.containerStyles}>
            <StyledBox maxWidth="656px">
              <FormControl sx={{ width: "100%" }}>
                <Box sx={styles.nameContainer}>
                  <Typography style={{ ...styles.labelStyles, flex: 1 }}>
                    Name
                  </Typography>
                  <StandardTextField
                    onChange={handleInputChange}
                    name="name"
                    value={formData.name}
                    placeholder="Enter Name"
                    showSearchIcon={false}
                    fullWidth
                    type="text"
                    sx={styles.textStyles}
                  />
                </Box>

                <Box sx={styles.emailContainer}>
                  <Typography style={{ ...styles.labelStyles, flex: 1 }}>
                    Email
                  </Typography>
                  <StandardTextField
                    onChange={handleInputChange}
                    name="email"
                    value={formData.email}
                    placeholder="Enter Email"
                    showSearchIcon={false}
                    fullWidth
                    type="email"
                    sx={styles.textStyles}
                  />
                </Box>

                <Box sx={styles.companyContainer}>
                  <Typography style={{ ...styles.labelStyles, flex: 1 }}>
                    Company
                  </Typography>
                  <StandardTextField
                    onChange={handleInputChange}
                    name="company"
                    placeholder="Enter Company"
                    value={formData.company}
                    showSearchIcon={false}
                    fullWidth
                    type="text"
                    sx={styles.textStyles}
                  />
                </Box>

                <Box sx={styles.dateContainer}>
                  <Typography style={{ ...styles.labelStyles, flex: 1 }}>
                    Expiration Date
                  </Typography>
                  <StandardTextField
                    onChange={handleInputChange}
                    name="expirationDate"
                    clasName="expirationDate"
                    value={formData.expirationDate}
                    placeholder="Enter Expiration Date"
                    showSearchIcon={false}
                    ref={calRef}
                    fullWidth
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => {
                            calRef.current?.showPicker();
                          }}
                        >
                          <CalendarMonthOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={styles.textStyles}
                  />
                </Box>
                <OutlinedButton
                  variant="outlined"
                  customcolor="#080809"
                  label="Generate Code"
                  onClick={onClickGenerateCode}
                  customBackgroundColor="#31FFCD"
                  customBorderColor="#31FFCD"
                  disabled={!canGenerateCode}
                  width="100%"
                  sx={{
                    fontSize: "18px",
                    width: "423px",
                    height: "55px",
                    margin: "0 auto",
                    alignItems: "center",
                    marginBottom: "20px",
                    borderRadius: "6px",
                    "@media (max-width: 768px)": {
                      width: "100%",
                    },
                  }}
                />

                <Box sx={styles.accessCodeContainer}>
                  <Typography style={{ ...styles.labelStyles, flex: 1 }}>
                    Access Code
                  </Typography>
                  <StandardTextField
                    disabled={!Boolean(accessCode)}
                    name="accessCode"
                    value={accessCode}
                    showSearchIcon={false}
                    fullWidth
                    type="text"
                    sx={styles.textStyles}
                  />
                </Box>

                <OutlinedButton
                  variant="outlined"
                  customcolor="#080809"
                  label="Send Code to Customer"
                  onClick={sendCustomerEmail}
                  customBackgroundColor="#31FFCD"
                  customBorderColor="#31FFCD"
                  disabled={!Boolean(accessCode)}
                  width="100%"
                  sx={styles.buttonStyle}
                />
              </FormControl>
            </StyledBox>
          </Box>
        </Box>
      </Box>
    </StyledModal>
  );
}

export default InviteModal;
