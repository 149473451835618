import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const Container = styled(Box)({
  width: "100%",
  margin: "0 auto",
});

const GetStyles = () => {
  const theme = useTheme();
  return {
    mapContainer: {
      width: "100%",
      mb: 2,
      mt: 2,
      position: "relative",
      "&::before": {
        content: "''",
        width: "100%",
        position: "absolute",
        display: "block",
        boxShadow: "none",
        zIndex: 5,
        bottom: "0px",
      },
      "&::after": {
        content: "''",
        width: "100%",
        position: "absolute",
        display: "block",
        boxShadow:
          theme.palette.mode === "dark"
            ? "0px 0px 20px 20px rgba(0, 0, 0, 0.7)"
            : "none",
        zIndex: 5,
        bottom: "0px",
      },
    },
    label: {
      fontSize: "19px",
      fontWeight: 500,
      fontFamily: "Inter",
      minWidth: "fit-content",
      lineHeight: "22px",
      marginRight: "80px",
      marginBottom: "15px",
      [theme.breakpoints.down("md")]: {
        marginRight: "20px",
      },
      color: theme.palette.mode === "dark" ? "#FFF" : "#000",
    },
    inputContainer: {
      height: "100%",
      display: "flex",
      alignItems: "end",
    },
    labelStyles: {
      fontWeight: 700,
      fontFamily: "Inter",
      fontSize: "2.9vw",
      marginBottom: "2.5rem",
      marginTop: "2.5rem",
      color: theme.palette.mode === "dark" ? "#FFF" : "#000",
    },
    bg: {
      width: "550px",
      height: "480px",
      position: "absolute",
      bottom: "-200px",
      right: "-250px",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
    gridItem: {
      paddingTop: "30px !important",
    },
  };
};

export default GetStyles;
