import img5661 from "assets/filter_image/5661.jpg";
import img5662 from "assets/filter_image/5662.jpg";
import img5706 from "assets/filter_image/5706.jpg";
import img5768 from "assets/filter_image/5768.jpg";
import img5769 from "assets/filter_image/5769.jpg";
import img5770 from "assets/filter_image/5770.jpg";
import img5771 from "assets/filter_image/5771.jpg";
import img5772 from "assets/filter_image/5772.jpg";
import img5816 from "assets/filter_image/5816.jpg";
import img5839 from "assets/filter_image/5839.jpg";
import img5840 from "assets/filter_image/5840.jpg";
import img5841 from "assets/filter_image/5841.jpg";
import img5842 from "assets/filter_image/5842.jpg";
import img5843 from "assets/filter_image/5843.jpg";
import img5844 from "assets/filter_image/5844.jpg";
import img5845 from "assets/filter_image/5845.jpg";
import img5846 from "assets/filter_image/5846.jpg";
import img5847 from "assets/filter_image/5847.jpg";
import img5848 from "assets/filter_image/5848.jpg";
import img5849 from "assets/filter_image/5849.jpg";
import img5850 from "assets/filter_image/5850.jpg";
import img5851 from "assets/filter_image/5851.jpg";
import img5852 from "assets/filter_image/5852.jpg";
import img5853 from "assets/filter_image/5853.jpg";
import img5854 from "assets/filter_image/5854.jpg";
import img5884 from "assets/filter_image/5884.jpg";
import img6088 from "assets/filter_image/6088.jpg";
import img6089 from "assets/filter_image/6089.jpg";
import img6090 from "assets/filter_image/6090.jpg";
import img6094 from "assets/filter_image/6094.jpg";
import img6097 from "assets/filter_image/6097.jpg";
import img6136 from "assets/filter_image/6136.jpg";
import img6137 from "assets/filter_image/6137.jpg";
import img6166 from "assets/filter_image/6166.jpg";
import img6201 from "assets/filter_image/6201.jpg";
import img6203 from "assets/filter_image/6203.jpg";
import img6206 from "assets/filter_image/6206.jpg";
import img6207 from "assets/filter_image/6207.jpg";
import img6219 from "assets/filter_image/6219.jpg";
import img6278 from "assets/filter_image/6278.jpg";
import img6279 from "assets/filter_image/6279.jpg";
import img6291 from "assets/filter_image/6291.jpg";
import img6292 from "assets/filter_image/6292.jpg";
import img6293 from "assets/filter_image/6293.jpg";
import img6307 from "assets/filter_image/6307.jpg";
import img6308 from "assets/filter_image/6308.jpg";
import img6309 from "assets/filter_image/6309.jpg";
import img6317 from "assets/filter_image/6317.jpg";
import img6328 from "assets/filter_image/6328.jpg";
import img6386 from "assets/filter_image/6386.jpg";
import img6396 from "assets/filter_image/6396.jpg";
import img6397 from "assets/filter_image/6397.jpg";
import img6398 from "assets/filter_image/6398.jpg";
import img6400 from "assets/filter_image/6400.jpg";
import img6401 from "assets/filter_image/6401.jpg";
import img6402 from "assets/filter_image/6402.jpg";
import img6403 from "assets/filter_image/6403.jpg";
import cloud1 from "assets/co2_clouds/1.svg";
import cloud2 from "assets/co2_clouds/2.svg";
import cloud3 from "assets/co2_clouds/3.svg";
import cloud4 from "assets/co2_clouds/4.svg";
import cloud5 from "assets/co2_clouds/5.svg";
import cloud6 from "assets/co2_clouds/6.svg";

export const options = [
  { value: "Select Filter Depth", label: "Select Filter Depth" },
  { value: "PG Baseload", label: "PG Baseload" },
  { value: "PG Peaker", label: "PG Peaker" },
  { value: "O&G Refinery", label: "O&G Refinery" },
  { value: "O&G Offshore", label: "O&G Offshore" },
  { value: "O&G Pipeline", label: "O&G Pipeline" },
];

export const convertRatingToValue = (string = "") => {
  return parseInt(string.slice(1, string.length));
};

export const convertValueToRating = (value) => {
  if (value) return "T" + value;
  else return "T0";
};

export const getFilterImage = (id) => {
  switch (id) {
    case "5661":
      return img5661;
    case "5662":
      return img5662;
    case "5706":
      return img5706;
    case "5768":
      return img5768;
    case "5769":
      return img5769;
    case "5770":
      return img5770;
    case "5771":
      return img5771;
    case "5772":
      return img5772;
    case "5816":
      return img5816;
    case "5839":
      return img5839;
    case "5840":
      return img5840;
    case "5841":
      return img5841;
    case "5842":
      return img5842;
    case "5843":
      return img5843;
    case "5844":
      return img5844;
    case "5845":
      return img5845;
    case "5846":
      return img5846;
    case "5847":
      return img5847;
    case "5848":
      return img5848;
    case "5849":
      return img5849;
    case "5850":
      return img5850;
    case "5851":
      return img5851;
    case "5852":
      return img5852;
    case "5853":
      return img5853;
    case "5854":
      return img5854;
    case "5884":
      return img5884;
    case "6088":
      return img6088;
    case "6089":
      return img6089;
    case "6090":
      return img6090;
    case "6094":
      return img6094;
    case "6097":
      return img6097;
    case "6136":
      return img6136;
    case "6137":
      return img6137;
    case "6166":
      return img6166;
    case "6201":
      return img6201;
    case "6203":
      return img6203;
    case "6206":
      return img6206;
    case "6207":
      return img6207;
    case "6219":
      return img6219;
    case "6278":
      return img6278;
    case "6279":
      return img6279;
    case "6291":
      return img6291;
    case "6292":
      return img6292;
    case "6293":
      return img6293;
    case "6307":
      return img6307;
    case "6308":
      return img6308;
    case "6309":
      return img6309;
    case "6317":
      return img6317;
    case "6328":
      return img6328;
    case "6386":
      return img6386;
    case "6396":
      return img6396;
    case "6397":
      return img6397;
    case "6398":
      return img6398;
    case "6400":
      return img6400;
    case "6401":
      return img6401;
    case "6402":
      return img6402;
    case "6403":
      return img6403;
    case "Generic-1":
      return img5816;
    case "Generic-2":
      return img5816;
    case "Generic-3":
      return img5816;
    case "Generic-4":
      return img5816;
    case "Generic-5":
      return img6403;
    case "Generic-6":
      return img6307;
    case "Generic-7":
      return img6307;
    case "Generic-8":
      return img6307;
    case "Generic-9":
      return img6307;
    case "Generic-10":
      return img5770;
    case "Generic-11":
      return img5770;
    case "Generic-12":
      return img5770;
    case "Generic-13":
      return img5770;
    case "Generic-14":
      return img5770;
    case "Generic-15":
      return img5770;
    case "Generic-16":
      return img5770;

    default:
      return null; // Return null or handle the case when the ID doesn't match any import
  }
};

export const getNumberOfClouds = (co2Saving) => {
  if (co2Saving > 25000) return cloud1;
  if (co2Saving > 20000) return cloud2;
  if (co2Saving > 15000) return cloud3;
  if (co2Saving > 10000) return cloud4;
  if (co2Saving > 5000) return cloud5;
  if (co2Saving <= 5000) return cloud6;
};
