import React, { useRef, useEffect, useState } from "react";
import ReactFusioncharts from "react-fusioncharts";

const GraphChart = ({ dynamicWidth, dataSource, onGraphRender }) => {
  const chartRef = useRef();
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    setInitialRender(false);
    if (!initialRender) {
      setTimeout(() => {
        onGraphRender(chartRef.current);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGraphRender]);

  return (
    <div ref={chartRef}>
      <ReactFusioncharts
        type="stackedcolumn3d"
        width={dynamicWidth}
        height="485px"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
};

export default GraphChart;
