import emailInstance from "./axios-instances/email";

export function sendUuidEmail(payload) {
  return emailInstance.post(`/api/send-uuid-email`, payload);
}

export function sendRequestLLCEmail(payload) {
  return emailInstance.post(`/api/send-pdf-and-xml-email`, payload);
}

export function sendRepresentativeEmail(payload) {
  return emailInstance.post(`/api/send-representative-email`, payload);
}
