import { styled } from "@mui/material/styles";
import { Modal } from "@mui/material";

export const StyledModal = styled(Modal)(({ theme }) => ({
  "& .MuiBox-root": {
    border: "none",
    outline: "none",
    backgroundColor: theme.palette?.mode !== "dark" ? "#ffffff" : "#282A30",
    color: "lightgrey",
    borderRadius: "32px",
    width: "fit-content",
    // height: "500px",
    maxWidth: "600px",
    padding: "3rem",
  },
  "& .MuiTypography-root": {
    textAlign: "center",
    fontSize: "50px",
    fontFamily: "Inter",
    lineHeight: "normal",
    fontWeight: "400px",
    color: "#D4D4D4",
  },
  "& .MuiTypography-body1": {
    fontSize: "22px",
    lineHeight: "35px",
    fontFamily: "Inter",
    fontWeight: "400px",
    color: theme.palette?.mode !== "dark" ? "#898989" : "#D4D4D4",
  },
  "& .MuiButtonBase-root": {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette?.mode !== "dark" ? "#008c64" : "#31FFCD",
    color: "black",
    textTransform: "Capitalize",
    marginTop: "40px",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette?.mode !== "dark" ? "#008c64" : "#31FFCD",
    },
  },
}));

const styles = {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  buttonStyle: {
    fontWeight: "bold",
    maxWidth: "350px",
    width: "100px",
    margin: "2rem auto 0 auto",
  },
};

export default styles;
