import * as React from "react";

//Material
import { CircularProgress, Grid, useTheme, Typography } from "@mui/material";
import Box from "@mui/material/Box";

//Assests
import tick_square from "../../assets/tick_square.svg";
import tick_square_light from "../../assets/tick-sqaure-light.svg";
import logobg from "../../assets/logobg.svg";

//Style
import GetStyles, { StyledBackButton } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  showNotification,
  showToasterMessage,
} from "store/slices/modals_slice";
import { generatePDFReport } from "api";
import { useState } from "react";

// active account
import { useMsal } from "@azure/msal-react";

function LCCLinkExpired() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = GetStyles();
  const { site } = useSelector((state) => state.globalData);
  const [loading, setLoading] = useState(false);
  const generateReport = () => {
    setLoading(true);
    generatePDFReport(site, activeAccount?.name)
      .then(async (response) => {
        const pdfString = await response.data;
        const pdfBlob = new Blob([pdfString], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        setLoading(false);
      })
      .catch((err) => {
        dispatch(
          showNotification({
            message:
              "Oops! Something went wrong, and the report could not be generated. Please contact your sales representative for assistance.",
            severity: "error",
          })
        );
        setLoading(false);
      });
  };
  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress
          size={80}
          style={{
            color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
          }}
        />
        <Typography
          sx={{
            color: "white",
            fontSize: "24px",
            fontWeight: 600,
            marginTop: "16px",
          }}
        >
          Your report download is starting. Please enable pop-ups for this page.
          <br />
          The process may take a few minutes, so kindly avoid switching tabs.
        </Typography>
      </Box>
    );
  }

  return (
    <Box style={styles.pageContainer}>
      <img src={logobg} alt="camfil" style={styles.bg} />
      <Grid container style={styles.link_expired_container}>
        <Grid
          item
          md={12}
          xs={12}
          lg={12}
          sm={12}
          style={styles.clock_container}
        >
          <img
            src={
              theme.palette.mode !== "dark" ? tick_square_light : tick_square
            }
            alt="tick_square"
          />
        </Grid>
        <Grid
          item
          md={12}
          lg={12}
          sm={12}
          xs={12}
          style={{
            ...styles.expired_heading,
            color: theme.palette.mode !== "dark" ? "#000" : "#fff",
          }}
          className="estimation-header"
        >
          Your Request For A Full LCC Estimation <br /> Has Been Received.
        </Grid>
        <Grid
          item
          md={12}
          lg={12}
          sm={12}
          xs={12}
          style={styles.expired_content}
        >
          We will be in touch with you shortly with the estimation.
        </Grid>
        <Grid item md={12} lg={12} sm={12} style={styles.button_container}>
          <StyledBackButton
            sx={styles.buttonvar}
            variant="contained"
            onClick={generateReport}
          >
            Get Your Site Environment Report Now
          </StyledBackButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LCCLinkExpired;
