import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

// Comps
import GraphChart from "../../components/Graph";
import { useTheme } from "@mui/material";

const LccGraph = () => {
  const [dynamicWidth, setDynamicWidth] = useState("200px");
  const graphData = useSelector((state) => state.graph.dataset);
  const theme = useTheme();
  console.log(graphData, "graphData");
  const dataSource = useMemo(() => {
    let category = [];
    if (
      graphData &&
      graphData.length &&
      graphData[0].data &&
      graphData[0].data.length
    ) {
      if (graphData[0].data.length < 3) {
        category = [
          { label: "optimal" },
          { label: "current on-screen selection" },
        ];
      } else {
        category = [
          { label: "optimal" },
          { label: "optimal for existing configuration" },
          { label: "current on-screen selection" },
        ];
      }
    }
    return {
      chart: {
        yaxisname: "Amount (USD)",
        yAxisMaxValue: 7,
        showsum: "1",
        showvalues: "0",
        showBorder: "0",
        borderColor: theme.palette.mode === "dark" ? "#191c1d" : "#ffffff",
        usePlotGradientColor: "1",
        plotGradientColor: "#000",
        legendBorderThickness: "0",
        use3DLighting: 0,
        canvasbgColor: theme.palette.mode === "dark" ? "#191c1d" : "#ffffff",
        canvasbgAlpha: "0",
        canvasBorderThickness: "1",
        showAlternateHGridColor: "0",
        bgColor: theme.palette.mode === "dark" ? "#191c1d" : "#ffffff",
        divLineColor: theme.palette.mode === "dark" ? "#191c1d" : "#ffffff",
        legendNumRows: "4",
        legendNumColumns: "1",
        legendPosition: "top-right",
        interactiveLegend: "0",
        legendBgColor: theme.palette.mode === "dark" ? "#191c1d" : "#ffffff",
        legendbgalpha: "0",
        showZeroPlane: "0",
        zeroPlaneColor: theme.palette.mode === "dark" ? "#191c1d" : "#ffffff",
        zeroPlaneShowBorder: "0",
        zeroPlaneBorderColor:
          theme.palette.mode === "dark" ? "#191c1d" : "#ffffff",
        showCanvasBase: "0",
        canvasBgDepth: "0",
        theme: "fusion",
        valueFontSize: "14",
        valueFontColor: theme.palette.mode === "dark" ? "#FFF" : "#000",
        valueFontBold: 1,
        yAxisNameFontColor: theme.palette.mode === "dark" ? "#FFF" : "#000",
        labelFontColor: theme.palette.mode === "dark" ? "#FFF" : "#000",
        labelFontSize: "12",
        baseFontColor: theme.palette.mode === "dark" ? "#FFF" : "#000",
        baseFont: "Inter",
        plottooltext: "<b>$dataValue</b>",
        toolTipBgColor: theme.palette.mode === "dark" ? "#000" : "#FFF",
        showToolTip: 1,
        reverseLegend: "1",
      },
      categories: [
        {
          category,
        },
      ],
      dataset: graphData
        .map((i) => ({ ...i, color: i.data[0]?.color }))
        .reverse(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, theme.palette.mode]);

  useEffect(() => {
    setDynamicWidth("100%");
  }, []);

  const handleGraphRender = (graphElement) => {
    console.log(graphElement);
  };

  return (
    <div>
      <GraphChart
        dynamicWidth={dynamicWidth}
        dataSource={dataSource}
        onGraphRender={handleGraphRender}
      />
    </div>
  );
};

export default LccGraph;
