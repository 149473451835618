import React from "react";

//Material
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Slide } from "@mui/material";

//Style
import styles, { StyledModal } from "./style";

function EfficiencyPopup({ isOpen, handleClosePopup, title, description, buttonLabel, ...modalProps }) {

  return (
      <Box>
        <StyledModal
          TransitionComponent={Slide}
          open={isOpen}
          onClose={handleClosePopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          {...modalProps}
        >
          <Box sx={styles.modalContainer}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2,fontSize:"24px"}}>
              {description}
            </Typography>
            <Button  onClick={handleClosePopup} style={{fontWeight:"bold"}}>{buttonLabel}</Button>
          </Box>
        </StyledModal>
      </Box>

  );
}

export default EfficiencyPopup;
