import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const BootstrapInput = styled(InputBase)(({ width, theme }) => ({
  width,
  marginTop: "5px",
  fontFamily: "Inter",
  "& .MuiInputBase-input": {
    color: theme.palette.mode !== "dark" ? "#FFFFFF" : "#000000",
    justifyContent: "center",
    //padding: "8px 16px",
    fontSize: "15px",
    lineHeight: "24px",
    height: "34px",
    fontWeight: 600,
    fontFamily: "Inter",
  },
  "& .MuiSvgIcon-root": {
    color: "#31FFCD",
    position: "absolute",
    right: 0,
    border: "2px",
    paddingRight: "5px",
  },
}));
