import { useDispatch } from "react-redux";

const { goToRoute } = require("store/actions/navigationActions");

const ProtectedRoute = ({ isApproved, children }) => {
  const dispatch = useDispatch();
  if (!isApproved) {
    return dispatch(goToRoute("/access-over"));
  }
  return children;
};

export default ProtectedRoute;
