// utils
import { push, goBack } from "connected-react-router";
import { reverse } from "named-urls";

let goToRoute = function (path, params = {}, query = null) {
	return (dispatch) => {
		let routePath = reverse(path, params);
		if (query) {
			let search = new URLSearchParams(query);
			routePath = `${routePath}?${search.toString()}`;
		}
		dispatch(push(routePath));
	};
};

let setQuery = function (key, value) {
	return (dispatch, getState) => {
		let state = getState();
		let search = state.router.location.search;
		let searchParams = new URLSearchParams(search);
		searchParams.set(key, value);
		dispatch(
			push({
				search: searchParams.toString(),
			})
		);
	};
};
let setQueries = function (arr) {
	return (dispatch) => {
		let searchParams = new URLSearchParams();
		arr.forEach((i) => {
			searchParams.set(i.key, i.value);
		});
		dispatch(
			push({
				search: searchParams.toString(),
			})
		);
	};
};

let goToExternalRoute = function (baseURL, path = "", params = {}, query = null, newTab = false) {
	let routePath = path ? reverse(path, params) : reverse(params);
	if (query) {
		let search = new URLSearchParams(query);
		routePath = `${routePath}?${search.toString()}`;
	}
	let fullUrl = baseURL + routePath;

	if (newTab) {
		return window.open(fullUrl, "_blank");
	} else {
		window.location.href = fullUrl;
	}
};

let back = function () {
	return (dispatch) => dispatch(goBack());
};

export { goToRoute, setQuery, setQueries, goToExternalRoute, back };
