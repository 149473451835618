import { useTheme } from "@mui/material";
const GetStyles = () => {
  const theme = useTheme();
  return {
    link_expired_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "4%",
      color: theme.palette.mode != "dark" ? "black" : "white",
      overflow: "hidden",
      position: "relative",
      minHeight: "calc(100vh - 70px)",
    },
    clock_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    expired_heading: {
      fontWeight: 600,
      fontSize: "2vw",
      textAlign: "center",
      fontFamily: "Inter",
    },
    expired_content: {
      color: theme.palette.mode == "dark" ? "#D4D4D4" : "#6B6B6B",
      fontSize: "1.1vw",
      lineHeight: "33.6px",
      fontWeight: 500,
      fontFamily: "Inter",
      textAlign: "center",
    },
    expired_contact_container: {
      marginTop: "8%",
    },
    contact: {
      fontSize: "2vw",
      fontWeight: 600,
      textAlign: "center",
      fontFamily: "Inter",
      color: theme.palette.mode == "dark" ? "#DADADA" : "#323232",
    },
    email: {
      fontSize: "26px",
      fontWeight: 700,
      textAlign: "center",
      fontFamily: "Inter",
      color: theme.palette.mode == "dark" ? "#31FFCD" : "#008C64",
    },
    bg: {
      width: "550px",
      height: "480px",
      position: "absolute",
      top: "-155px",
      right: "-193px",
      transform: "translate(0%, 0%)",
      overflow: "hidden",
    },
  };
};

export default GetStyles;
