/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";

// Mui
import {
  InputAdornment,
  Autocomplete as MuiAutocomplete,
  useTheme,
} from "@mui/material";

// Styles
import styles, { StyledPopper, StyledTextField } from "./style";

// Assets
import SearchIcon from "@mui/icons-material/Search";

function Autocomplete(props) {
  const theme = useTheme();
  const [searchInput, setSearchInput] = useState("");
  const getFilteredAndSortedOptions = useMemo(() => {
    const uniqueArray = [...new Set(props.options)];
    return uniqueArray
      .filter((option) =>
        option.toLowerCase().includes(searchInput.toLowerCase())
      )
      .sort();
  }, [searchInput]);

  // Check if the current input matches a valid option
  const isSiteSelected = props.options.includes(searchInput);

  return (
    <MuiAutocomplete
      {...props}
      freeSolo={true}
      fullWidth
      PopperComponent={StyledPopper}
      options={getFilteredAndSortedOptions}
      onInputChange={(event, newInputValue) => {
        props.onInputChange && props.onInputChange(event, newInputValue);
        setSearchInput(newInputValue);
      }}
      renderInput={(params) => (
        <StyledTextField
          fullWidth
          {...params}
          placeholder={props?.placeholder}
          InputProps={
            params.InputProps
              ? {
                  ...params.InputProps,
                  sx: styles.propsContainer,
                  endAdornment: !isSiteSelected && (
                    <InputAdornment
                      sx={styles.adornment}
                      position="end"
                      onClick={() =>
                        props.onSearch && props.onSearch(props.value)
                      }
                    >
                      <SearchIcon
                        // color={
                        //   theme.palette.mode !== "dark" ? "#008c64" : "primary"
                        // }
                        style={{
                          color:
                            theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#31FFCD",
                        }}
                      />
                    </InputAdornment>
                  ),
                }
              : {
                  sx: styles.propsContainer,
                  endAdornment: (
                    <InputAdornment position="end" onClick={props?.onSearch}>
                      <SearchIcon
                        // color={
                        //   theme.palette.mode !== "dark" ? "#008c64" : "primary"
                        // }
                        style={{
                          color:
                            theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#31FFCD",
                        }}
                      />
                    </InputAdornment>
                  ),
                }
          }
          sx={styles.propsContainer}
          variant="standard"
          isKeyPressNeeded={props?.isKeyPressNeeded}
          onKeyDown={(e) => {
            if (props?.isKeyPressNeeded) {
              props.handleKeyDown(e);
            }
          }}
        />
      )}
    />
  );
}

Autocomplete.propTypes = {};

export default Autocomplete;
