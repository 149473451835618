import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSiteSelected: false,
  isDarkMode: true,
};

const flagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    setIsSiteSelected(state, action) {
      state.isSiteSelected = action.payload;
    },
    setIsDarkMode(state, action) {
      state.isDarkMode = action.payload;
    },
  },
});

export const { setIsSiteSelected, setIsDarkMode } = flagsSlice.actions;
export default flagsSlice.reducer;
