import get from "lodash/get";
import { convertRatingToValue } from "modules/Home/utils";
import {
  showNotification,
  showToasterMessage,
} from "store/slices/modals_slice";

const isNone = (filter) => {
  return get(filter, "Filter Type", "") === "None";
};

export const deserializeFilter = (filters, curr, dispatch) => {
  if (
    filters[0] &&
    filters[0].Stage === "No filters match all the constraints"
  ) {
    dispatch(
      showNotification({
        message:
          "The FAST simulator doesn’t support the requested configuration. Please request a FULL LCC if this is your current site configuration.",
        severity: "primary",
      })
    );
    return curr;
  }

  const stage1Min = get(curr, "stage1.minEfficiency");
  const stage2Min = get(curr, "stage2.minEfficiency");
  const stage1Max = get(curr, "stage1.maxEfficiency");
  const stage2Max = get(curr, "stage2.maxEfficiency");
  const stage1FilterDepth = get(curr, "stage1.filterDepth", "None");
  const stage2FilterDepth = get(curr, "stage2.filterDepth", "None");
  const stage3FilterDepth = get(curr, "stage3.filterDepth", "None");
  const finalFilters = {};
  const [stage1, stage2, stage3] = filters;
  if (isNone(stage1)) {
    finalFilters.stage1 = {
      filterType: "None",
      minEfficiency: stage1Min,
      maxEfficiency: stage1Max,
    };
  } else {
    finalFilters.stage1 = {
      filterType: get(stage1, "Filter Type", "None"),
      name: get(stage1, "Filter", ""),
      filterId: get(stage1, "FilterID", 6292),
      rating: convertRatingToValue(get(stage1, "Rating", "T1")),
      life: parseFloat(get(stage1, "Life", 0)),
      filterDepth: stage1FilterDepth,
      minEfficiency: stage1Min,
      maxEfficiency: stage1Max,
    };
  }
  if (isNone(stage2)) {
    finalFilters.stage2 = {
      filterType: "None",
      minEfficiency: stage2Min,
      maxEfficiency: stage2Max,
    };
  } else {
    finalFilters.stage2 = {
      filterType: get(stage2, "Filter Type", "None"),
      name: get(stage2, "Filter", ""),
      filterId: get(stage2, "FilterID", 6292),
      rating: convertRatingToValue(get(stage2, "Rating", "T1")),
      life: parseFloat(get(stage2, "Life", 0)),
      filterDepth: stage2FilterDepth,
      minEfficiency: stage2Min,
      maxEfficiency: stage2Max,
    };
  }
  if (isNone(stage3)) {
    finalFilters.stage2 = {
      filterType: "None",
    };
  } else {
    finalFilters.stage3 = {
      filterType: get(stage3, "Filter Type", "None"),
      name: get(stage3, "Filter", ""),
      filterId: get(stage3, "FilterID", 6292),
      rating: convertRatingToValue(get(stage3, "Rating", "T1")),
      life: parseFloat(get(stage3, "Life", 0)),
      filterDepth: stage3FilterDepth,
    };
  }
  return finalFilters;
};
