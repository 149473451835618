import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
// Store
import { history, store } from "./store";
//  Fonts
import "./assets/fonts/Roobert/Roobert-Bold.otf";
import "./assets/fonts/Roobert/Roobert-BoldItalic.otf";
import "./assets/fonts/Roobert/Roobert-Heavy.otf";
import "./assets/fonts/Roobert/Roobert-HeavyItalic.otf";
import "./assets/fonts/Roobert/Roobert-Light.otf";
import "./assets/fonts/Roobert/Roobert-LightItalic.otf";
import "./assets/fonts/Roobert/Roobert-Medium.otf";
import "./assets/fonts/Roobert/Roobert-MediumItalic.otf";
import "./assets/fonts/Roobert/Roobert-Regular.otf";
import "./assets/fonts/Roobert/Roobert-RegularItalic.otf";
import "./assets/fonts/Roobert/Roobert-SemiBold.otf";
import "./assets/fonts/Roobert/Roobert-SemiBoldItalic.otf";
import "./assets/fonts/sans/SourceSansPro-Black.otf";
import "./assets/fonts/sans/SourceSansPro-BlackIt.otf";
import "./assets/fonts/sans/SourceSansPro-Bold.otf";
import "./assets/fonts/sans/SourceSansPro-BoldIt.otf";
import "./assets/fonts/sans/SourceSansPro-ExtraLight.otf";
import "./assets/fonts/sans/SourceSansPro-ExtraLightIt.otf";
import "./assets/fonts/sans/SourceSansPro-It.otf";
import "./assets/fonts/sans/SourceSansPro-Light.otf";
import "./assets/fonts/sans/SourceSansPro-LightIt.otf";
import "./assets/fonts/sans/SourceSansPro-Regular.otf";
import "./assets/fonts/sans/SourceSansPro-Semibold.otf";
import "./assets/fonts/sans/SourceSansPro-SemiboldIt.otf";

// Comps
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import App from "./app";
import "./index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "utils/auth-token-utils/auth-config";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === "msal:loginSuccess" && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// Resolves charts dependancy
FusionCharts.options.license({
  key: process.env.REACT_APP_FUSION_KEY,
  creditLabel: false,
});
charts(FusionCharts);

const root = ReactDOM.createRoot(document.getElementById("root"));
const RootComponent = () => {
  return (
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            {/* <CssBaseline /> */}
            <App msalInstance={msalInstance} />
          </ConnectedRouter>
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  );
};
// Styles for root
document.getElementById("root").style.minHeight = "100Vh";
root.render(RootComponent());
