//Style
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";

export const StyledSelect = styled(Select)(({ theme }) => ({
  "&.Mui-focused::after": {
    borderBottom: `2px solid ${
      theme.palette.mode !== "dark"
        ? "#008c64 !important"
        : "#31FFCD !important"
    }`,
  },
  "&..MuiSelect-selectMenu": {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "24px",
    minHeight: "2px",
    "&:focus": {
      // borderBottom: "none",
      backgroundColor: theme.palette.mode !== "dark" ? "white" : "black",
      color: theme.palette.mode == "dark" ? "white" : "black",
    },
  },
  "& div *": {
    minHeight: "fit-content !important",
    fontSize: "15px !important",
  },
  color: theme.palette.mode == "dark" ? "#FFF" : "#000",
  borderBottom: "1px solid #2c2d2d",
  fontSize: "15px",
  lineHeight: "24px",
  fontWeight: 600,
  padding: "0.4rem 0.5rem",
  // "& .MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-focused:after": {
  //   borderBottom: "none",
  // },
  "& .MuiSelect-icon": {
    color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
  },
}));

export const styles = {
  menuItem: {
    fontSize: "12px",
    fontWeight: 500,
  },
};
