import TableCell from "@mui/material/TableCell";
import { styled, useTheme } from "@mui/material/styles";
import { Select, MenuItem, TextField } from "@mui/material";

export const TableCellEditable = styled(TableCell)(({ theme }) => ({
  padding: "0px",
  border: `1px solid ${theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"}`,
}));
export const TableTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    color: theme.palette.mode !== "dark" ? "black" : "white",
    width: "100%",
    height: "100%",
    border: "none",
    outline: "none",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    outline: "none",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
  "&.MuiSelect-select": {
    border: "none",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    paddingRight: "10px",
  },
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    "&:focus": {
      borderColor: "transparent",
    },
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

export const CustomMenuItem = styled(MenuItem)({
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "transparent",
    border: "none",
  },
});

export const StyledSelect = styled(Select)({
  "&. MuiOutlinedInput-root ": {
    "&:hover fieldset": {
      border: "transparent",
    },
    "&.Mui-focused fieldset": {
      border: "transparent",
    },
    "&.MuiSelect-root": {
      border: "none !important",
    },
    "&.MuiSelect-select": {
      border: "none !important",
    },
  },
});

export const StyledMenuItem = styled(MenuItem)({
  "&.MuiListItem-root.Mui-selected": {
    "&.MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
  },
});

const GetStyles = () => {
  const theme = useTheme();
  return {
    tableHeader: {
      background: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
    },
    clientSolution: {
      border: "1px solid #0D0D0D",
    },
    rowHeaders: {
      background: theme.palette.mode !== "dark" ? "#e9fff8" : "#122f28",
      border: `1px solid ${
        theme.palette.mode !== "dark" ? "#c9c6d8" : "#43404D"
      }`,
      fontSize: "16px",
      fontWeight: "700",
      color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
      focus: "none",
      "& .MuiInputBase-root": {
        border: "none",
        padding: "0px",
      },
    },
    textfields: {
      fontSize: "13px",
    },
    cellTextColorFirstColumn: {
      color: theme.palette.mode === "dark" ? "#FFF" : "#000",
      fontSize: "16px",
      border: "1px solid #43404D",
      backgroundColor: theme.palette.mode === "dark" ? "#282a30" : "#edf0f9",
    },
    cellTextColor: {
      color: theme.palette.mode === "dark" ? "#FFF" : "#000",
      fontSize: "16px",
      border: `2px solid ${
        theme.palette.mode === "dark" ? "#211F2C" : "#c9c6d8"
      }`,
    },
    editableCellTextColor: {
      color: theme.palette.mode === "dark" ? "#FFF" : "#000",
      fontSize: "16px",
      border: `2px solid ${
        theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
      }`,
    },
    tableBodyBackground: {
      background: theme.palette.mode === "dark" ? "black" : "#f3fffb",
    },
    textFiledContainer: {
      padding: "0px",
      border: `2px solid ${
        theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
      }`,
    },
    textField: {
      color: "white",
      width: "100%",
      height: "100%",
      border: "none",
      outline: "none",
      "&:focus": {
        border: "none",
        outline: "none",
      },
    },
    headerTitle: {
      fontWeight: "700",
      fontSize: "17px",
      textAlign: "center",
      width: "1163px",
      Textalign: "center",
      color: theme.palette.mode === "dark" ? "#000" : "#FFF",
    },
    inputText: {
      minHeight: "30px",
    },
    iconColor: {
      color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD",
      cursor: "pointer",
    },
    tableCell: {
      color: "white",
      padding: "0px",
    },
    rowStyle: {
      fontSize: "16px",
    },
  };
};
export default GetStyles;
