import routePaths from "../../routes/routePaths";
import { getCookie } from "utils/auth-token-utils";

export const isSelectedRoute = (route, location) => {
  if (route === routePaths.DASHBOARD || route === routePaths.HOME) {
    return (
      location?.pathname === routePaths.DASHBOARD ||
      location?.pathname === routePaths.HOME
    );
  } else {
    return location?.pathname?.includes(route);
  }
};

export const getMenuBarRoutes = (
  location,
  isSiteSelected,
  isFilterSelected
) => {
  let data = [];

  if (isSiteSelected) {
    data = [
      {
        path: routePaths.HOME,
        label: "Home",
        clickEnabled: !isSelectedRoute(routePaths.DASHBOARD, location),
      },
      {
        path: routePaths.REQUEST_LLC,
        label: "Request Full LCC",
        clickEnabled: true,
      },
      {
        path: routePaths.SITE_REPORT,
        label: "Site Environment Report",
        clickEnabled: true,
      },
      {
        path: routePaths.CONTACT_SALES,
        label: "Contact Sales",
        clickEnabled: true,
      },
    ];
  } else {
    data = [
      {
        path: routePaths.DASHBOARD,
        label: "Home",
        clickEnabled: !isSelectedRoute(routePaths.DASHBOARD, location),
      },
      {
        path: routePaths.CONTACT_SALES,
        label: "Contact Sales",
        clickEnabled: true,
      },
    ];
  }
  // if (userType !== "guest") {
  //   return [
  //     // {
  //     //   path: routePaths.INVITE_PAGE,
  //     //   label: "Invite a Customer",
  //     //   clickEnabled: true,
  //     // },
  //     ...data,
  //   ];
  // } else
  return data;
};
