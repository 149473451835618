/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

//Material
import {
  Grid,
  Box,
  Typography,
  FormControl,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

//Comps
import CustomTextField from "../../components/TextField";
import RoundedButton from "../../components/RoundedButton";
import StandardTextField from "../../components/StandardTextField";
import OutlineDropdown from "../../components/OutlineDropdown";
import StandardDropdown from "../../components/StandardDropdown";
import OutlinedButton from "../../components/Button";
import Steppers from "../../components/Stepper";
import SmallTable from "../../components/SmallTable/SmallTable";
import ClientTable from "../../components/ClientTable/ClientTable";
import OptimalTable from "../../components/OptimalTable";
import SolutionTable from "../../components/SolutionTable";
import { ArrowForward } from "@mui/icons-material";
import LCCLinkExpired from "../../components/LCCLinkExpired";

//Assets
import FAQImage from "../../assets/FAQImage.svg";
import FAQImageLight from "../../assets/FAQImg-light.svg";
import logobg from "../../assets/logobg.svg";

//actions
import { goToRoute, setQuery } from "../../store/actions/navigationActions";
import { setIsSiteSelected } from "../../store/slices/flags_slice";

//utils
import {
  LccPeriodoptions,
  Maintenanceoptions,
  WaterWashoptions,
  Timeloadoptions,
  PartLoadoptions,
  Currencyoptions,
  Unitoptions,
  TurbineHeatoptions,
  TotalSystemoptions,
  CostOfFueloptions,
  OfflineCompressoroptions,
  TaxRateoptions,
  PriceSoldoptions,
} from "./utils";
import { useDispatch, useSelector } from "react-redux";
import routePaths from "../../routes/routePaths";
import jsPDF from "jspdf";

//Style
import GetStyles, { StyledBox } from "./style";
import {
  fetchEngineManufacturer,
  getLCCTurbineAirflowAndHeatrate,
  getLCCTurbineFromManufacturers,
  sendEmailAttachment,
  sendRequestLLCEmail,
} from "api";
import {
  setLccManufacturerOptions,
  setLccTurbineOptions,
} from "store/slices/lcc_slice";
import {
  deserializeLccManufacturer,
  deserializeLccTurbines,
} from "utils/deserializers/lcc_data_deserializer";
import {
  showNotification,
  showToasterMessage,
} from "store/slices/modals_slice";
import {
  setEngineManufacturerList,
  setTurbineTypeList,
} from "store/slices/global_data_slice";
import EfficiencyModal from "components/Modal/efficiencyModal";
import moment from "moment";
import { useMsal } from "@azure/msal-react";
import { getStoredXml } from "./xml-script";

const RequestLLCPage = () => {
  const dispatch = useDispatch();
  const questionerRef = useRef();
  const [isFullLoad, setIsFullLoad] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [requestLccOption, setRequestLccOption] = useState({
    engineManufacture: "Select One",
    turbineType: "Select One",
    currency: "USD",
    unit: "Metric",
    turbineHeat: "kJ/kWh",
    taxRate: "[USD/Tonne]",
    priceOfMWh: "[USD/MWh]",
    costOfFuel: "USD/MJ",
    lccPeriod: "5",
    maintenanceIntervalMonths: "12",
    washIntervalMonths: "6",
    timeAtFullLoad: "30",
    powerOutput: "70",
    compressorWashCost: "[USD/wash]",
    totalSystemAirflow: "m3/h",
  });

  const [formData, setFormData] = useState({
    downtimeFilter: "Hours",
    downtimeCompressor: "Hours",
    downtimeFilterText: "21",
    turbineHours: "Hours/Year",
    downtimeCompressorText: "48",
    turbineHeatText: "5,000",
    compressorWashCostText: "5000",
    cO2TaxRate: "20",
    mWhSoldText: "50",
    costOfFuelText: "0.003",
    totalSystemAirflowText: "8000",
    turbineOperatingText: "8000",
  });

  const [isConditionMet, setIsConditionMet] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const search = useSelector((state) => state.router.location.search);
  const [isCogeneration, setIsCogeneration] = useState(false);
  const [isSimpleCycle, setIsSimpleCycle] = useState("Simple Cycle");
  const [activeButtonLabel, setActiveButtonLabel] = useState("Simple Cycle");
  const searchParams = new URLSearchParams(search);
  const { lccManufacturerOptions, turbineOptions } = useSelector(
    (state) => state.lccData
  );
  const {
    globalOptimalSolution,
    optimalSolutionWithSizeLimitation,
    currentSolution,
    stage1,
    stage2,
    stage3,
  } = useSelector((state) => state.filters);
  const lccData = useSelector((state) => state.lccData);
  console.log(lccData, "lccData");
  console.log(globalOptimalSolution, "Request LCCPage globalOptimalSolution");
  console.log(currentSolution, "Request LCCPage currentSolution");
  console.log(
    optimalSolutionWithSizeLimitation,
    "Request LCCPage optimalSolutionWithSizeLimitation"
  );
  const { graphURL, ...globalData } = useSelector((state) => state.globalData);
  const { userDetails } = useSelector((state) => state.users);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const selectedValue = useSelector((state) => state.users.selectedValue);
  const [headerValues, setHeaderValues] = useState({
    stage1: "",
    stage2: "",
    stage3: "",
  });
  const [rowsValues, setRowsValues] = useState({
    filterType: {
      stage1: "",
      stage2: "",
      stage3: "",
    },
    filterEff: {
      stage1: "",
      stage2: "",
      stage3: "",
    },
    noOfFilters: {
      stage1: "",
      stage2: "",
      stage3: "",
    },
  });
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  console.log(activeAccount, "activeAccount");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const styles = GetStyles();
  const updatedFaqImg =
    theme.palette.mode !== "dark" ? FAQImageLight : FAQImage;
  useEffect(() => {
    fetchEngineManufacturer()
      .then((res) => {
        dispatch(
          setLccManufacturerOptions(deserializeLccManufacturer(res.data))
        );
        dispatch(setEngineManufacturerList(res.data));
      })
      .catch((err) => {
        dispatch(
          showToasterMessage({
            message: "Failed to fetch Engine Manufacturers",
            toasterColor: "primary",
          })
        );
      });
  }, []);

  useEffect(() => {
    if (
      requestLccOption.engineManufacture &&
      requestLccOption.engineManufacture !== "Select One"
    ) {
      getLCCTurbineFromManufacturers(requestLccOption.engineManufacture)
        .then((res) => {
          dispatch(setTurbineTypeList(res.data));
          dispatch(setLccTurbineOptions(deserializeLccTurbines(res.data)));
          setFormData({
            ...formData,
            totalSystemAirflowText: 0,
            turbineHeatText: 0,
          });
          setActiveButtonLabel("Simple Cycle");
          setIsSimpleCycle("Simple Cycle");
        })
        .catch((err) => {
          dispatch(
            showToasterMessage({
              message: "Failed to fetch Turbines",
              toasterColor: "primary",
            })
          );
        });
    }
  }, [requestLccOption.engineManufacture]);

  useEffect(() => {
    setActiveButtonLabel("Simple Cycle");
    setIsSimpleCycle("Simple Cycle");
    handleButtonClick("Simple Cycle", "Simple Cycle");
  }, [requestLccOption.turbineType, requestLccOption?.unit]);

  useEffect(() => {}, [
    requestLccOption.engineManufacture,
    requestLccOption.turbineType,
  ]);

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const getError = (fieldName, value) => {
    const ragex = /^[()\-,.\d\s]+$/;
    return !ragex.test(value);
  };

  const handleFullLoadClick = () => {
    setIsFullLoad(true);
    setIsConditionMet(true);
  };

  const handlePartLoadClick = () => {
    setIsFullLoad(false);
    setIsConditionMet(false);
  };

  const handleDropdownChange = (e) => {
    if (e.target.name === "currency") {
      console.log(e.target.value, e.target.name);
      let taxRate, priceOfMWh, compressorWashCost, costOfFuel;
      if (e.target.value === "EURO") {
        taxRate = "[EURO/Tonne]";
        priceOfMWh = "[EURO/MWh]";
        compressorWashCost = "[EURO/wash]";
        if (requestLccOption.unit === "Metric") {
          costOfFuel = "EURO/MJ";
        } else {
          costOfFuel = "EURO/MMBtu";
        }
      } else {
        taxRate = "[USD/Tonne]";
        priceOfMWh = "[USD/MWh]";
        compressorWashCost = "[USD/wash]";
        if (requestLccOption.unit === "Metric") {
          costOfFuel = "USD/MJ";
        } else {
          costOfFuel = "USD/MMBtu";
        }
      }
      setRequestLccOption({
        ...requestLccOption,
        [e.target.name]: e.target.value,
        taxRate,
        priceOfMWh,
        compressorWashCost,
        costOfFuel,
      });
    } else if (e.target.name === "unit") {
      console.log(e.target.value, e.target.name, requestLccOption);
      let turbineHeat, totalSystemAirflow, costOfFuel;
      if (e.target.value === "Metric") {
        turbineHeat = "kJ/kWh";
        totalSystemAirflow = "m3/h";
        if (requestLccOption.currency === "EURO") {
          costOfFuel = "EURO/MJ";
        } else {
          costOfFuel = "USD/MJ";
        }
      } else {
        turbineHeat = "Btu/kWh";
        totalSystemAirflow = "CFM";
        if (requestLccOption.currency === "EURO") {
          costOfFuel = "EURO/MMBtu";
        } else {
          costOfFuel = "USD/MMBtu";
        }
      }
      setRequestLccOption({
        ...requestLccOption,
        [e.target.name]: e.target.value,
        turbineHeat,
        totalSystemAirflow,
        costOfFuel,
      });
    } else
      setRequestLccOption({
        ...requestLccOption,
        [e.target.name]: e.target.value,
      });
  };

  const onClickNext = () => {
    dispatch(goToRoute(routePaths.REQUEST_LLC));
    dispatch(setIsSiteSelected(true));
    setActiveStep(2);
  };

  const onClickBack = () => {
    dispatch(goToRoute(routePaths.REQUEST_LLC));
    dispatch(setIsSiteSelected(true));
    setActiveStep(1);
  };
  const onClickSubmit = async () => {
    let flag = true;
    const regex = /^[()\-,.\d\s]+$/;
    for (const [key, value] of Object.entries(formData)) {
      if (
        key.toLowerCase().includes("text") ||
        key.toLowerCase().includes("rate")
      ) {
        if (value && !regex.test(value)) {
          flag = false;
          break;
        }
      }
    }
    if (flag) {
      dispatch(setQuery("submited", true));
      dispatch(setIsSiteSelected(true));
      const pdf = new jsPDF({ compress: true });
      // pdf.addImage(graphURL, "PNG", 10, 10, 190, 130);
      const storedPdf = pdf.output("datauristring");
      // pdf.save("graph.pdf");
      const splittedStr = globalData.site?.split(",");
      const checkSplitWithSpaceStr =
        splittedStr[1]?.split(" ")?.length > 1
          ? splittedStr[1]?.split(" ")[1]
          : splittedStr[1];
      // console.log(checkSplitWithSpaceStr, "checkSplitWithSpaceStr");

      const payload = {
        filters: [],
        unitID: requestLccOption.unit,
        currency: requestLccOption.currency,
        currencyID: requestLccOption.currency === "USD" ? 2 : 3,
        reportDate: moment(Date.now()).format("yyyyMMDD"),
        contactEmail:
          localStorage?.getItem("contactEmail") ||
          activeAccount?.idTokenClaims?.email,
        salesPersonEmail: localStorage.getItem("salesPersonEmail"),
        site: globalData.site,
        location:
          globalData.cordinates?.lat + " , " + globalData.cordinates?.lng,
        manufacturer: requestLccOption.engineManufacture,
        manufacturerID: globalData.engineManufacturerList.filter(
          (item) => item["Manufacturer"] === requestLccOption.engineManufacture
        )[0]["Manufacturer_id"],
        turbineType: requestLccOption.turbineType,
        turbineTypeID: globalData.turbineTypeList.filter(
          (item) => item["Gas Turbine"] === requestLccOption.turbineType
        )[0]["Gas Turbine_id"],
        isoBaseRating: globalData.turbineTypeList.filter(
          (item) => item["Gas Turbine"] === requestLccOption.turbineType
        )[0]["ISOBaseRating"],
        heatRate: formData.turbineHeatText,
        heatRateUnit: requestLccOption.turbineHeat == "kJ/kWh" ? "kJ" : "btu",
        massFlow: globalData.turbineTypeList.filter(
          (item) => item["Gas Turbine"] === requestLccOption.turbineType
        )[0]["Massflow"],
        comments: globalData.turbineTypeList.filter(
          (item) => item["Gas Turbine"] === requestLccOption.turbineType
        )[0]["Comments"],
        operationMode:
          activeButtonLabel == "Simple Cycle"
            ? "SC"
            : activeButtonLabel == "1x1 Combined Cycle"
            ? "1x1 CC"
            : "2x1 CC",
        downtimeReplacement: formData.downtimeFilterText,
        downtimeReplacementUnit: formData.downtimeFilter,
        gasTurbineOutput:
          formData.turbineOutput === 0
            ? globalData?.output_input
            : formData.turbineOutput,
        engineHeatRate: "engineHeatRate",
        engineHeatRateUnit: "engineHeatRateUnit",
        fuelCost: formData.costOfFuelText,
        fuelCostUnit: requestLccOption.costOfFuel == "USD/MJ" ? "MJ" : "MMBTU",
        mWhSoldPrice: formData.mWhSoldText,
        mWhSoldPriceUnit: requestLccOption.priceOfMWh,
        compressorWashingDowntime: formData.downtimeCompressorText,
        compressorWashingDowntimeUnit: formData.downtimeCompressor,
        compressorWashCost: formData.compressorWashCostText,
        compressorWashCostUnit: requestLccOption.compressorWashCost,
        turbineOperating: formData.turbineOperatingText,
        turbineOperatingUnit: formData.turbineHours,
        totalAirflow: formData.totalSystemAirflowText,
        totalAirflowUnit: requestLccOption.totalSystemAirflow,
        // selectedCycle: activeButtonLabel,
        OperatingTimeMode: "OperatingTimeMode",
        baseLoad: "baseLoad",
        partLoad: "partLoad",
        // lccPeriod: "lccPeriod",
        majorMaintenanceInterval: "majorMaintenanceInterval",
        co2TaxRate: formData.cO2TaxRate,
        co2TaxUnit: requestLccOption.taxRate,
        latitude: "latitude",
        longitude: "longitude",
        totalSolution: "totalSolution",
        // optimalSolution: "optimalSolution",
        totalOptimalStages: "totalOptimalStages",
        // offlineWaterWashInterval: "offlineWaterWashInterval",
        solutionWithSizeLimitationStage: "solutionWithSizeLimitationStage",
        solutionWithSizeLimitationFilter: "solutionWithSizeLimitationFilter",
        // offlineWaterwashInterval: "offlineWaterwashInterval",
        solutionWithSizeLimitationFilterID:
          "solutionWithSizeLimitationFilterID",
        loadType: isFullLoad ? "Full Load" : "Part Load",
        currentSolution: `<NUMBEROFSTAGES>${
          lccData.currentSolution?.numberOfStages
        }</NUMBEROFSTAGES>
                          ${lccData?.currentSolution?.data?.map(
                            (filter) =>
                              `<FILTER>${filter.name}-${filter.filterType}</FILTER>`
                          )}`,
        optimalSolution: `<NUMBEROFSTAGES>${
          lccData.optimalSolution?.numberOfStages
        }</NUMBEROFSTAGES>
        ${lccData?.optimalSolution?.data?.map(
          (filter) => `<FILTER>${filter.name}-${filter.filterType}</FILTER>`
        )}`,
        optimalWithSizeLimitationSolution: `<NUMBEROFSTAGES>${
          lccData.optimalSolutionWithSizeLimitation?.numberOfStages
        }</NUMBEROFSTAGES>
        ${lccData?.optimalSolutionWithSizeLimitation?.data?.map(
          (filter) => `<FILTER>${filter.name}-${filter.filterType}</FILTER>`
        )}`,
        filterStageNumber1: globalOptimalSolution?.filter_stage_number[0],
        filterID11: globalOptimalSolution?.filter[0]?.FilterID,
        noOfFilter11: globalOptimalSolution?.filter[0]?.Nfilter,
        filterID12: globalOptimalSolution?.filter[1]?.FilterID,
        noOfFilter12: globalOptimalSolution?.filter[1]?.Nfilter,
        filterID13: globalOptimalSolution?.filter[2]?.FilterID,
        noOfFilter13: globalOptimalSolution?.filter[2]?.Nfilter,
        filterStageNumber2: currentSolution?.filter_stage_number[0],
        filterID21: currentSolution?.filter[0]?.FilterID,
        noOfFilter21: currentSolution?.filter[0]?.Nfilter,
        filterID22: currentSolution?.filter[1]?.FilterID,
        noOfFilter22: currentSolution?.filter[1]?.Nfilter,
        filterID23: currentSolution?.filter[2]?.FilterID,
        noOfFilter23: currentSolution?.filter[2]?.Nfilter,
        siteInfo: globalData?.siteInfo,
        isCogeneration: isCogeneration,
        filter_stage_number_optimal:
          optimalSolutionWithSizeLimitation?.filter_stage_number?.[0],
        isCoordinatesCheck: check_lat_lon(
          splittedStr[0],
          checkSplitWithSpaceStr
        ),
        isStage5Check: globalOptimalSolution?.filter_stage_number?.[0], // Optimal solution has how many stages
        isStage6Check: currentSolution?.filter_stage_number?.[0], // current solution has how many stages
        file: storedPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
      };
      console.log(payload, "fdsfsdd");

      if (isFullLoad) {
        payload["lccPeriod"] = requestLccOption.lccPeriod;
        payload["maintenanceIntervalMonths"] =
          requestLccOption.maintenanceIntervalMonths;
        payload["offlineWaterWashInterval"] =
          requestLccOption.washIntervalMonths;
      } else {
        payload["timeAtFullLoad"] = requestLccOption.timeAtFullLoad;
        payload["powerOutput"] = requestLccOption.powerOutput;
        payload["lccPeriod"] = requestLccOption.lccPeriod;
        payload["maintenanceIntervalMonths"] =
          requestLccOption.maintenanceIntervalMonths;
        payload["offlineWaterWashInterval"] =
          requestLccOption.washIntervalMonths;
      }

      console.log(
        optimalSolutionWithSizeLimitation,
        "requestLCC optimalSolutionWithSizeLimitation"
      );
      if (
        optimalSolutionWithSizeLimitation &&
        Array.isArray(optimalSolutionWithSizeLimitation.filter_stage_number)
      ) {
        payload["filterStageNumber3"] =
          optimalSolutionWithSizeLimitation?.filter_stage_number[0];
        payload["filterID31"] =
          optimalSolutionWithSizeLimitation?.filter[0]?.FilterID;
        payload["noOfFilter31"] =
          optimalSolutionWithSizeLimitation?.filter[0]?.Nfilter;
        payload["filterID32"] =
          optimalSolutionWithSizeLimitation?.filter[1]?.FilterID;
        payload["noOfFilter32"] =
          optimalSolutionWithSizeLimitation?.filter[1]?.Nfilter;
        payload["filterID33"] =
          optimalSolutionWithSizeLimitation?.filter[2]?.FilterID;
        payload["noOfFilter33"] =
          optimalSolutionWithSizeLimitation?.filter[2]?.Nfilter;
        payload["isStage4Check"] =
          optimalSolutionWithSizeLimitation?.filter_stage_number?.[0]; // how many stages optimal with size limitation has
      }

      // if customer input has Generic filters, don't pass to XML
      if (currentSolution?.filter_stage_number?.[0]) {
        let stage1ID = currentSolution?.filter[0]?.FilterID;
        let stage2ID = currentSolution?.filter[1]?.FilterID;
        let stage3ID = currentSolution?.filter[2]?.FilterID;
        let customerSolutionExist = true;

        if (typeof stage1ID !== "undefined") {
          if (stage1ID.includes("Generic")) {
            customerSolutionExist = false;
          }
        }
        if (typeof stage2ID !== "undefined") {
          if (stage2ID.includes("Generic")) {
            customerSolutionExist = false;
          }
        }
        if (typeof stage3ID !== "undefined") {
          if (stage3ID.includes("Generic")) {
            customerSolutionExist = false;
          }
        }
        if (!customerSolutionExist) {
          payload["isStage6Check"] = 0;
        }
      }

      const newPayload = {
        customer_name: activeAccount?.idTokenClaims?.name,
        customer_email: payload.contactEmail,
        site: payload.site,
        application_t: globalData?.application,
        output_input: `${globalData?.output_input}MW`,
        torecip: payload.salesPersonEmail,
        ccrecip: "chang.hong@camfil.com",
        customer_stage: selectedValue,
        customer_filter_name1: headerValues.stage1,
        customer_filter_type1: rowsValues.filterType.stage1,
        customer_filter_eff1: rowsValues.filterEff.stage1,
        customer_filter_number1: rowsValues.noOfFilters.stage1,
        customer_filter_name2: headerValues.stage2,
        customer_filter_type2: rowsValues.filterType.stage2,
        customer_filter_eff2: rowsValues.filterEff.stage2,
        customer_filter_number2: rowsValues.noOfFilters.stage2,
        customer_filter_name3: headerValues.stage3,
        customer_filter_type3: rowsValues.filterType.stage3,
        customer_filter_eff3: rowsValues.filterEff.stage3,
        customer_filter_number3: rowsValues.noOfFilters.stage3,
        stage1_size: encodeURIComponent(stage1?.filterDepth || "None"),
        stage1_eff: stage1?.rating ? `T${stage1?.rating}` : "T6",
        stage1_type: stage1?.filterType || "Static",
        stage2_size: encodeURIComponent(stage2?.filterDepth || "None"),
        stage2_eff: stage2?.rating ? `T${stage2?.rating}` : "T9",
        stage2_type: stage2?.filterType || "Static",
        stage3_size: encodeURIComponent(stage3?.filterDepth || "None"),
        stage3_eff: stage3?.rating ? `T${stage3?.rating}` : "T10",
        stage3_type: stage3?.filterType || "Static",
        final_life: stage3?.life || 0,
        file: payload.file,
      };
      // sendEmailAttachment(newPayload)
      const storedXml = getStoredXml(payload);
      // sendRequestLLCEmail(payload)
      //   .then((res) => {
      const xmlFormData = new FormData();

      xmlFormData.append("file", storedXml);

      try {
        await sendEmailAttachment(newPayload, xmlFormData);
      } catch (err) {
        dispatch(
          showNotification({
            message:
              "Oops! Something went wrong, and the email could not be sent. Please contact myGTexpert@Camfil.com for assistance.",
            severity: "error",
          })
        );
      }
    }
  };

  const check_lat_lon = (lat, lon) => {
    const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
    const regexLon =
      /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
    let validLat = regexLat.test(lat);
    let validLon = regexLon.test(lon);
    return validLat && validLon;
  };

  const handleCogenerationChange = (event) => {
    console.log(event.target.checked);
    setIsCogeneration((prevIsCogeneration) => !prevIsCogeneration);
  };

  const handleButtonClick = (label, buttonName) => {
    setActiveButtonLabel(label);
    setIsSimpleCycle(buttonName);
    if (
      requestLccOption.turbineType &&
      requestLccOption.turbineType !== "Select One" &&
      requestLccOption.unit &&
      isSimpleCycle
    ) {
      const payload = {
        LccTurbineName: requestLccOption.turbineType,
        LccUnit: requestLccOption.unit,
        OperationMode: buttonName,
      };
      getLCCTurbineAirflowAndHeatrate(payload)
        .then((res) => {
          if (res.data && res.data.length) {
            setFormData({
              ...formData,
              totalSystemAirflowText: res.data[0]["Total Airflow"],
              turbineHeatText: res.data[0]["Heat Rate"],
              turbineOutput: res.data[0]["Output"],
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // else {
    //   dispatch(
    //     showToasterMessage({
    //       message:
    //         "Please Select a Turbine Type , Unit and then Operation Mode to get Heat rate ",
    //       toasterColor: "primary",
    //     })
    //   );
    // }
  };

  const modalData = [
    {
      id: "mwh",
      title: "Price of MWh Sold",
      description:
        "Price at which electricity is sold on average, or estimated value of each MWh.",
    },
    {
      id: "cogeneration",
      title: "Cogeneration",
      description:
        "Is steam produced by the Heat Recovery Steam Generator (HRSG) sold?",
    },
    {
      id: "downtime1",
      title: "Filter Replacement Time",
      description: "Time offline to replace one set of filters.",
    },
    {
      id: "downtime2",
      title: "Compressor Washing Time",
      description: "Time offline to perform one offline water wash.",
    },
    {
      id: "turbineheatrate",
      title: "Turbine Heat Rate",
      description:
        "Amount of fuel consumed per kWh of electricity generated. Lower is more efficient. Input to be in Lower Heating Value.",
    },
    {
      id: "offlinewashcost",
      title: "Compressor Wash Cost",
      description:
        "Cost of performing a wash, not including the lost revenue during the wash downtime.",
    },
    {
      id: "costoffuel",
      title: "Cost of Fuel",
      description: "Use cost of fuel at lower heating value.",
    },
    {
      id: "lccperiod",
      title: "LCC Period",
      description: "Evaluation period of Life Cycle Cost.",
    },
    {
      id: "waterwashinterval",
      title: "Water Wash Interval",
      description:
        "What is the interval between offline water washes?  This is used to calculate the amount of engine fouling between maintenance stops of the engine.",
    },
    {
      id: "majormaintenance",
      title: "Major Maintenance",
      description:
        "List the frequency of major maintenance of the gas turbine, where the unit will be taken offline for several days.  No downtime costs will be calculated due to filter replacement or offline water washing, if it's done at the same time as a major maintenance.",
    },
    {
      id: "timeatfullload",
      title: "Time At Full Load [%]",
      description:
        "Percent of time running at full output. 10% base load and 8,000 hours means 800 hours at base load and 7,200 hours at part load.",
    },
    {
      id: "poweroutput",
      title: "Power Output When At Part Load [%]",
      description:
        "Average percent of output when unit is not at base load. 100MW rated output and 70% load is equivalent to 70MW output when at part load.",
    },
  ];

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  console.log(globalData, "formData");
  if (searchParams.get("submited")) return <LCCLinkExpired />;

  return (
    <Grid container sx={{ overflow: "hidden" }}>
      <img src={logobg} alt="camfil" style={styles.containerbg} />
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8}>
        <Box sx={styles.ContainerStyle}>
          <Box sx={styles.StepperStyle}>
            <Steppers activeStep={activeStep} setActiveStep={setActiveStep} />
          </Box>
          {activeStep === 1 && (
            <>
              <SmallTable />
              <ClientTable
                headerValues={headerValues}
                rowsValues={rowsValues}
                setHeaderValues={(data) => setHeaderValues(data)}
                setRowsValues={(data) => setRowsValues(data)}
                selectedValue={selectedValue}
                rows={rows}
                setRows={setRows}
                headers={headers}
                setHeaders={setHeaders}
              />
              <OptimalTable />
              <SolutionTable />
              <Box sx={styles.NextButtonStyle}>
                <OutlinedButton
                  customcolor={
                    theme.palette.mode !== "dark" ? "#ffffff" : "#080809"
                  }
                  label="Next"
                  onClick={onClickNext}
                  customBackgroundColor={
                    theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                  }
                  customBorderColor={
                    theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                  }
                  disabled={false}
                  width="30%"
                  endIcon={<ArrowForward />}
                  sx={{ marginBottom: "26px" }}
                />
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <Box ref={questionerRef} sx={styles.BoxContainer}>
              <StyledBox maxWidth="656px">
                <FormControl sx={{ width: "100%" }}>
                  <Box sx={styles.EngineContainerStyle}>
                    <Typography sx={styles.labelStyles}>
                      Engine Manufacturer:
                    </Typography>
                    <OutlineDropdown
                      width="100%"
                      option={lccManufacturerOptions}
                      value={requestLccOption?.engineManufacture}
                      name="engineManufacture"
                      onChange={handleDropdownChange}
                      sx={{
                        ...styles.EngineDropdown,
                        ...styles.turbineTextStyle,
                      }}
                    />
                  </Box>

                  <Box sx={styles.TurbineStyle}>
                    <Typography sx={styles.labelStyles}>
                      Turbine Type:
                    </Typography>
                    <OutlineDropdown
                      width="100%"
                      option={turbineOptions}
                      value={requestLccOption?.turbineType}
                      name="turbineType"
                      onChange={handleDropdownChange}
                      sx={{
                        ...styles.EngineDropdown,
                        ...styles.turbineTextStyle,
                      }}
                    />
                  </Box>

                  <Box sx={styles.CurrencyStyle}>
                    <Typography sx={styles.labelStyles}>Currency:</Typography>
                    <OutlineDropdown
                      width="100%"
                      option={Currencyoptions}
                      value={requestLccOption?.currency}
                      name="currency"
                      onChange={handleDropdownChange}
                      sx={{
                        ...styles.EngineDropdown,
                        ...styles.turbineTextStyle,
                      }}
                    />
                  </Box>

                  <Box sx={styles.UnitStyle}>
                    <Typography sx={styles.labelStyles}>Unit:</Typography>
                    <OutlineDropdown
                      width="100%"
                      option={Unitoptions}
                      value={requestLccOption?.unit}
                      name="unit"
                      onChange={handleDropdownChange}
                      sx={{
                        ...styles.EngineDropdown,
                        ...styles.turbineTextStyle,
                      }}
                    />
                  </Box>

                  <Box sx={styles.CheckboxContainer}>
                    <Checkbox
                      sx={styles.CheckboxStyle}
                      checked={isCogeneration}
                      onChange={handleCogenerationChange}
                    />
                    <Typography sx={styles.Checkboxlabel}>
                      Cogeneration?
                    </Typography>
                    <img
                      src={updatedFaqImg}
                      alt="img-notfound"
                      onClick={() => setOpenModal("cogeneration")}
                    />
                  </Box>

                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={4}>
                      <RoundedButton
                        variant="outlined"
                        label="Simple Cycle"
                        onClick={() =>
                          handleButtonClick("Simple Cycle", "Simple Cycle")
                        }
                        isSimpleCycle={isSimpleCycle}
                        customBackgroundColor={
                          isSimpleCycle === "Simple Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#A4CDBD"
                            : theme.palette.mode === "dark"
                            ? "#000000"
                            : "#FFFFFF"
                        }
                        customBorderColor={
                          isSimpleCycle === "Simple Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#A4CDBD"
                            : theme.palette.mode === "dark"
                            ? "#FFFFFF"
                            : "#000000"
                        }
                        size="medium"
                        width="100%"
                        disabled={false}
                        showArrowIcon={isSimpleCycle === "Simple Cycle"}
                        textColor={
                          isSimpleCycle === "Simple Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#ffffff"
                              : "#0D0D0D"
                            : theme.palette.mode === "dark"
                            ? "#FFFFFF"
                            : "#000000"
                        }
                        activeLabel={activeButtonLabel === "Simple Cycle"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <RoundedButton
                        variant="outlined"
                        label="1x1 Combined Cycle"
                        width="100%"
                        onClick={() =>
                          handleButtonClick(
                            "1x1 Combined Cycle",
                            "1x1 Combined Cycle"
                          )
                        }
                        isSimpleCycle={isSimpleCycle}
                        customBackgroundColor={
                          isSimpleCycle === "1x1 Combined Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#A4CDBD"
                            : theme.palette.mode === "dark"
                            ? "#000000"
                            : "#FFFFFF"
                        }
                        customBorderColor={
                          isSimpleCycle === "1x1 Combined Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#A4CDBD"
                            : theme.palette.mode === "dark"
                            ? "#FFFFFF"
                            : "#000000"
                        }
                        size="medium"
                        disabled={false}
                        showArrowIcon={isSimpleCycle === "1x1 Combined Cycle"}
                        textColor={
                          isSimpleCycle === "1x1 Combined Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#ffffff"
                              : "#0D0D0D"
                            : theme.palette.mode === "dark"
                            ? "#FFFFFF"
                            : "#000000"
                        }
                        activeLabel={activeButtonLabel === "1x1 Combined Cycle"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <RoundedButton
                        variant="outlined"
                        label="2x1 Combined Cycle"
                        width="100%"
                        onClick={() =>
                          handleButtonClick(
                            "2x1 Combined Cycle",
                            "2x1 Combined Cycle"
                          )
                        }
                        isSimpleCycle={isSimpleCycle}
                        customBackgroundColor={
                          isSimpleCycle === "2x1 Combined Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#A4CDBD"
                            : theme.palette.mode === "dark"
                            ? "#000000"
                            : "#FFFFFF"
                        }
                        customBorderColor={
                          isSimpleCycle === "2x1 Combined Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#A4CDBD"
                            : theme.palette.mode === "dark"
                            ? "#FFFFFF"
                            : "#000000"
                        }
                        size="medium"
                        disabled={false}
                        showArrowIcon={isSimpleCycle === "2x1 Combined Cycle"}
                        textColor={
                          isSimpleCycle === "2x1 Combined Cycle"
                            ? theme.palette.mode !== "dark"
                              ? "#ffffff"
                              : "#0D0D0D"
                            : theme.palette.mode === "dark"
                            ? "#FFFFFF"
                            : "#000000"
                        }
                        activeLabel={activeButtonLabel === "2x1 Combined Cycle"}
                      />
                    </Grid>
                  </Grid>

                  <Box marginTop="64px" marginBottom="15px" width="100%">
                    <Grid container spacing={2}>
                      <Box display="flex" marginLeft="14px" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Downtime for Filter Replacement (Per Stage)
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("downtime1")}
                        />
                      </Box>
                      <Grid item xs={8} md={10} mt="auto">
                        {console.log(
                          getError(null, formData.downtimeFilterText),
                          "errors"
                        )}
                        <StandardTextField
                          value={formData?.downtimeFilterText}
                          name="downtimeFilterText"
                          id="downtimeFilterText"
                          onChange={(e) => {
                            handleInputChange(
                              "downtimeFilterText",
                              e.target.value
                            );
                          }}
                          error={getError(null, formData.downtimeFilterText)}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.turbineTextStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData.downtimeFilterText)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={2} marginTop="inherit">
                        <CustomTextField
                          variant="standard"
                          type="text"
                          value={formData?.downtimeFilter}
                          showSearchIcon={false}
                          label="Gas Turbine Output (MW)"
                          width="100%"
                          sx={{
                            ...styles.DowntimeStyle,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginTop="40px" marginBottom="15px" width="100%">
                    <Grid container spacing={2}>
                      <Box display="flex" marginLeft="14px" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Downtime for Offline Compressor Washing
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("downtime2")}
                        />
                      </Box>
                      <Grid item xs={8} md={10} mt="auto">
                        <StandardTextField
                          value={formData?.downtimeCompressorText}
                          name="downtimeCompressorText"
                          id="downtimeCompressorText"
                          onChange={(e) => {
                            handleInputChange(
                              "downtimeCompressorText",
                              e.target.value
                            );
                          }}
                          error={getError(
                            null,
                            formData.downtimeCompressorText
                          )}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.turbineTextStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData.downtimeCompressorText)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={2} marginTop="inherit">
                        <CustomTextField
                          variant="standard"
                          type="text"
                          value={formData?.downtimeCompressor}
                          showSearchIcon={false}
                          label="Gas Turbine Output (MW)"
                          width="100%"
                          sx={{
                            ...styles.DowntimeCompressor,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginTop="40px" marginBottom="15px" width="100%">
                    <Grid container spacing={2}>
                      <Box display="flex" marginLeft="14px" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Turbine Heat Rate
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("turbineheatrate")}
                        />
                      </Box>
                      <Grid item xs={8} md={9.5} mt="auto">
                        <StandardTextField
                          value={formData?.turbineHeatText}
                          name="turbineHeatText"
                          id="turbineHeatText"
                          onChange={(e) => {
                            handleInputChange(
                              "turbineHeatText",
                              e.target.value
                            );
                          }}
                          error={getError(null, formData.turbineHeatText)}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.turbineTextStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData.turbineHeatText)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={2.5} marginTop="inherit">
                        <OutlineDropdown
                          width="100%"
                          option={TurbineHeatoptions}
                          value={requestLccOption?.turbineHeat}
                          name="turbineHeat"
                          onChange={handleDropdownChange}
                          sx={{
                            ...styles.TurbineDropdown,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginTop="45px" marginBottom="20px" width="100%">
                    <Grid container spacing={2}>
                      <Box
                        display="flex"
                        marginLeft="14px"
                        width={isMobile ? "67%" : "100%"}
                      >
                        <Typography sx={styles.TypographyStyle}>
                          Offline Compressor Wash Cost (Consumables, Labour And
                          Others Unit)
                          <img
                            src={updatedFaqImg}
                            alt="img-notfound"
                            style={{
                              marginLeft: "8px",
                              position: "relative",
                              top: "4px",
                            }}
                            onClick={() => setOpenModal("offlinewashcost")}
                          />
                        </Typography>
                      </Box>
                      <Grid item xs={8} md={9} mt="auto">
                        <StandardTextField
                          value={formData?.compressorWashCostText}
                          name="compressorWashCostText"
                          id="compressorWashCostText"
                          onChange={(e) => {
                            handleInputChange(
                              "compressorWashCostText",
                              e.target.value
                            );
                          }}
                          error={getError(
                            null,
                            formData.compressorWashCostText
                          )}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.compressorStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData.compressorWashCostText)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={3}>
                        <OutlineDropdown
                          width="100%"
                          option={OfflineCompressoroptions}
                          value={requestLccOption?.compressorWashCost}
                          name="compressorWashCost"
                          onChange={handleDropdownChange}
                          sx={{
                            ...styles.TurbineDropdown,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginTop="40px" marginBottom="15px" width="100%">
                    <Grid container spacing={2}>
                      <Box display="flex" marginLeft="14px" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          CO<sub>2</sub> Tax Rate
                        </Typography>
                        {/* <img src={updatedFaqImg} alt="img-notfound"  onClick={() => setOpenModal("offlinewashcost")}/> */}
                      </Box>
                      <Grid item xs={8} md={9} mt="auto">
                        <StandardTextField
                          value={formData.cO2TaxRate}
                          name="cO2TaxRate"
                          id="cO2TaxRate"
                          onChange={(e) => {
                            handleInputChange("cO2TaxRate", e.target.value);
                          }}
                          error={getError(null, formData.cO2TaxRate)}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.turbineTextStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData.cO2TaxRate)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={3} marginTop="inherit">
                        <OutlineDropdown
                          width="100%"
                          option={TaxRateoptions}
                          value={requestLccOption?.taxRate}
                          name="taxRate"
                          onChange={handleDropdownChange}
                          sx={{
                            ...styles.TurbineDropdown,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginTop="40px" marginBottom="15px" width="100%">
                    <Grid container spacing={2}>
                      <Box display="flex" marginLeft="14px" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Price of MWh Sold
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("mwh")}
                        />
                      </Box>
                      <Grid item xs={8} md={9} mt="auto">
                        <StandardTextField
                          value={formData.mWhSoldText}
                          name="mWhSoldText"
                          id="mWhSoldText"
                          onChange={(e) => {
                            handleInputChange("mWhSoldText", e.target.value);
                          }}
                          error={getError(null, formData.mWhSoldText)}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.turbineTextStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData.mWhSoldText)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={3} marginTop="inherit">
                        <OutlineDropdown
                          width="100%"
                          option={PriceSoldoptions}
                          value={requestLccOption?.priceOfMWh}
                          name="priceOfMWh"
                          onChange={handleDropdownChange}
                          sx={{
                            ...styles.TurbineDropdown,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom="15px" width="100%">
                    <Grid container spacing={2}>
                      <Box display="flex" marginTop="35px" width="100%">
                        <Typography sx={styles.TypographyContent}>
                          Cost of Fuel
                        </Typography>
                        <img
                          src={logobg}
                          alt="camfil"
                          style={styles.costoffuelbg}
                        />
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("costoffuel")}
                        />
                      </Box>
                      <Grid item xs={8} md={9} mt="auto">
                        <StandardTextField
                          value={formData?.costOfFuelText}
                          name="costOfFuelText"
                          id="costOfFuelText"
                          onChange={(e) => {
                            handleInputChange("costOfFuelText", e.target.value);
                          }}
                          error={getError(null, formData.costOfFuelText)}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.turbineTextStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData.costOfFuelText)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={3} marginTop="inherit">
                        <OutlineDropdown
                          width="100%"
                          option={CostOfFueloptions}
                          value={requestLccOption?.costOfFuel}
                          name="costOfFuel"
                          onChange={handleDropdownChange}
                          sx={{
                            ...styles.TurbineDropdown,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom="15px" width="100%">
                    <Grid container spacing={2}>
                      <Box display="flex" marginTop="35px" width="100%">
                        <Typography sx={styles.TypographyContent}>
                          Total System Airflow
                        </Typography>
                        {/* <img src={updatedFaqImg} alt="img-notfound" /> */}
                      </Box>
                      <Grid item xs={8} md={10} mt="auto">
                        <StandardTextField
                          value={formData.totalSystemAirflowText}
                          name="totalSystemAirflowText"
                          id="totalSystemAirflowText"
                          onChange={(e) => {
                            handleInputChange(
                              "totalSystemAirflowText",
                              e.target.value
                            );
                          }}
                          error={getError(
                            null,
                            formData.totalSystemAirflowText
                          )}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.turbineTextStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData.totalSystemAirflowText)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={2} marginTop="inherit">
                        <OutlineDropdown
                          width="100%"
                          option={TotalSystemoptions}
                          value={requestLccOption?.totalSystemAirflow}
                          name="totalSystemAirflow"
                          onChange={handleDropdownChange}
                          sx={{
                            ...styles.TurbineDropdown,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom="15px" width="100%">
                    <Grid container spacing={2}>
                      <Box
                        display="flex"
                        marginTop="35px"
                        marginLeft="10px"
                        width="100%"
                      >
                        <Typography sx={styles.TypographyStyle}>
                          Turbine Operating Hours
                        </Typography>
                        {/* <img src={updatedFaqImg} alt="img-notfound" onClick={() => setOpenModal("turbinehours")}/> */}
                      </Box>
                      <Grid item xs={8} md={9.5} mt="auto">
                        <StandardTextField
                          value={formData?.turbineOperatingText}
                          name="turbineOperatingText"
                          id="turbineOperatingText"
                          onChange={(e) => {
                            handleInputChange(
                              "turbineOperatingText",
                              e.target.value
                            );
                          }}
                          error={getError(null, formData?.turbineOperatingText)}
                          showSearchIcon={false}
                          fullWidth
                          sx={styles.turbineTextStyle}
                        />
                        <Box style={{ color: "red" }}>
                          {getError(null, formData?.turbineOperatingText)
                            ? "The value should be a number"
                            : ""}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={2.5} marginTop="inherit">
                        <CustomTextField
                          variant="standard"
                          type="text"
                          value={formData.turbineHours}
                          showSearchIcon={false}
                          label="Gas Turbine Output (MW)"
                          width="100%"
                          sx={{
                            ...styles.DowntimeStyle,
                            ...styles.turbineTextStyle,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={styles.ButtonContainer}>
                    <Box width={{ xs: "100%", md: "315px" }} marginRight="20px">
                      <RoundedButton
                        variant="outlined"
                        label="Full Load"
                        onClick={handleFullLoadClick}
                        customBackgroundColor={
                          isConditionMet
                            ? theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#A4CDBD"
                            : theme.palette.mode === "dark"
                            ? "#000000"
                            : "#FFFFFF"
                        }
                        customBorderColor={
                          isConditionMet
                            ? theme.palette.mode !== "dark"
                              ? "#008c64"
                              : "#A4CDBD"
                            : theme.palette.mode === "dark"
                            ? "#FFFFFF"
                            : "#000000"
                        }
                        size="medium"
                        width="100%"
                        disabled={false}
                        showArrowIcon={isConditionMet}
                        textColor={
                          isConditionMet
                            ? theme.palette.mode !== "dark"
                              ? "#ffffff"
                              : "#0D0D0D"
                            : theme.palette.mode !== "dark"
                            ? "#000000"
                            : "#FFFFFF"
                        }
                        activeLabel={isConditionMet}
                      />
                    </Box>
                    <Box width={{ xs: "100%", md: "315px" }}>
                      <RoundedButton
                        variant="outlined"
                        label="Part Load"
                        width="100%"
                        onClick={handlePartLoadClick}
                        customBackgroundColor={
                          isConditionMet
                            ? theme.palette?.mode === "dark"
                              ? "#000000"
                              : "#FFFFFF"
                            : theme.palette.mode !== "dark"
                            ? "#008c64"
                            : "#A4CDBD"
                        }
                        customBorderColor={
                          isConditionMet
                            ? theme.palette?.mode === "dark"
                              ? "#FFFFFF"
                              : "#000000"
                            : theme.palette.mode !== "dark"
                            ? "#008c64"
                            : "#A4CDBD"
                        }
                        size="medium"
                        disabled={false}
                        showArrowIcon={!isConditionMet}
                        textColor={
                          isConditionMet
                            ? theme.palette?.mode === "dark"
                              ? "#FFFFFF"
                              : "#000000"
                            : theme.palette.mode !== "dark"
                            ? "#ffffff"
                            : "#0D0D0D"
                        }
                        activeLabel={!isConditionMet}
                      />
                    </Box>
                  </Box>

                  {isFullLoad ? (
                    <>
                      <Box display="flex" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          LCC period [Years]
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("lccperiod")}
                        />
                      </Box>
                      <StandardDropdown
                        width="100%"
                        option={LccPeriodoptions}
                        value={requestLccOption?.lccPeriod}
                        name="lccPeriod"
                        onChange={handleDropdownChange}
                        sx={{
                          ...styles.DropdownStyle,
                          ...styles.turbineTextStyle,
                        }}
                      />

                      <Box display="flex" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Major Maintenance Interval [Months]
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("majormaintenance")}
                        />
                      </Box>
                      <StandardDropdown
                        width="100%"
                        option={Maintenanceoptions}
                        value={requestLccOption?.maintenanceIntervalMonths}
                        name="maintenanceIntervalMonths"
                        onChange={handleDropdownChange}
                        sx={{
                          ...styles.DropdownStyle,
                          ...styles.turbineTextStyle,
                        }}
                      />

                      <Box display="flex" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Water Wash Interval [Months]
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("waterwashinterval")}
                        />
                        <img src={logobg} alt="camfil" style={styles.bg} />
                      </Box>
                      <StandardDropdown
                        width="100%"
                        option={WaterWashoptions}
                        value={requestLccOption?.washIntervalMonths}
                        name="washIntervalMonths"
                        onChange={handleDropdownChange}
                        sx={{
                          ...styles.DropdownStyle,
                          ...styles.turbineTextStyle,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Box display="flex" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Time At Full Load [%]
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("timeatfullload")}
                        />
                      </Box>
                      <StandardDropdown
                        width="100%"
                        option={Timeloadoptions}
                        value={requestLccOption?.timeAtFullLoad}
                        name="timeAtFullLoad"
                        onChange={handleDropdownChange}
                        sx={{
                          ...styles.DropdownStyle,
                          ...styles.turbineTextStyle,
                        }}
                      />

                      <Box display="flex" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Power Output When At Part Load [%]
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("poweroutput")}
                        />
                      </Box>
                      <StandardDropdown
                        width="100%"
                        option={PartLoadoptions}
                        value={requestLccOption?.powerOutput}
                        name="powerOutput"
                        onChange={handleDropdownChange}
                        sx={{
                          ...styles.DropdownStyle,
                          ...styles.turbineTextStyle,
                        }}
                      />

                      <Box display="flex" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          LCC period [Years]
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("lccperiod")}
                        />
                      </Box>
                      <StandardDropdown
                        width="100%"
                        option={LccPeriodoptions}
                        value={requestLccOption?.lccPeriod}
                        name="lccPeriod"
                        onChange={handleDropdownChange}
                        sx={{
                          ...styles.DropdownStyle,
                          ...styles.turbineTextStyle,
                        }}
                      />

                      <Box display="flex" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Major Maintenance Interval [Months]
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("majormaintenance")}
                        />
                        <img
                          src={logobg}
                          alt="camfil"
                          style={styles.fullLoad}
                        />
                      </Box>
                      <StandardDropdown
                        width="100%"
                        option={Maintenanceoptions}
                        value={requestLccOption?.maintenanceIntervalMonths}
                        name="maintenanceIntervalMonths"
                        onChange={handleDropdownChange}
                        sx={{
                          ...styles.DropdownStyle,
                          ...styles.turbineTextStyle,
                        }}
                      />

                      <Box display="flex" width="100%">
                        <Typography sx={styles.TypographyStyle}>
                          Water Wash Interval [Months]
                        </Typography>
                        <img
                          src={updatedFaqImg}
                          alt="img-notfound"
                          onClick={() => setOpenModal("waterwashinterval")}
                        />
                      </Box>
                      <StandardDropdown
                        width="100%"
                        option={WaterWashoptions}
                        value={requestLccOption?.washIntervalMonths}
                        name="washIntervalMonths"
                        onChange={handleDropdownChange}
                        sx={{
                          ...styles.DropdownStyle,
                          ...styles.turbineTextStyle,
                        }}
                      />
                    </>
                  )}

                  <OutlinedButton
                    type="submit"
                    customcolor={
                      theme.palette.mode == "dark" ? "#080809" : "#FFFFFF"
                    }
                    label="Submit"
                    onClick={onClickSubmit}
                    customBackgroundColor={
                      theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                    }
                    customBorderColor={
                      theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                    }
                    disabled={
                      requestLccOption?.engineManufacture === "Select One" ||
                      requestLccOption?.turbineType === "Select One"
                    }
                    width="100%"
                    sx={{ marginBottom: "26px" }}
                  />

                  <OutlinedButton
                    variant="outlined"
                    label="Back"
                    width="100%"
                    onClick={onClickBack}
                    customBackgroundColor={
                      theme.palette.mode == "dark" ? "#000000" : "#FFFFFF"
                    }
                    customBorderColor={
                      theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                    }
                    customcolor={
                      theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                    }
                    size="medium"
                    disabled={false}
                    showArrowIcon={true}
                    textColor={
                      isConditionMet
                        ? theme.palette.mode !== "dark"
                          ? "#008c64"
                          : "#31FFCD"
                        : "#080809"
                    }
                    sx={{ marginBottom: "160px" }}
                  />
                </FormControl>
              </StyledBox>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={1} md={2} />
      <EfficiencyModal
        isOpen={openModal}
        handleClosePopup={handleCloseModal}
        title={modalData.find((item) => item.id === openModal)?.title}
        description={
          modalData.find((item) => item.id === openModal)?.description
        }
        buttonLabel="Okay"
      />
    </Grid>
  );
};

export default RequestLLCPage;
