import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    mode: "light",
    labelPrimary: {
      dark: "#000",
      light: "#fff",
    },
    primary: {
      main: "#31FFCD",
    },
    secondary: {
      main: "#D1CC80",
    },
    info: {
      main: "#AD5BFF",
    },
    common: {
      main: "#44B0FF",
    },
    warning: {
      main: "#a09d9d",
    },
  },
  typography: {
    fontFamily: "Inter",
    // fontStyle: "normal",
    // fontSize: 18,
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
    // fontWeightBold: 600,
    // fontWeightHeavy: 900,
    h1: {
      fontWeight: 700,
      fontSize: "45px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "24px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "20px",
    },
    h4: {
      fontSize: "18px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 500,
    },
    h6: {
      fontSize: "17px",
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "12px",
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: "10px",
    },
    subtitle3: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "auto",
    },
    button: {
      fontSize: "14px",
      fontWeight: 500,
    },
  },
});

export const LighTheme = createTheme({
  palette: {
    mode: "light",
    primaryLabel: {
      main: "#000",
    },
    primary: {
      main: "#31FFCD",
    },
    secondary: {
      main: "#D1CC80",
    },
    info: {
      main: "#AD5BFF",
    },
    common: {
      main: "#44B0FF",
    },
    warning: {
      main: "#a09d9d",
    },
  },
  typography: {
    fontFamily: "Inter",
    // fontStyle: "normal",
    // fontSize: 18,
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
    // fontWeightBold: 600,
    // fontWeightHeavy: 900,
    h1: {
      fontWeight: 700,
      fontSize: "45px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "24px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "20px",
    },
    h4: {
      fontSize: "18px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 500,
    },
    h6: {
      fontSize: "17px",
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "12px",
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: "10px",
    },
    subtitle3: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "auto",
    },
    button: {
      fontSize: "14px",
      fontWeight: 500,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
