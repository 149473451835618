import React from "react";
import PropTypes from "prop-types";

//Assests
import informationcircle from "../../assets/information _circle.svg";
import lightInfo from "../../assets/light-info.svg";
import { useTheme } from "@mui/material";
function Info({ onClick }) {
  const theme = useTheme();
  return (
    <img
      src={theme.palette.mode == "dark" ? informationcircle : lightInfo}
      alt="info icon"
      onClick={onClick}
    />
  );
}

Info.propTypes = {
  handleOpen: PropTypes.func,
};

Info.defaultProps = {
  handleOpen: () => {},
};

export default Info;
