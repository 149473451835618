import React from "react";
import { Snackbar, Alert } from "@mui/material"; // Material-UI components
import { useSelector, useDispatch } from "react-redux"; // Assuming you're using Redux
import { hideNotification } from "store/slices/modals_slice";

const Notification = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.modals.notification); // Assuming notification is stored in your Redux state

  const handleClose = () => {
    dispatch(hideNotification()); // Hide notification on close
  };

  return (
    <Snackbar
      open={Boolean(notification)} // Open the notification if there is a message
      onClose={handleClose} // Handle closing the notification
      autoHideDuration={5000} // Automatically close after 5 seconds
      sx={{
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw", // Full viewport width
        height: "100vh", // Full viewport height
        pointerEvents: "none", // Allow clicking through the background
        zIndex: 9999, // Ensure it's on top of other elements
        "& .MuiAlert-root": {
          fontSize: "1.25rem",
          padding: "20px",
          width: "600px", // Make the alert a square by setting equal width and height
          height: "180px", // Equal height for the square shape
          display: "flex", // Ensure flexbox inside to align text
          justifyContent: "center", // Center text horizontally
          alignItems: "center", // Center text vertically
          borderRadius: "10px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Optional shadow
          pointerEvents: "auto", // Make sure the alert itself is clickable
          backgroundColor: "#282A30",
          color: "#ffffff",
          fontFamily: "Inter",
        },
      }}
    >
      {notification && (
        <Alert
          onClose={handleClose}
          severity={notification.severity || "info"} // Use severity levels like 'info', 'success', 'error', 'warning'
          sx={{
            width: "100%",
            borderRadius: "10px", // Rounded corners
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Soft shadow
            width: "100%",
            height: "100%",
            textAlign: "center", // Center the text in the square
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "inherit",
            color: "#ffffff",
            fontFamily: "Inter",
          }}
        >
          {notification.message}
        </Alert>
      )}
    </Snackbar>
  );
};

export default Notification;
