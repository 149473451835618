import Cookies from "universal-cookie";

function checkCookieEnabled() {
  return (
    typeof window !== "undefined" && window.document && navigator?.cookieEnabled
  );
}

function setAuthTokenCookie(tokenValue) {
  try {
    const cookies = new Cookies();
    cookies.set(process.env.REACT_APP_COOKIE_NAME, tokenValue, {
      path: process.env.REACT_APP_COOKIE_PATH,
      maxAge: process.env.REACT_APP_COOKIE_MAXAGE,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      sameSite: "none",
      secure: true,
    });
    return true;
  } catch {
    return false;
  }
}

const deleteAuthTokenCookie = () => {
  try {
    const cookies = new Cookies();
    cookies.remove(process.env.REACT_APP_COOKIE_NAME, {
      path: process.env.REACT_APP_COOKIE_PATH,
      maxAge: 0,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: new Date(1990, 1, 1, 1, 1, 1, 1),
    });
    return true;
  } catch {
    return false;
  }
};

function getAuthCookie() {
  const cookies = new Cookies();
  return cookies.get(process.env.REACT_APP_COOKIE_NAME);
}

function getCookie(key, cookieOptions = {}) {
  let value = null;
  if (checkCookieEnabled()) {
    const cookies = new Cookies();
    value = cookies.get(key, cookieOptions);
    if (value && value !== "null") return value;
  }
  return value;
}

function setCookie(key, value, expiryDate = false) {
  try {
    const cookies = new Cookies();
    if (expiryDate) {
      cookies.set(key, value, {
        maxAge: expiryDate,
      });
    } else cookies.set(key, value);
    return true;
  } catch {
    return false;
  }
}

function deleteCookie(key, isDeletePath) {
  try {
    const cookies = new Cookies();
    if (isDeletePath) {
      cookies.remove(key, {
        path: process.env.REACT_APP_COOKIE_PATH,
        maxAge: 0,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: new Date(1990, 1, 1, 1, 1, 1, 1),
      });
    } else cookies.remove(key);
    return true;
  } catch {
    return false;
  }
}

const exp = {
  checkCookieEnabled,
  setAuthTokenCookie,
  deleteAuthTokenCookie,
  getAuthCookie,
  getCookie,
  setCookie,
  deleteCookie,
};
export default exp;
