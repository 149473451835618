import { createSlice } from "@reduxjs/toolkit";

const initialState = { selectedValue: 1 };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    setSelectedValue(state, action) {
      state.selectedValue = action.payload;
    },
  },
});

export const { setUserDetails, setSelectedValue } = userSlice.actions;
export default userSlice.reducer;
