import React from "react";
import PropTypes from 'prop-types';

//Material
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Style
import { BootstrapButton } from './style';

function OutlinedButton({ variant, customcolor, label, onClick, disabled, customBackgroundColor, customBorderColor,
  size, showArrowIcon, textColor,width, ...props }) {
  return (
    <BootstrapButton
      variant={variant}
      customcolor={customcolor}
      onClick={onClick}
      disabled={disabled}
      disableRipple
      customBackgroundColor={customBackgroundColor}
      customBorderColor={customBorderColor}
      size={size}
      width={width}
      {...props}
    >
      {showArrowIcon && <ArrowBackIcon />}
      {label}
    </BootstrapButton>
  );
}

export default OutlinedButton;

OutlinedButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  customBackgroundColor: PropTypes.string,
  customBorderColor: PropTypes.string,
  size: PropTypes.string,
  showArrowIcon: PropTypes.bool,
  textColor: PropTypes.string,
};
