import { Box, Typography } from "@mui/material";
import OutlinedButton from "components/Button";
import styles from "components/InviteModal/style";
import { StyledModal } from "components/Modal/style";
import { useDispatch, useSelector } from "react-redux";
import { setEmailSentModal, setInviteModal } from "store/slices/modals_slice";

export default function EmailSentModal() {
  const open = useSelector((state) => state.modals.emailSentModal);
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(setEmailSentModal(false));
    dispatch(setInviteModal(false));
  };
  return (
    <StyledModal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modalContainer} style={{ height: "300px" }}>
        <Typography style={styles.invitationStyles}>
          Your invitation is sent to customer successfully.
        </Typography>
        <OutlinedButton
          variant="outlined"
          customcolor="#080809"
          label="Okay"
          onClick={handleCloseModal}
          customBackgroundColor="#31FFCD"
          customBorderColor="#31FFCD"
          width="100%"
          sx={styles.buttonStyle}
        />
      </Box>
    </StyledModal>
  );
}
