/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { generateTokenFromMicrosoftToken } from "api";
import { setAuthToken } from "utils/auth-token-utils";
import { useDispatch } from "react-redux";
import { goToRoute } from "store/actions/navigationActions";
import routePaths from "routes/routePaths";

function Redirect(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    const token = window.__user;
    if (token) {
      generateTokenFromMicrosoftToken({ id_token: token })
        .then((res) => {
          console.log(res);
          setAuthToken(res.data);
          dispatch(goToRoute(routePaths.DASHBOARD));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size={80}
        style={{ color: theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD" }}
      />
      <Typography
        sx={{
          color: theme.palette.mode !== "dark" ? "black" : "white",
          fontSize: "24px",
          fontWeight: 600,
          marginTop: "16px",
        }}
      >
        Redirecting...
      </Typography>
    </Box>
  );
}

Redirect.propTypes = {};

export default Redirect;
