import React from "react";
import PropTypes from "prop-types";

//Material
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//Assests
import informationcircle from "../../assets/information _circle.svg";
import lightInfo from "../../assets/light-info.svg";
//Styles
import GetStyles, { Slider as StyledSlider } from "./style";
import { Skeleton, useTheme } from "@mui/material";

function Slider(props) {
  const theme = useTheme();
  const styles = GetStyles();
  return (
    <>
      <Box sx={styles.sliderContainer}>
        <Box sx={styles.container}>
          <Typography style={styles.sliderLabel}>
            {props.sliderStartname}
          </Typography>
          <img
            src={theme.palette.mode == "dark" ? informationcircle : lightInfo}
            alt="info icon"
            onClick={props.onInfoClick}
          />
        </Box>
        <Typography style={styles.sliderLabel}>
          {props.sliderEndname}
        </Typography>
      </Box>
      {!props.loading ? (
        <StyledSlider
          valueLabelDisplay="auto"
          value={props.value}
          onChange={props.onChange}
          onChangeCommitted={props.onChangeCommitted}
          min={props.min}
          max={props.max}
          noSlot={props.noSlot}
          valueLabelFormat={(value) =>
            props.isLabelBefore
              ? props.popupLabel + value
              : value + props.popupLabel
          }
          disabled={props?.disabled}
          marks
        />
      ) : (
        <Skeleton
          variant="rounded"
          width="100%"
          height="10px"
          sx={{ color: "#fdfdfd", my: "18px" }}
          animation="wave"
        />
      )}
    </>
  );
}

Slider.propTypes = {
  label: PropTypes.string,
  slider1startname: PropTypes.string,
  onChangeCommitted: PropTypes.func,
};

Slider.defaultProps = {
  label: "Test Label",
};

export default Slider;
