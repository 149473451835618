// Utils
import cookieActions from "./cookie";

export function getAuthTokenValue() {
  let value = null;
  if (cookieActions.checkCookieEnabled()) {
    value = cookieActions.getAuthCookie();
    if (value) {
      return value;
    }
  }
  return null;
}

export function setAuthToken(tokenData) {
  if (cookieActions.checkCookieEnabled()) {
    cookieActions.setCookie("user_type", tokenData?.type);
    return cookieActions.setAuthTokenCookie(tokenData?.token);
  }
}

export function removeAuthToken() {
  cookieActions.deleteAuthTokenCookie();
  localStorage.clear();
  window.location.reload();
}

export function getCookie(key, cookieOptions = {}) {
  return cookieActions.getCookie(key, cookieOptions);
}

export function setCookie(key, value, expiryDate) {
  return cookieActions.setCookie(key, value, expiryDate);
}

export function deleteCookie(key, isDeletePath) {
  return cookieActions.deleteCookie(key, isDeletePath);
}

export const removeTokenAndGotoLogin = () => {
  removeAuthToken();
};

export const convertUnixTimestampToUTC = (unixTimestamp) => {
  const milliseconds = unixTimestamp * 1000; // Convert seconds to milliseconds
  const utcDate = new Date(milliseconds); // Create a Date object using the milliseconds

  // Get individual date and time components in UTC format
  const year = utcDate.getUTCFullYear();
  const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
  const day = utcDate.getUTCDate().toString().padStart(2, "0");
  const hours = utcDate.getUTCHours().toString().padStart(2, "0");
  const minutes = utcDate.getUTCMinutes().toString().padStart(2, "0");
  const seconds = utcDate.getUTCSeconds().toString().padStart(2, "0");

  const utcDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`; // UTC date and time in ISO format

  return utcDateTime;
};
