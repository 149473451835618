const styles = {
  placeDetailContainer: {
    borderRadius: "8px",
    position: "relative",
    width: "190px",
    height: "120px",
    overflow: "hidden",
  },
  placeDetailLabel: {
    fontSize: "20px",
    fontWeight: 600,
    color: "white",
    paddingLeft: "15px",
  },
};

export default styles;
